import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  container: {
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    height: '-webkit-fill-available',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  }
}

function Loader() {
  return (
    <div style={styles.container}>
      <div style={styles.loader}>
        <CircularProgress/>
      </div>
    </div>
  )
}

export default Loader;