import React, { Component } from 'react';

import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';

import PeerCard from './../PeerCard';

const incrediblesData = [
  {
    image: null,
    position: 'Web Developer',
    name: 'Shahzaib Shahid',
    domains: ['Fintech', 'Healthtech']
  },
  {
    image: null,
    position: 'Android Developer',
    name: 'Omer Zahid',
    domains: ['EdTech', 'AR/VR']
  },
  {
    image: null,
    name: 'Hassan Saadar',
    position: 'IOS Developer',
    domains: ['3d Artist', 'Finca']
  }
]

const PeersSection = (props) => {
  const { users, title, refetch, type } = props;

  return  (
    <div style={{marginBottom: '32px'}}>
      <Typography variant="subtitle2" gutterBottom>{title}</Typography>
      <Grid
         container
         direction="row"
         alignItems="center"
         style={{ overflowX: 'scroll', height: '400px', padding: '0px 4px'}}
      >
        <div style={{display: 'flex'}}>
          {
            users.length > 0 &&
            users.map((incredible, index)=>(
              <PeerCard key={index} type={type} refetch={refetch} incredible={incredible}/>
            ))
          }
        </div>
      </Grid>
    </div>
  )
}

export default PeersSection;
