import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, Avatar, Tooltip } from '@material-ui/core'

import { updateDeliverable } from '../../../../../redux/actions';

class Assignees extends Component {
  state = {
    selectedIncredibles: this.props.deliverable ? this.props.deliverable.incredibles : []
  }

  handleAssginIncredible = (incredible) => {
    const { deliverable, updateDeliverable } = this.props;
    const { selectedIncredibles } = this.state;
    let userIds = [];
    const existedIncredibleIndex = selectedIncredibles.map(i=>i.id).indexOf(incredible.id);

    if(existedIncredibleIndex !== -1) {
      selectedIncredibles.splice(existedIncredibleIndex, 1);
      userIds = [...selectedIncredibles.map(i=>i.id)];
    }else {
      selectedIncredibles.push(incredible);
      userIds = [incredible.id, ...selectedIncredibles.map(i=>i.id)];
    }

    updateDeliverable({user_ids: userIds}, deliverable.id)
      .then(response=>{
        this.setState({
          selectedIncredibles
        })
        console.log(response);
      });
  }

  getAvatrStyles = (id) => {
    const { selectedIncredibles } = this.state;

    let styles = {marginTop: '8px',marginRight: '8px', cursor: 'pointer'};

    if(selectedIncredibles.map(i=>i.id).indexOf(id) !== -1) {
      styles['border'] = '2px solid #673AB7';
    }

    return styles;
  }

  render() {
    const {
      assignableIncredibles
    } = this.props;

    return (
      <div>
        <Typography variant="subtitle1"><strong>Choose from engaged Incredibles</strong></Typography>

        <div style={{display: 'flex', alignItems: 'center'}}>
          {
            assignableIncredibles.length > 0 &&
            assignableIncredibles.map((incredible,index)=>(
              <Tooltip title={incredible.username} placement="bottom">
                <Avatar style={this.getAvatrStyles(incredible.id)} onClick={()=>{this.handleAssginIncredible(incredible)}} key={index}>{incredible.username.charAt(0).toLocaleUpperCase()}</Avatar>
              </Tooltip>
            ))
          }
        </div>
      </div>
     );
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps, {
  updateDeliverable
})(Assignees);
