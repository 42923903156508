import React, { Component, Fragment } from 'react';

import {
  Grid,
  Button,
  Typography,
  Divider,
  TextField,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import 'date-fns';

import { connect } from 'react-redux';

import { createContract } from '../../../../../redux/actions';

import ConfirmationIcon from '../../../../../assets/icons/confirmation.svg';

const HireContractForm = (props) => {
  const { values, errors, onChange, onDateChange } = props;

  const {
    hours_per_week,
    rate_per_hour,
    start_date,
    end_date,
    documents
  } = values;

  const getMarks = () => {
    let marks = [
      {
        value: 0,
        label: '0'
      }
    ];

    for (let index = 1; index < 7; index++) {
      marks.push({
        value: index * 8,
        label: `${index * 8}`
      });
    }

    return marks;
  }

  return (
    <div style={{padding: '8px 16px'}}>
      <Grid container spacing={3}>
        <Grid xs={8}>
          <Typography variant="subtitle1" id="discrete-slider" gutterBottom>
            Number of Hours / week
          </Typography>

          <Slider
            defaultValue={16}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={8}
            marks={getMarks()}
            min={0}
            max={48}
            name="hours_per_week"
            value={hours_per_week}
            onChange={(e, value)=>{
              onChange('hours_per_week', value);
            }}
            style={{marginTop: '2px'}}
          />
        </Grid>

        <Grid xs={4}>
          <TextField
            id="hourly-rate"
            label="Hourly Rate ($)"
            name="rate_per_hour"
            value={rate_per_hour}
            type='number'
            min="0"
            error={errors.rate_per_hour}
            onChange = {
              (e) => {
                onChange('rate_per_hour', e.target.value)
              }
            }
            style={{marginLeft: '16px'}}
          />
        </Grid>

        <Grid xs={6}>
          <FormControl margin="normal" >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Start Date"
                  style={{width: '260px'}}
                  name='start_date'
                  value={start_date}
                  format="dd/MM/yyyy"
                  onChange = {
                    (date) => {
                      onChange('start_date', date)
                    }
                  }
                />
              </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>

        <Grid xs={6}>
          <FormControl margin="normal" >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="End Date"
                  style={{width: '260px', marginLeft: '16px'}}
                  name='end_date'
                  value={end_date}
                  format="dd/MM/yyyy"
                  onChange = {
                    (date) => {
                      onChange('end_date', date)
                    }
                  }
                />
              </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>

        <Grid xs={12} style={{marginTop: '24px'}}>
          <Typography variant="subtitle1">Choose from templates or add from local memory.</Typography>
          <input
            name="documents"
            type="file"
            onChange = {
              (e) => {
                onChange('documents', e.target.files[0]);
              }
            }
          />
        </Grid>
      </Grid>
    </div>
  )
}

const HireContractConfirmation = (props) => {
  return (
    <div style={{padding: '8px 16px'}}>
      <Grid container justify="center">
        <img src={ConfirmationIcon} style={{height: '75px', width: '75px', marginBottom: '16px'}} alt="confirmation-icon"/>
      </Grid>

      <Typography variant="h5" align="center">Contract Has been sent Successfully.</Typography>
    </div>
  )
}

class IncredibleHireContract extends Component {
  state = {
    openIncredibleHireContractModal: false,
    confirmation: false,
    contractForm: {
      hours_per_week: '0',
      rate_per_hour: '',
      start_date: new Date(),
      end_date: new Date(),
      documents: null
    },
    errors: {
      hours_per_week: false,
      rate_per_hour: false
    }
  }

  handleOpenModal  = () => {
    this.setState({
      openIncredibleHireContractModal: true
    })
  }

  handleCloseModal = () => {
    this.setState({
      openIncredibleHireContractModal: false
    })
  }

  handleCreateContract = () => {
    const { createContract, projectId, userId } = this.props;
    const { contractForm, errors } = this.state;

    contractForm['user_id'] = userId;

    if(!contractForm.hours_per_week) {
      errors.hours_per_week = true;
    }else {
      errors.hours_per_week = false;
    }

    if(!contractForm.rate_per_hour) {
      errors.rate_per_hour = true;
    } else {
      errors.rate_per_hour = false;
    }

    if(this.validateContactForm(errors)) {
      createContract(contractForm, projectId)
        .then(response => {
          this.setState({
            confirmation: true
          });
        });
    } else {
      this.setState({
        errors
      })
    }
  }

  validateContactForm = (errors) => {
    let validation = true;

    Object.keys(errors).map((ok,i)=>{
        if(errors[ok]){
          validation = false
          return validation;
        }
    });

    return validation;
  }

  handleChange = (name, value) => {
    const { contractForm } = this.state;

    contractForm[name] = value;

    this.setState({
      contractForm
    })
  }

  render() {
    const { openIncredibleHireContractModal, confirmation, contractForm, errors } = this.state;

    return (
      <Fragment>
        <Grid item justify="center" container>
          <Button
            style={styles.actionButton}
            variant="outlined"
            onClick={this.handleOpenModal}
            color="primary"
          >
            Hire
          </Button>
        </Grid>

        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={openIncredibleHireContractModal}
          onClose={this.handleCloseModal}
        >
          <DialogTitle style={{
            textAlign: 'center',
            'fontSize': '24px',
            'fontWeight': '400'}}>
              {/* Add Contract */}
          </DialogTitle>

          <DialogContent>
            {
              confirmation ?

              <HireContractConfirmation/>
              :
              <HireContractForm onDateChange={this.handleDateChange} onChange={this.handleChange} errors={errors} values={contractForm}/>
            }
          </DialogContent>

          <DialogActions style={{ marginBottom: '16px' }}>
            {
              !confirmation ?
              <Fragment>
                <Button color="secondary" onClick={()=>{ this.handleCloseModal() }} variant="outlined">Cancel</Button>
                <Button color="primary" onClick={()=>{ this.handleCreateContract() }} variant="outlined">Send</Button>
              </Fragment>
              :
              <Button color="primary" onClick={()=>{this.handleCloseModal()}} variant="outlined">Done</Button>
            }
          </DialogActions>
        </Dialog>
      </Fragment>
     );
  }
}

const styles = {
  actionButton: {
    width: "132px",
    fontSize: "12px",
    padding: "6px"
  },
}

const mapStateToProps = (state) => {
	return state
}

export default connect(mapStateToProps, {
  createContract
})(IncredibleHireContract);
