import React from 'react';

const BannerMessage = ({ message }) => {
  return (
    <div
      style={{
        backgroundColor: "#ece9e9",
        color: '#5d5d5d',
        padding: "16px",
        marginTop: "16px",
        borderRadius: "6px",
        fontSize: "16px"
      }}
    >
      {message}
    </div>
  );
};


export default BannerMessage;
