import React from 'react';
import Profile from '../../../../Components/Profile';

const IncredibleProfile = (props) => {
	const edit = `${props.auth.user.id}` === props.match.params.userId;
	return (
		<Profile editMode={edit} {...props} />
	)
}

export default IncredibleProfile;
