import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import timezones from '../../../../../Components/Timezone'
import {
  Grid,
  TextField,
  DialogActions,
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Input,
  Chip,
  Select
} from '@material-ui/core';

import { updateProfileRequest } from '../../../../../redux/actions';

import {
  ENGINEER_TOOLS,
  DESIGNER_TOOLS,
  ARTIST_TOOLS,
  PROJECT_MANAGER_TOOLS,
  PROGRAMMING_LANGUAGE_OPTIONS,
  LIBRARIES_OPTIONS,
  PLATFORMS,
  API_OPTIONS
} from '../../../../../utils/selectFieldOptionsConstants';

class EditSideBarDialog extends Component {

  handleSave = () => {
    let { values } = this.props.form.profile
    this.props.updateProfileRequest(values,this.props.refetch)
    this.props.handleClose();
  }

  componentDidMount() {
    this.props.change('','');
  }

  handleMultiSelectChange = (name, event) => {
    const {
      change,
      user
    } = this.props;

    let {
      value
    } = event.currentTarget.dataset;

    let index = user.profile[name].indexOf(value)

    if (index !== -1) {
      user.profile[name].splice(index, 1)
    } else {
      user.profile[name].push(value);
    }

    change(name, user.profile[name])
  }

  getUserTypeToolsOptions = () => {
    let role = this.props.user.profile.role;

    let tools = null;

    switch (role) {
      case 'artist':
        tools = ARTIST_TOOLS;
        break;
      case 'engineer':
        tools = ENGINEER_TOOLS;
        break;
      case 'designer':
        tools = DESIGNER_TOOLS;
        break;
      case 'TPM':
        tools = PROJECT_MANAGER_TOOLS;
        break;

      default:
        tools = []
        break;
    }

    return tools.map(name => (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    ));
  }

  getUserTypePlatformOptions = () => {
    let role = this.props.user.profile.role;

    let platforms = null;

    switch (role) {
      case 'artist':
        platforms = PLATFORMS;
        break;
      case 'engineer':
        platforms = ENGINEER_TOOLS;
        break;
      case 'designer':
        platforms = PLATFORMS;
        break;
      case 'TPM':
        platforms = PROJECT_MANAGER_TOOLS;
        break;

      default:
        platforms = []
        break;
    }

    return platforms.map(name => (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    ));
  }

  getUserTypeProgrammingLanguageOptions = () => {
    let role = this.props.user.profile.role;

    let languages = null;

    switch (role) {
      case 'artist':
        languages = PROGRAMMING_LANGUAGE_OPTIONS;
        break;
      case 'engineer':
        languages = PROGRAMMING_LANGUAGE_OPTIONS;
        break;
      case 'designer':
        languages = PROGRAMMING_LANGUAGE_OPTIONS;
        break;
      case 'TPM':
        languages = PROGRAMMING_LANGUAGE_OPTIONS;
        break;

      default:
        languages = []
        break;
    }

    return languages.map(name => (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    ));
  }

  getUserTypeApiOptions = () => {
    let role = this.props.user.profile.role;

    let apis = null;

    switch (role) {
      case 'artist':
        apis = API_OPTIONS;
        break;
      case 'engineer':
        apis = API_OPTIONS;
        break;
      case 'designer':
        apis = API_OPTIONS;
        break;
      case 'TPM':
        apis = API_OPTIONS;
        break;

      default:
        apis = []
        break;
    }

    return apis.map(name => (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    ));
  }

  getUserTypeLibrariesOptions = () => {
    let role = this.props.user.profile.role;

    let libraries = LIBRARIES_OPTIONS;

    return libraries.map(name => (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    ));
  }


  render () {
    const { user, change } = this.props;

    const {
      languages,
      apis,
      tools,
      libraries,
      platform,
      role
    } = user.profile;

    const renderMultiSelectField = (label, value, name, optionsCallback) => {
      return (
        <Grid item>
          <FormControl margin="dense" fullWidth>
              <InputLabel htmlFor="select-multiple-chip">{label}</InputLabel>
              <Select
                multiple
                value={value}
                name={name}
                onChange={(event)=> { this.handleMultiSelectChange(name, event) }}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div style={{marginRight: 5, display: 'flex', flexWrap: 'wrap'}}>
                    {selected.map(value => (
                      <Chip key={value} label={value} style={{marginRight: 5, marginBottom: 5}}/>
                    ))}
                  </div>
                )}
              >
                {optionsCallback()}
              </Select>
          </FormControl>
        </Grid>
      );
    }

    return (
      <React.Fragment>
      <DialogContent>
        <Grid container direction="column" style={{ paddingTop: 24 }} spacing={2}>
          {
            (role === 'engineer' || role === 'TPM') &&
            <>
                {
                  languages  &&
                  renderMultiSelectField('Programming Languages', languages, 'languages', this.getUserTypeProgrammingLanguageOptions)
                }

                {
                  apis &&
                  renderMultiSelectField('APIS', apis, 'apis', this.getUserTypeApiOptions)
                }

                {
                  libraries &&
                  renderMultiSelectField('Libraries', libraries, 'libraries', this.getUserTypeLibrariesOptions)
                }
              </>
          }

          {
            platform &&
            renderMultiSelectField('Platforms', platform, 'platform', this.getUserTypePlatformOptions)
          }

          {
            tools &&
            renderMultiSelectField('Tools', tools, 'tools', this.getUserTypeToolsOptions)
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleSave} color="primary">
          Save changes
        </Button>
      </DialogActions>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ form }) => {
  return { form }
}

EditSideBarDialog = connect(mapStateToProps, { updateProfileRequest })(EditSideBarDialog)

export default EditSideBarDialog = reduxForm({
  form: 'profile',
})(EditSideBarDialog)
