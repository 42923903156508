import React, { Component } from "react";
import { Paper, TextField, Button, Grid } from "@material-ui/core";

import {
  Formik
} from 'formik';

const DeliverableFormCardFooter = ({onCancel, onCreate}) => {
  const deliverableFormCardFooterStyles = {
    container: {
      marginTop: "24px"
    },
    createButton: {
      marginLeft: "8px"
    },
    icon: {
      height: "32px",
      width: "32px",
      color: "#0000008a"
    }
  };

  return (
    <Grid
      container
      style={deliverableFormCardFooterStyles.container}
      justify="space-between"
      xs={12}
    >
      <Grid container justify={"flex-end"} xs={12}>
        <Button variant="outlined" color="secondary" onClick={onCancel}>Cancel</Button>
        <Button variant="outlined" color="primary" onClick={onCreate} style={deliverableFormCardFooterStyles.createButton}>Create</Button>
      </Grid>

    </Grid>
  );
};

class DeliverableFormCard extends Component {
  handleCreateDeliverable = (values) => {
    const {onCreateDeliverable} = this.props;

    if(typeof(onCreateDeliverable) === 'function') {
      onCreateDeliverable(values);
    }
  }

  handleValidation = values => {
    let errors = {};

    if(!values.title) {
      errors.title = "Required"
    }

    if(!values.description) {
      errors.description = "Required"
    }

    return errors;
  }

  render() {
    const styles = {
      container : {
        padding: "32px",
        margin: "16px 0px"
      }
    }

    return (
      <Paper style={styles.container}>
        <Formik
          validate={ this.handleValidation }
          onSubmit={ this.handleCreateDeliverable }
        >
          {
            ({
              values,
              errors,
              handleChange,
              handleSubmit
            })=> (
              <div>
                <TextField
                  id="title"
                  name="title"
                  label="Title"
                  error={errors.title}
                  value={values.title}
                  fullWidth
                  onChange={handleChange}
                 />

                <TextField
                  id="description"
                  name="description"
                  label="Description"
                  placeholder="Enter deliverable description"
                  multiline
                  error={errors.description}
                  value={values.description}
                  fullWidth
                  style={{marginTop: '12px'}}
                  onChange={handleChange}
                />

                <DeliverableFormCardFooter onCreate={handleSubmit} {...this.props}/>
              </div>
            )
          }
        </Formik>
      </Paper>
    );
  }
}

export default DeliverableFormCard;
