import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Grid, Select, TextField, DialogActions, Button, DialogContent, InputLabel, FormControl, MenuItem, Chip, Input } from '@material-ui/core';
//import Select from '../../../../../Components/SelectionControl/Select';
import { updateProfileRequest } from '../../../../../redux/actions';

class EditSideBarDialog extends Component {

  handleSave = () => {
    let { values } = this.props.form.profile

    this.props.updateProfileRequest(values,this.props.refetch).then(resp => {
      this.props.handleClose();
    })
  }

  componentDidMount() {
    // this.props.change('domain',this.props.data.domain);
  }

  render () {
    const { data, change, form } = this.props;
    let {
      overview,
      specialization,
      clients,
      domain
    } = data;

    const myDomain = (form.profile && form.profile.values && form.profile.values.domain) ? form.profile.values.domain : domain


    const domainOptions = [
      'Games',
      'AR/VR',
      'Fintech',
      'HealthTech',
      'IOT',
    ];

    return (
      <React.Fragment>
      <DialogContent>
        <Grid container direction="column" style={{ paddingTop: 24 }} spacing={2}>
          <Grid item>
            <TextField
              required
              defaultValue={overview}
              id="overview"
              name="overview"
              label="Overview"
              fullWidth
              autoComplete="overview"
              onChange={(event)=> {
                this.props.change('overview', event.target.value)
              }}
            />
          </Grid>
          <Grid item>
          {
            domain &&
            <FormControl margin="dense" fullWidth>
              <InputLabel htmlFor="select-multiple-chip">Which industry/vertical/domain does your project belong to? (e.g. Games, AR/VR)</InputLabel>
              <Select
                multiple
                style={{marginTop:30}}
                value={myDomain}
                name="domain"
                onChange={(event)=> {
                change('domain', event.target.value)}}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div style={{marginRight: 5}}>
                    {selected.map(value => (
                      <Chip key={value} label={value} style={{marginRight: 5}}/>
                    ))}
                  </div>
                )}
              >
                {domainOptions.map(name => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }

          </Grid>
          <Grid item>
            <TextField
              required
              defaultValue={clients}
              id="clients"
              name="clients"
              label="Clients"
              fullWidth
              autoComplete="clients"
              onChange={(event)=> {change('clients',event.target.value.split(','))}}
              helperText="please use comma ', ' to seperate identities"
            />
          </Grid>
          {/* <Grid item>
            <TextField
              required
              defaultValue={specialization}
              id="specialization"
              name="specialization"
              label="Specialization"
              fullWidth
              autoComplete="specialization"
              onChange={(event)=> {change('specialization',event.target.value.split(','))}}
              helperText="please use comma ', ' to seperate identities"
            />
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleSave} color="primary">
          Save changes
        </Button>
      </DialogActions>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ form }) => {
  return { form }
}

EditSideBarDialog = connect(mapStateToProps, { updateProfileRequest })(EditSideBarDialog)

export default EditSideBarDialog = reduxForm({
  form: 'profile',
})(EditSideBarDialog)
