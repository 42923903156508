import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import RadioOptions from '../../../Components/SelectionControl/RadioOptions';
import { reduxForm } from 'redux-form';

function DetailForm(props) {

  const {
    change,
    incredibleType = 'artist',
    fname,
    lname,
    email,
    password,
    confirmPassword,
    skype_id,
    linkedin_profile,
    client_description,
    error = null,
    userType,
    formErrors
  } = props;

  const options = [
      { value: 'artist', label: 'Artist' },
      { value: 'designer', label: 'UX/UI Designer' },
      { value: 'engineer', label: 'Engineer' },
      { value: 'TPM', label: 'Project Manager' },
  ]

  return (
    <React.Fragment>
    <Typography variant="h6" gutterBottom>
        User Details
    </Typography>
    <Grid container spacing={2}>
        {
          userType === 'Incredible' &&
          <RadioOptions change={change} id={'incredibleType'} label={'What is your area of functional expertise'} options={options} defaultValue={incredibleType}/>
        }
        <Grid item xs={12} sm={6}>
          <TextField
            error={formErrors.fname}
            required
            defaultValue={fname}
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="fname"
            onChange={(event)=> {change('fname',event.target.value)}}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={formErrors.lname}
            defaultValue={lname}
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="lname"
            onChange={(event)=> {change('lname',event.target.value)}}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
              required
              error={formErrors.email.required || formErrors.email.format}
              defaultValue={email}
              id="email"
              name="email"
              label="Email Address"
              fullWidth
              autoComplete="email"
              onChange={(event)=> {change('email',event.target.value)}}
          />
          { formErrors.email.format && <FormHelperText style={{color: '#f44336'}} id="component-error-text">{formErrors.email.message}</FormHelperText> }
        </Grid>
        <Grid item xs={12}>
          <TextField
              required
              error={formErrors.password.required || formErrors.password.format}
              defaultValue={password}
              id="password"
              name="password"
              label="Password"
              type="password"
              fullWidth
              autoComplete="password"
              onChange={(event)=> {change('password',event.target.value)}}
          />
            { formErrors.password.format && <FormHelperText style={{color: '#f44336'}} id="component-error-text">{formErrors.password.message}</FormHelperText> }
        </Grid>
        <Grid item xs={12}>
          <TextField
              required
              error={formErrors.confirmPassword}
              defaultValue={confirmPassword}
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              fullWidth
              autoComplete="confirmPassword"
              onChange={(event)=> {change('confirmPassword',event.target.value)}}
          />
          {
            (((password && confirmPassword) && confirmPassword.length > 0) && confirmPassword != password) &&
            <FormHelperText style={{color: '#f44336'}} id="component-error-text">Password do not match.</FormHelperText>
          }
        </Grid>

        <Grid item xs={12}>
          <TextField
              required
              error={formErrors.skype_id}
              defaultValue={password}
              id="skype_id"
              name="skype_id"
              label="Skype ID"
              type="skype_id"
              fullWidth
              autoComplete="skype_id"
              onChange={(event)=> {change('skype_id',event.target.value)}}
          />
            { formErrors.linkedin_profile.format && <FormHelperText style={{color: '#f44336'}} id="component-error-text">{formErrors.password.message}</FormHelperText> }
        </Grid>

        <Grid item xs={12}>
          <TextField
              required
              error={formErrors.linkedin_profile}
              defaultValue={password}
              id="linkedin_profile"
              name="linkedin_profile"
              label="Linkedin"
              type="linkedin_profile"
              fullWidth
              autoComplete="linkedin_profile"
              onChange={(event)=> {change('linkedin_profile',event.target.value)}}
          />
        </Grid>
        {
          userType === 'Client' &&
          <Grid item xs={12}>
            <TextField
                required
                error={formErrors.client_description}
                defaultValue={client_description}
                id="client_description"
                name="client_description"
                label="Project Description"
                type="textarea"
                multiline
                fullWidth
                autoComplete="client_description"
                onChange={(event)=> {change('client_description',event.target.value)}}
            />
             <FormHelperText id="component-error-text">Please tell us about your project or hiring needs.</FormHelperText>
          </Grid>
        }
        {/* <Grid item xs={12}>
        <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
            label="Use this address for payment details"
        />
        </Grid> */}
    </Grid>
    </React.Fragment>
  );
}

DetailForm = reduxForm({
  form: 'SignUp',
  destroyOnUnmount: false,
})(DetailForm)

export default DetailForm;
