import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import { connect } from 'react-redux';
import { retrieveUser, signOut } from './redux/actions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LoggedInRoutes from './pages/LoggedIn';

class App extends Component {

  componentDidMount() {
    const { props } = this;
    props.retrieveUser();
  }

  shouldComponentUpdate(nextProps) {
    // prevent
    if(nextProps.auth.user !== this.props.auth.user)
    {
      //this.props.history.push('/');
      return true
    }
    else {
      return false;
    }
  }

  render() {
    const { props } = this;

    if(props.auth.user === null) {
      return (
        <div style={styles.container}>
          <React.Fragment>
            <AppBar position="relative" color="default" >
              <Toolbar>
                <Typography variant="h6" color="inherit" noWrap>
                  {/* <Link to='/'>
                    Get Incredible
                  </Link> */}
                  <div  style={{ cursor: 'pointer'}} onClick={()=>{
                    window.location.replace('https://www.getincredibles.com/')
                  }}>
                    Get Incredible
                  </div>
                </Typography>
              </Toolbar>
            </AppBar>
            <Switch>
              <Route path="/register" component={(params) => { return <SignUp {...params} {...this.props} /> }} />
              <Route path="/" component={(params) => { return <SignIn {...params} /> }} />
            </Switch>
          </React.Fragment>
        </div>
      );
    }
    else {
      return <LoggedInRoutes {...props}/>
    }
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    boxSizing: 'border-box',
    flex: 1,
  },
}

function mapStateToProps({auth}) {
  return { auth };
}

export default connect(mapStateToProps,{ retrieveUser, signOut })(App);
