import React, { Component, Fragment } from 'react';
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux';
import { Typography, LinearProgress } from '@material-ui/core';

import ImageIcon from '@material-ui/icons/Image'
import AttachFileIcon from '@material-ui/icons/AttachFile'

import { createAttachment } from '../../../../../redux/actions';

class Attachments extends Component {
  state = {
    attachments: this.props.deliverable ? this.props.deliverable.documents : [],
    uploading: false
  }

  handleFileSelection = (acceptedFiles) => {
    const { deliverable, createAttachment } = this.props;
    const { id } = deliverable;

    this.setState({
      uploading: true
    }, () => {
      createAttachment(acceptedFiles[0], id)
        .then(response => {
          const { attachments } = this.state;
          let availableAttachments = response.data.data[0].attributes.documents.data;
          let file = availableAttachments[availableAttachments.length - 1];

          attachments.push({
            id: file.attributes.blob.id,
            serviceUrl: file.attributes.blob.url,
            filename: file.attributes.blob.filename
          });

          this.setState({
            uploading: false,
            attachments
          })
        })
        .catch(err=>{
          this.setState({
            uploading: false
          })
        })
    })
  }

  render() {
    const {
      attachments,
      uploading
    } = this.state;

    return (
      <div>
        <Typography variant="subtitle1"><strong>Attachments</strong></Typography>

        {
          attachments.length > 0 &&
          <div>
            {
              attachments.map((attachment, index) => (
                  <a href={attachment.serviceUrl} download={attachment.filename} target="_blank" key={index} style={{display: 'flex', alignItems: 'center', marginBottom: '4px', cursor: 'pointer'}}>
                    <AttachFileIcon style={{marginRight:'6px'}}/>
                    <Typography variant='body2'>
                      {attachment.filename}
                    </Typography>
                  </a>
              ))
            }
          </div>
        }

        <Dropzone maxSize={10000000} onDrop={this.handleFileSelection}>
          {({getRootProps, getInputProps}) => (
            <section style={styles.dropzoneContainer}>
              <div style={styles.dropzoneText} {...getRootProps()}>
                <input {...getInputProps()} />
                {
                  !uploading ?
                    <Fragment>
                      <Typography variant="subtitle1">Drag 'n' drop some file here, or click to select file</Typography>
                      <Typography variant="caption" bold>Max file size: 10mb</Typography>
                    </Fragment>
                  :
                    <Fragment>
                      <Typography variant="subtitle1">Uploading Attachment</Typography>
                      <LinearProgress color="primary" variant="determinate" value={Math.floor((Math.random() * 100) + 1)} />
                    </Fragment>
                }
              </div>
            </section>
          )}
        </Dropzone>
      </div>
     );
  }
}

const styles = {
  dropzoneContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#f6f6f6',
    border: '2px dashed #ccc',
    borderRadius: '8px',
    marginTop: '16px'
  },
  dropzoneText: {
    width: '100%',
    height: '100%',
    padding: '24px 16px',
    textAlign: 'center'
  }
}

const mapStateToProps = (state) => {
	return state
}

export default connect(mapStateToProps, {
  createAttachment
})(Attachments);
