import React, { Component, useState } from 'react';
import { connect } from 'react-redux';

import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Button from '@material-ui/core/Button';

import MyDialog from './../../../../../Components/Dialog';
import PeerReviewForm from './../PeerReviewForm';
import PeerInviteSent from './../PeerInviteSent';

import { checkInviteEmail } from './../../../../../redux/actions';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EmailInviteSection = (props) => {
  const {user, refetch, searchResultEmail, searchResultName} = props;

  const [emailValue, setEmailValue] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    // let error = !emailRegex.test(value);

    // if(emailErrorMessage){
    //   setEmailErrorMessage('')
    // }

    setEmailValue(value);

    // if(value.length > 0) {
    //   setError(error);
    // }
  }

  const handleEmailInvite = ({onOpenDialog, invite}) => {
    const { onSearchClick } = props;

    if(!invite){
      onSearchClick(emailValue)
    }else{
      onOpenDialog();
    }

    // props.checkInviteEmail({email:emailValue})
    // .then(res=>{
    //   setEmailErrorMessage('')
    //   onOpenDialog();
    // })
    // .catch(err=>{
    //   setEmailErrorMessage('Invite is already sent to this email!');
    // })
  }

  return (
    <div style={{marginBottom: '32px'}}>
      <Grid
          container
            direction="row"
            justify="space-between"
            alignItems="center"
            >
              <Typography variant="subtitle1" gutterBottom>Invite peers to GetIncredibles</Typography>
              {/* <Typography variant="subtitle2" gutterBottom>INVITE SENT</Typography> */}
              <div style={{display: 'flex'}}>
                {
                  (user && user.sentInvites) &&
                  <MyDialog title="Email Invite Sent" content={(renderProps)=>{return (<PeerInviteSent invites={user.sentInvites.filter(invite=> {
                    if(!invite.signUp){
                      return invite;
                    }
                  })}/>)}}>
                    {
                      ({onOpenDialog})=>{
                        return(
                          <Typography style={styles.openListModalButton} onClick={onOpenDialog} variant="subtitle1" gutterBottom>Show Sent Invites</Typography>
                        )
                      }
                    }
                  </MyDialog>
                }

                {
                  (user && user.sentRatings) &&
                  <MyDialog title="Peer Request Sent" content={(renderProps)=>{return (<PeerInviteSent invites={user.sentRatings.map(rating=>{
                    return {
                      id: rating.id,
                      name: rating.receiver.username,
                      receiverEmail: rating.receiver.email,
                      projectName: rating.projectName,
                      score: rating.score,
                      startDate: rating.startDate,
                      endDate: rating.endDate,
                      createdAt: rating.createdAt
                    }
                  })}/>)}}>
                    {
                      ({onOpenDialog})=>{
                        return(
                          <Typography style={styles.openListModalButton} onClick={onOpenDialog} variant="subtitle1" gutterBottom>Show Sent Ratings</Typography>
                        )
                      }
                    }
                  </MyDialog>
                }
              </div>
          </Grid>

          <MyDialog title="Peer Review" content={(renderProps)=>{return (<PeerReviewForm onInviteSuccess={()=>{setEmailValue('')}} onClose={renderProps} email={emailValue} refetch={refetch}/>)}}>
                  {
                    ({onOpenDialog})=>{
                      return(
                      <form onSubmit={(event)=>{
                          event.preventDefault();
                          // if((emailValue.length > 0 && !error)){
                          //   handleEmailInvite({onOpenDialog})
                          // }
                            handleEmailInvite({onOpenDialog})
                        }}>
                        <Grid
                          container
                          direction="row"
                          alignItems="flex-end"
                          spacing={2}
                          >
                          <Grid item xs={4} style={{position: 'relative'}}>
                            <FormControl margin="normal" fullWidth>
                              <InputLabel htmlFor="email">Search</InputLabel>
                              <Input id="email" error={error} value={emailValue} name="email" onChange={handleChange}/>
                            </FormControl>
                            {
                              error &&
                              <div style={{color: 'red', fontSize: '10px', position: 'absolute'}}>Enter a valid email</div>
                            }
                            {
                              emailErrorMessage &&
                              <div style={{color: 'red', fontSize: '10px', position: 'absolute'}}>{emailErrorMessage}</div>
                            }
                          </Grid>
                          <Grid item xs={2}>
                              {/* <Button disabled={!(emailValue.length > 0 && !error)} onClick={()=>{handleEmailInvite({onOpenDialog})}} style={{width: '120px', height:'42px',top:'-7px'}} variant="contained" color = "primary" >Invite</Button> */}
                              <Button onClick={()=>{handleEmailInvite({onOpenDialog,invite:false})}} style={{width: '120px', height:'42px',top:'-7px'}} variant="contained" color = "primary" >Search</Button>
                          </Grid>
                        </Grid>
                        {
                          searchResultEmail &&
                          <div>{searchResultEmail}<span onClick={()=>{handleEmailInvite({onOpenDialog,invite:true})}} style={styles.inviteButton} variant="outlined" color = "primary" >Send Invite</span></div>
                        }

                        {
                          searchResultName &&
                          <div>{searchResultName}</div>
                        }
                       </form>
                      )
                  }
                }
      </MyDialog>

    </div>
  )
}

const styles = {
  inviteButton:{
    color: '#3f51b5',
    textDecoration: 'underline',
    marginLeft: '8px',
    cursor: 'pointer',
    fontWeight: '500',
    fontSize: '18px'
  },
  openListModalButton:{
    color: '#3f51b5',
    marginLeft: '8px',
    cursor: 'pointer',
    fontSize: '14px',
    border: '1px solid #3f51b5',
    padding: '0px 10px',
    borderRadius: '4px'

  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps,{ checkInviteEmail })(EmailInviteSection);
