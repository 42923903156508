import React from "react";
import Dropdown from "@material-ui/icons/ExpandMore";
import DropdownClose from "@material-ui/icons/ExpandLess";
import Moment from "react-moment";
import AcceptedIcon from "@material-ui/icons/Check";
import RejectedIcon from "@material-ui/icons/Close";
import { graphql } from "react-apollo";
import fetchUserMatches from "../../../../graphQL/Query/fetchUserMatches";
import Loader from "../../../../Components/Loader";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Paper,
  Button
} from "@material-ui/core";
import IncredibleMatchedPanel from "../../../../Components/IncredibleMatchedPanel";
import MyAvatar from "../../../../Components/MyAvatar";
import Detail from "../../../../Components/Profile/Components/Project/Components/Detail";
import List from "../../../../Components/Profile/Components/Project/Components/List";
import { updateMatch } from "../../../../redux/actions";
import { connect } from "react-redux";
import Chip from "@material-ui/core/Chip";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import { useTheme, Theme } from "@material-ui/core/styles";
import BannerMessage from "./../../../../Components/BannerMessage";

const TALENT_MATCH_STATUS = {
  pending: 'pending',
  matched: 'matched',
  get_estimate: 'get_estimate',
  hired: 'hired',
  declined: 'declined'
}

const ProjectView = ({ data }) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      padding: 20,
      borderBottom: "1px solid #d6d6d6"
    },
    heading: {
      fontSize: 22,
      marginBottom: 20,
      color: "#444444",
      marginTop: 20
    },
    row: {
      display: "flex",
      width: "50%",
      justifyContent: "space-between"
    }
  };

  const {
    project,
    domain: reqDomain,
    tools,
    startDate,
    duration,
    languages,
    technicalExpertise,
    timezone
  } = data;
  const { domain, targetPlatform, title, description, stageAt } = project;

  // debugger;
  return (
    <Grid container wrap="wrap" style={{ padding: "16px 10px" }}>
      <Grid xs={8} style={{ marginBottom: "16px" }}>
        <Typography variant="body1" style={{ marginBottom: "6px" }}>
          <strong>{"Description"}</strong>
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </Grid>

      <Grid md={6} xs={12} sm={12} style={{ marginBottom: "16px" }}>
        <Typography variant="body1" style={{ marginBottom: "6px" }}>
          <strong>{"Domains"}</strong>
        </Typography>
        {domain[0].split(" ").map((d, i) => (
          <Chip key={i} label={d} style={{ marginRight: 6 }} />
        ))}
      </Grid>

      <Grid md={6} xs={12} sm={12} style={{ marginBottom: "16px" }}>
        <Typography variant="body1" style={{ marginBottom: "6px" }}>
          <strong>{"Target Platforms"}</strong>
        </Typography>
        {targetPlatform[0].split(" ").map((p, i) => (
          <Chip key={i} label={p} style={{ marginRight: 6 }} />
        ))}
      </Grid>

      <Grid md={6} xs={12} sm={12} style={{ marginBottom: "16px" }}>
        <Typography variant="body1" style={{ marginBottom: "6px" }}>
          <strong>{"Preferred Tools and Softwares"}</strong>
        </Typography>
        {tools[0].split(" ").map((p, i) => (
          <Chip key={i} label={p} style={{ marginRight: 6 }} />
        ))}
      </Grid>

      <Grid md={6} xs={12} sm={12} style={{ marginBottom: "16px" }}>
        <Typography variant="body1" style={{ marginBottom: "6px" }}>
          <strong>{"Programming Languages"}</strong>
        </Typography>
        {languages[0].split(" ").map((p, i) => (
          <Chip key={i} label={p} style={{ marginRight: 6 }} />
        ))}
      </Grid>

      <Grid md={6} xs={12} sm={12} style={{ marginBottom: "16px" }}>
        <Typography variant="body1" style={{ marginBottom: "6px" }}>
          <strong>{"Description"}</strong>
        </Typography>
        <Typography variant="body2">{duration}</Typography>
      </Grid>

      <Grid md={6} xs={12} sm={12} style={{ marginBottom: "16px" }}>
        <Typography variant="body1" style={{ marginBottom: "6px" }}>
          <strong>{"Timezone"}</strong>
        </Typography>
        <Typography variant="body2">{timezone}</Typography>
      </Grid>
    </Grid>
  );
};

const Match = ({ data, updateStatus }) => {
  const { technicalRequirement, status, id } = data;
  const { project, talentType, domain, startDate } = technicalRequirement;
  const { user } = project;
  const { username } = user;

  return (
    <IncredibleMatchedPanel data={data} updateStatus={updateStatus}>
      {<ProjectView data={technicalRequirement} />}
    </IncredibleMatchedPanel>
  );
};

const FilteredTalentMatchesTabContent = ({ talentMatches, updateStatus, status  }) => {
  let filteredTalentMathces =  talentMatches.filter(tM=> tM.status === status);

  return (
    <TabContainer>
      <Paper elevation="0" style={{ padding: "4px", borderRadius: "0px" }}>
        {
          filteredTalentMathces.length > 0 ?
          filteredTalentMathces.map(req => (
            <Match key={req.id} updateStatus={updateStatus} data={req} />
          ))
          : <BannerMessage message="There are no projects to show in this section for now."/>
        }
      </Paper>
    </TabContainer>
  );
};

const TabContainer = ({ children, dir }) => {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
};

class Matches extends React.Component {
  state = {
    tab: 0
  };

  handleTabChange = (event, newValue) => {
    this.setState({
      tab: newValue
    });
  };

  handleTabChangeIndex = index => {
    this.setState({
      tab: index
    });
  };

  updateStatus = (status, id) => {
    this.props.updateMatch(status, id).then(resp => {
      this.props.data.refetch();
    });
  };

  render() {
    const { data } = this.props;
    const { loading, user = {} } = data;
    const { talentMatches } = user;
    const { tab } = this.state;
    if (loading) return <Loader />;

    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          Matched Projects
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Recent Matched Projects
                </Typography>
                {talentMatches.length === 0 && (
                  <BannerMessage message="You have not been matched against a client requirement yet" />
                )}
                <AppBar
                  position="static"
                  style={{
                    backgroundColor: "#FFFFFF",
                    marginTop: "24px",
                    marginBottom: "24px",
                    boxShadow: "0px 2px 2px 0px rgba(224,224,224,0.6)"
                  }}
                >
                  <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label="Pending" />
                    <Tab label="Matched" />
                    <Tab label="Getting Estimates" />
                    <Tab label="Hired" />
                    <Tab label="Declined Matches" />
                  </Tabs>
                </AppBar>

                <SwipeableViews
                  index={tab}
                  onChangeIndex={this.handleTabChangeIndex}
                >
                  {
                    Object.keys(TALENT_MATCH_STATUS).map((status,index)=>(
                      <FilteredTalentMatchesTabContent
                        key={index}
                        talentMatches={talentMatches}
                        updateStatus={this.updateStatus}
                        status={status}
                      />
                    ))
                  }
                </SwipeableViews>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default graphql(fetchUserMatches, {
  options: props => {
    return { variables: { id: props.match.params.userId } };
  }
})(
  connect(
    mapStateToProps,
    { updateMatch }
  )(Matches)
);
