import 'date-fns';
import React, { Component } from 'react';
import { FormControlLabel, Checkbox, Grid, Typography, Button, TextField, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Moment from 'react-moment';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import { connect } from 'react-redux';
import { updateCheckList, addCheckList, deleteCheckList } from '../../../../../../../redux/actions';

const EditableCheckList = ({data, handleChange, handleDelete}) => {
	const { checklistText , dueDate } = data;
	return (
		<Grid container spacing={2}>
			<Grid item xs>
				<TextField
					required
					defaultValue = {checklistText}
					id="checklistText"
					name="checklistText"
					label="Add an action item or task"
					fullWidth
					autoComplete="checklistText"
					onChange={(event)=> {handleChange('checklistText',event.target.value)}} 
				/>
			</Grid>
			<Grid item xs={'auto'}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<DatePicker
						fullWidth
						label="Date picker"
						value={dueDate}
						onChange={(date) => {handleChange('dueDate',date)}} 
					/>
				</MuiPickersUtilsProvider>
			</Grid>
			<Grid item xs={'auto'}>
				<Fab onClick={handleDelete} size="small" aria-label="Delete">
					<DeleteIcon />
				</Fab>
			</Grid>
		</Grid>
	)
}

class CheckList extends Component {
	state = {
		isEdit: false,
		checklists: this.props.data.checklists,
		updateList: [],
	}

	update = () => {
		let state = {...this.state};
		let { updateList } = state;
		const { refetch } = this.props.data;

		return Promise.all(updateList.map(item => {
			return this.props.updateCheckList(item)
		}))
		.then(values => {
			state.updateList = [];
			this.setState(state);
		})
	}

	handleClick = () => {
		const { isEdit } = this.state;
		if(isEdit) {
			this.update().then(resp => {
				this.setState({isEdit: !isEdit})
			})
		}
		else
			this.setState({isEdit: !isEdit})
	}

	handleChange = (index,key,value) => {
		let state = {...this.state};
		let { checklists, updateList } = state;
		let listItem = checklists[index];
		listItem[`${key}`] = value;
		let item = updateList.find(item => item.id === listItem.id);
		if(item )
			item = listItem
		else
			updateList.push(listItem);
		this.setState(state);
	}

	handleDelete = (index,id) => {
		let state = {...this.state};
		let { checklists } = state;
		this.props.deleteCheckList(id).then(resp => {
			//checklists.splice(index,1);
			//this.setState(state);
		})
		checklists.splice(index,1);
		this.setState(state);
	}

	handleAdd = () => {
		let state = {...this.state};
		const { id } = this.props.data;
		let { checklists } = state;
		const newObj = { checklistText: '', dueDate: new Date(), status: false };
		this.props.addCheckList(newObj,id).then(resp => {
			const {id} = resp.data.data;
			const obj = {...newObj, id };
			checklists.push(obj);
			this.setState(state);
		})
	}

	addItem = () => {
		const { checklists } = this.state; 
		return (
			<Grid container item direction="column" spacing={2}>
				<Grid item>
				<Fab color="primary" size="small" variant="extended" onClick={this.handleAdd} aria-label="Add">
					<AddIcon/> 
					Add item 
				</Fab>
				</Grid>
				<Grid item>
				{
					checklists.map((item,index) => <EditableCheckList index={index} handleDelete={() => {this.handleDelete(index,item.id)}} handleChange={(key,value) => this.handleChange(index,key,value)} data={item}/>)
				}
				</Grid>
			</Grid>
		)
	}

	render() {
		const { checklists, isEdit } = this.state;
		const { canEdit = false} = this.props;
		const renderCheckList = checklists.map(({id, status, dueDate, checklistText},index) => {
			return (
				<Grid key={id} alignItems="center" justify="space-between" container item spacing={2}>
					<Grid item xs>
						<FormControlLabel
							control={
								<Checkbox
									checked={status}
									onChange={(event,value) => {
										this.handleChange(index,'status',value) 
										this.update()
									}}
									value={status}
									color="primary"
								/>
							}
							label={checklistText}
						/>
					</Grid>
					<Grid item xs={'auto'}>
						<Typography><Moment format="MMM D, YYYY" withTitle>{dueDate}</Moment></Typography>
					</Grid>
				</Grid>
			)
		});

		return (
			<Grid item>
				<Grid container item alignItems="center" justify={'space-between'}>
					<Typography variant="subtitle2" gutterBottom>
						Checklist
					</Typography>
					{
						canEdit &&
						<Button onClick={this.handleClick} size="small" color="primary">
							{ !isEdit ? 'Edit' : 'Save' }
						</Button>
					}
				</Grid>
				{ isEdit ? this.addItem() : renderCheckList }
			</Grid>
		)
	}
}

const mapStateToProps = (state) => {
	return state;
}

export default connect(mapStateToProps, { updateCheckList, addCheckList, deleteCheckList })(CheckList);