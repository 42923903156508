import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import timezones from "../../../../../Components/Timezone";
import {
  Grid,
  TextField,
  DialogActions,
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  MenuItem
} from "@material-ui/core";
// import Select from '../../../../../Components/SelectionControl/Select';
import { createProfileProject, updateProfileProject } from "../../../../../redux/actions";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import "date-fns";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";

import {
  ENGINEER_TOOLS,
  DESIGNER_TOOLS,
  ARTIST_TOOLS,
  PROJECT_MANAGER_TOOLS,
  PROGRAMMING_LANGUAGE_OPTIONS,
  LIBRARIES_OPTIONS,
  PLATFORMS,
  API_OPTIONS,
  DOMAINS
} from "../../../../../utils/selectFieldOptionsConstants";

class EditSideBarDialog extends Component {
  state = {
    startDate: new Date(),
    endDate: new Date()
  };

  handleSave = () => {
    let { values } = this.props.form.profile;
    this.props.createProfileProject(values, this.props.refetch);
    this.props.handleClose();
  };

  componentDidMount() {
    // this.props.change('','');
  }

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  getPlatfromOptions = () => {
    let options = PLATFORMS;

    return options.map(name => (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    ));
  };

  getDomainOptions = () => {
    let domains = DOMAINS;
    return domains.map(name => (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    ));
  };

  getProgrammingLanguageOptions = () => {
    let languages = PROGRAMMING_LANGUAGE_OPTIONS;

    return languages.map(name => (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    ));
  };

  getUserTypeToolsOptions = () => {
    let role = this.props.data.role;

    let tools = null;

    switch (role) {
      case "artist":
        tools = ARTIST_TOOLS;
        break;
      case "engineer":
        tools = ENGINEER_TOOLS;
        break;
      case "designer":
        tools = DESIGNER_TOOLS;
        break;
      case "TPM":
        tools = PROJECT_MANAGER_TOOLS;
        break;

      default:
        tools = [];
        break;
    }

    return tools.map(name => (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    ));
  };

  handleMultiSelectChange = (name, event, arrayHelpers) => {
    let { value } = event.currentTarget.dataset;

    let index = arrayHelpers.form.values[name].indexOf(value);

    if (index !== -1) {
      arrayHelpers.remove(index);
    } else {
      arrayHelpers.push(value);
    }
  };

  handleValidation = values => {
    let errors = {};
    if (!values.name) {
      errors.name = "Required";
    }

    if (!values.url) {
      errors.url = "Required";
    }

    if (!values.description) {
      errors.description = "Required";
    }

    if (!values.role) {
      errors.role = "Required";
    }

    if (!values.teamSize) {
      errors.teamSize = "Required";
    }

    return errors;
  };

  handleSubmit = (values) => {
    const {createProfileProject, updateProfileProject,handleClose, data, editMode, refetch} = this.props;

    if(editMode) {
      updateProfileProject(data.id, values, refetch);
      handleClose();
    }else{
      values.startDate = this.state.startDate;
      values.endDate = this.state.endDate;

      createProfileProject(values, refetch);
      handleClose();
    }
  };

  handleChange = (e) =>{
    debugger;
  }

  render() {
    const { editMode } = this.props;
    const { role, name, url, description, teamSize, platforms, tools, domain, languages } = this.props.data;

    let initialValues = {
      name: "",
      url: "",
      description: "",
      role: "",
      teamSize: "",
      platforms: [],
      tools: [],
      domain: [],
      languages: []
    };

    if(editMode) {
      initialValues.name = name;
      initialValues.url = url;
      initialValues.description = description;
      initialValues.role = role;
      initialValues.teamSize = teamSize
      initialValues.platforms = platforms;
      initialValues.tools = tools;
      initialValues.domain = domain;
      initialValues.languages = languages;
    }

    const renderMultiSelectField = (
      label,
      value,
      name,
      optionsCallback,
      arrayHelpers
    ) => {
      return (
        <Grid item>
          <FormControl margin="dense" fullWidth>
            <InputLabel htmlFor="select-multiple-chip">{label}</InputLabel>
            <Select
              multiple
              value={value}
              name={name}
              onChange={event => {
                this.handleMultiSelectChange(name, event, arrayHelpers);
              }}
              input={<Input id="select-multiple-chip" />}
              renderValue={selected => (
                <div
                  style={{ marginRight: 5, display: "flex", flexWrap: "wrap" }}
                >
                  {selected.map(value => (
                    <Chip
                      key={value}
                      label={value}
                      style={{ marginRight: 5, marginBottom: 5 }}
                    />
                  ))}
                </div>
              )}
            >
              {optionsCallback()}
            </Select>
          </FormControl>
        </Grid>
      );
    };

    return (
      <Formik
        initialValues={initialValues}
        validate={ this.handleValidation }
        onSubmit={ this.handleSubmit }
        onChange={this.handleChange}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <React.Fragment>
            <DialogContent>
              <Grid
                container
                direction="column"
                style={{ paddingTop: 24 }}
                spacing={2}
              >
                <Grid item>
                  <TextField
                    error={errors.name}
                    required
                    id="name"
                    name="name"
                    value={values.name}
                    label="Project Title"
                    fullWidth
                    autoComplete="name"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    required
                    error={errors.url}
                    id="url"
                    name="url"
                    value={values.url}
                    label="Project Url"
                    fullWidth
                    autoComplete="url"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    required
                    error={errors.description}
                    id="description"
                    name="description"
                    value={values.description}
                    label="Description"
                    fullWidth
                    autoComplete="description"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    required
                    error={errors.role}
                    id="role"
                    name="role"
                    value={values.role}
                    label="My role on the project"
                    fullWidth
                    autoComplete="role"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      label="Start Date"
                      value={this.state.startDate}
                      name="startDate"
                      fullWidth
                      onChange={date => {
                        // change('startDate',date);
                        this.setState({ startDate: date });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      label="End Date"
                      name="endDate"
                      fullWidth
                      value={this.state.endDate}
                      onChange={date => {
                        this.setState({ endDate: date });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item>
                  <TextField
                    required
                    error={errors.teamSize}
                    id="teamSize"
                    name="teamSize"
                    value={values.teamSize}
                    label="Team Size"
                    fullWidth
                    autoComplete="teamSize"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item>
                  <FieldArray
                    name="platforms"
                    render={arrayHelpers =>
                      renderMultiSelectField(
                        "Platforms",
                        values.platforms,
                        "platforms",
                        this.getPlatfromOptions,
                        arrayHelpers
                      )
                    }
                  />
                </Grid>

                <Grid item>
                  <FieldArray
                    name="tools"
                    render={arrayHelpers =>
                      renderMultiSelectField(
                        "Tools and Softwares",
                        values.tools,
                        "tools",
                        this.getUserTypeToolsOptions,
                        arrayHelpers
                      )
                    }
                  />
                </Grid>

                <Grid item>
                  <FieldArray
                    name="domain"
                    render={arrayHelpers =>
                      renderMultiSelectField(
                        "Domain",
                        values.domain,
                        "domain",
                        this.getDomainOptions,
                        arrayHelpers
                      )
                    }
                  />
                </Grid>

                {
                  (role === "engineer" || role === "TPM") && (
                  <Grid item>
                    <FieldArray
                      name="languages"
                      render={arrayHelpers =>
                        renderMultiSelectField(
                          "Programming Languages",
                          values.languages,
                          "languages",
                          this.getProgrammingLanguageOptions,
                          arrayHelpers
                        )
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmit} color="primary">
                Save changes
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = ({ form }) => {
  return {};
};

export default (EditSideBarDialog = connect(
  mapStateToProps,
  { createProfileProject, updateProfileProject }
)(EditSideBarDialog));
