import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { loginRequest, forgotPassword } from '../../redux/actions';
import { connect } from 'react-redux';
import { emailRegex } from './../../utils/validationsRegex';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  signUp: {
    marginTop: theme.spacing.unit,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  forgetButton: {
    fontWeight: 500,
    color: '#f50057',
    cursor: 'pointer',
    textAlign: 'right'
  }
});

class SignIn extends Component {
  state = {
    email: '',
    password: '',
    error: null,
    formErrors : {
      email: {
        required: false,
        format: false,
        message: null
      },
      password: false
    },
    forgotPasswordForm: false,
    forgotPasswordFormMessage: null,
  }

  handleSubmit = (event) => {
    const {
      email,
      password,
      loading,
      forgotPasswordForm,
      formErrors
    } = this.state;

    const {
      loginRequest,
      forgotPassword
    } = this.props;

    event.preventDefault();

    if(forgotPasswordForm){
      if(this.validateForgotPasswordForm()) {
        forgotPassword(email)
          .then(result => {
            formErrors.email.required = false;
            formErrors.email.format = false;
            formErrors.email.message = null;

            this.setState({
              forgotPasswordFormMessage: 'An Email has been sent with password instructions!',
              email: '',
              formErrors
            });
          })
          .catch(err => {
            formErrors.email.required = true;
            formErrors.email.format = true;
            formErrors.email.message = 'The provided email does not exists!';

            this.setState({
              formErrors,
            })
          });
      }
    }else{
      if(this.validateInputForm()) {
        loginRequest({ email, password });
      }
    }
  }

  validateInputForm = () => {
    const {
      email,
      password
    } = this.state;

    let formErrors = {
      email: {
        required: false,
        format: false,
        message: null
      },
      password: false
    }

    if(!email){
      formErrors.email.required = true;
    }else{
      formErrors.email.required = false;
      formErrors.email.format = !emailRegex.test(email);
      formErrors.email.message = !emailRegex.test(email) ? 'Kindly Enter a Valid Email' : null;
    }

    if(!password){
      formErrors.password = true;
    }else{
      formErrors.password = false;
    }

    this.setState({formErrors});

    if (
      formErrors.email.required ||
      formErrors.email.format ||
      formErrors.password
    ) {
      return false;
    } else {
      return true;
    }
  }


  validateForgotPasswordForm = () => {
    const {
      email
    } = this.state;

    let formErrors = {
      email: {
        required: false,
        format: false,
        message: null
      }
    }

    if(!email){
      formErrors.email.required = true;
    }else{
      formErrors.email.required = false;
      formErrors.email.format = !emailRegex.test(email);
      formErrors.email.message = !emailRegex.test(email) ? 'Kindly Enter a Valid Email' : null;
    }

    this.setState({formErrors});

    if (
      formErrors.email.required ||
      formErrors.email.format
    ) {
      return false;
    } else {
      return true;
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if (nextProps.auth.error) {
      return {
        error: 'Invalid email/password'
      }
    }
  }

  handleForgotPassword = () => {
    this.setState({
      forgotPasswordForm: !this.state.forgotPasswordForm
    })
  }

  render() {
    const { props } = this;
    const { classes, auth } = props;
    let { loading } = auth;

    const {
      email,
      password,
      error,
      formErrors,
      forgotPasswordForm
    } = this.state

    function handleSignUp(event) {
      props.history.push('/register')
    }

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
               { forgotPasswordForm ? 'Forgot Password': 'Sign in'}
          </Typography>
          {error && <Typography style={{marginTop:'8px'}} color="error" align="center">{error}</Typography>}
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <FormControl margin="normal" required fullWidth error={formErrors.email.required || formErrors.email.format }>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input id="email" name="email" value={this.state.email} autoComplete="email" autoFocus onChange={(event) => {
                this.setState({email:event.target.value})
              }}/>
              { formErrors.email.format && <FormHelperText style={{color: '#f44336'}} id="component-error-text">{formErrors.email.message}</FormHelperText> }
            </FormControl>
            {
              !forgotPasswordForm &&
              <FormControl margin="normal" required error={ formErrors.password } fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input name="password" type="password" id="password" autoComplete="current-password" onChange={(event) => {
                  this.setState({password:event.target.value})
                }}/>
              </FormControl>
            }
            <div onClick={this.handleForgotPassword} className={classes.forgetButton}>
              {!forgotPasswordForm ? 'Forgot Password ?' : 'Back To Login'}
            </div>
            <div style={{ position: 'relative' }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.handleSubmit}
                disabled={loading}
              >
                <div style={{height: '36px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                 {loading ? <CircularProgress style={{top:'14px', left: '46%'}} size={24} className={classes.buttonProgress} />: forgotPasswordForm ? 'Submit': 'Sign in'}
                </div>
              </Button>
            </div>
            {
              this.state.forgotPasswordFormMessage &&
              <div style={{    textAlign: 'center',
                color: '#f50057',
                marginTop: '10px',
                fontWeight: '500'
            }}>{this.state.forgotPasswordFormMessage}</div>
            }
            {/* <Button
              fullWidth
              className={classes.signUp}
              onClick={handleSignUp}
            >
              Create new account
            </Button> */}
          </form>
        </Paper>
      </main>
    )
  }
}

const mapStateToProps = ({ form, auth }) => {
  return { auth };
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default connect(mapStateToProps,{ loginRequest, forgotPassword })(withStyles(styles)(SignIn));
