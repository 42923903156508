import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const Detail = ({ variant = 'subtitle2', heading, text}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>  
        <Typography variant={variant} gutterBottom >{ heading }</Typography>
      </Grid>
      <Grid item xs={12}>
        { typeof text === "function" ? text() : typeof text === "string" ? <Typography paragraph>{text}</Typography> : <React.Fragment>{ text }</React.Fragment>}
      </Grid>
    </React.Fragment>
  )
}

export default Detail;