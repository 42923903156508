import React from 'react';
import MyAvatar from '../../../MyAvatar';
import StarIcon from '@material-ui/icons/StarRate';
import Detail from '../Project/Components/Detail';
import Tags from '../Project/Components/Tags';
import { Grid, Typography, Button } from '@material-ui/core';
import Dialog from '../../../Dialog';
import EditDialog from './Components/EditDialog';
import EmailIcon from '@material-ui/icons/Email';
import LocationIcon from '@material-ui/icons/LocationOn';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';


import skypeIcon from './../../../../assets/icons/skype.svg';
import linkedIcon from './../../../../assets/icons/linkedin.svg';

// const SideBar = ({ data, editMode = false, refetch }) => {
// 	const { username = 'username', profile = {} } = data;
// 	const {
// 		clients = [],
// 		clientRating = 0,
// 		interests = [],
// 		role = 'Technical Project Manager',
// 		timezone = 'California, U.S.A',
// 	} = profile

// 	const stars = []

//   for (let i = 0; i < clientRating; i++) {
//     stars.push(<StarIcon/>)
// 	}

// 	return (
// 		<React.Fragment>
// 			<Grid container direction="column" spacing={2}>
// 				<Grid container item direction="column" spacing={2} alignItems="center">
// 					<Grid container item justify={'center'} style={{ position:"relative" }}>
// 						<MyAvatar size="extraLarge" hasMenu={false} user={data}/>
// 						<Grid container item style={{position:"absolute"}}>
// 						{
// 							editMode &&
// 							<Dialog title={'Profile Details'} content={(props) => <EditDialog handleClose={props} refetch={refetch} data={profile}/>}>
// 								<Button size="small" color="primary">
// 									Edit
// 								</Button>
// 							</Dialog>

// 						}
// 						</Grid>
// 					</Grid>
// 					<Grid item>
// 						<Typography variant="h5">{username}</Typography>
// 					</Grid>
// 					<Grid item>
// 						<Typography variant="h6" paragraph>{role}</Typography>
// 					</Grid>
// 					<Grid item>
// 						<Typography>{timezone}</Typography>
// 					</Grid>
// 					<Grid item>
// 						<Typography paragraph>{'Local Time: 7:00 pm'}</Typography>
// 					</Grid>
// 					<Grid item>
// 						<Typography gutterBottom>{'Client rating: '}</Typography>
// 						{
// 							stars
// 						}
// 					</Grid>
// 				</Grid>
// 				<Grid item>
// 					<Detail variant="h5" heading={'Clients'} text={()=><Tags data={clients}/>}/>
// 				</Grid>
// 				<Grid container item>
// 					<Detail variant="h5" heading={'Future Interests'} text={()=><Tags data={interests}/>}/>
// 				</Grid>
// 			</Grid>
// 		</React.Fragment>
// 	)
// }


const ProfileOverview = (props) => {
	const {
		editMode,
		refetch
	} = props;

	const {
		username,
		email,
		profile,
		// linkedinProfile,
	} = props.data;

	const {
		timezone,
		experience,
		tagline,
		skype,
		linkedinProfile
	} = profile;

	const profileOverviewStyles = {
		contactIcon : {
			marginRight: '8px'
		},
		positionTitle: {
			fontSize: '18px',
			color: '#686868',
			textTransform: 'capitalize',
			marginTop: '-2px',
		},
		socialIconContainer: {
			marginBottom: '8px'
		}
	}

	return (
			<Grid container>
				<Grid container justify="flex-end">
					{
						editMode &&
						<Dialog title={'Profile Details'} content={(props) => <EditDialog handleClose={props} refetch={refetch} data={profile}/>}>
							<Button size="small" color="primary">
								Edit
							</Button>
						</Dialog>
					}
				</Grid>

				<Grid container item xs={12} alignItems="flex-start">
					<Grid container justify="center" alignItems="center" xs={2}>
						<MyAvatar size="extraLarge" hasMenu={false} user={props.data}/>
					</Grid>

					<Grid container item xs={6}>
						<Grid item xs={12}>
							<Typography variant='h5'>{username}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle2' style={profileOverviewStyles.positionTitle}>{profile.role}</Typography>
						</Grid>
						<Grid item xs={12} 	style={{marginTop: '8px'}}>
							{/* <Typography variant="subtitle1">Crafting the future of digital experiences through emotional design.</Typography> */}
							<Typography variant="subtitle1">{tagline}</Typography>
						</Grid>
						<Grid container
								direction="row"
								justify="space-between"
								alignItems="center" item xs={12}
								style={{marginTop: '24px'}}
								>
								<Grid item style={profileOverviewStyles.socialIconContainer}>
									<Grid container alignItems="center">
										<img  style={profileOverviewStyles.contactIcon} src={skypeIcon} height="24px" alt="skype-icon"/>
										<Typography variant="body1">
												{skype}
										</Typography>
									</Grid>
								</Grid>
								<Grid item style={profileOverviewStyles.socialIconContainer}>
									<Grid container alignItems="center">
										<EmailIcon style={profileOverviewStyles.contactIcon}/>
										<Typography variant="body1">{email}</Typography>
									</Grid>
								</Grid>
								<Grid item style={profileOverviewStyles.socialIconContainer}>
									<Grid container alignItems="center">
										<img style={profileOverviewStyles.contactIcon} src={linkedIcon} height="28px" alt="linkedin-icon"/>
										<Typography variant="body1">
											{
												linkedinProfile ?
												linkedinProfile.replace(linkedinProfile.substring(0,linkedinProfile.indexOf('linkedin')),'')
												: 'N/A'
											}
										</Typography>
									</Grid>
								</Grid>
						</Grid>

						<Grid container
								direction="row"
								// justify="space-between"
								alignItems="center" item xs={12}
								style={{marginTop: '18px'}}
								>
								{
									timezone &&
									<Grid item>
										<Grid container>
											<LocationIcon style={profileOverviewStyles.contactIcon}/>
											<Typography variant="body1">
													{timezone}
											</Typography>
										</Grid>
									</Grid>
								}

								{
									experience > 0 &&
									<Grid item style={{marginLeft: '16px'}}>
										<Grid container>
											<QueryBuilderIcon style={profileOverviewStyles.contactIcon}/>
											<Typography variant="body1">
													{experience} {experience > 1 ? 'Years': 'Year'} Experience
											</Typography>
										</Grid>
									</Grid>
								}
						</Grid>
					</Grid>

					<Grid container direction="column" justify="center" alignItems="center" item xs={4}>
						<Grid direction="column">
							<Typography variant="h4">4.2</Typography>
							<Typography variant="subtitle1">Client Rating</Typography>
						</Grid>
						<Grid direction="column">
							<Typography variant="h4">4.2</Typography>
							<Typography variant="subtitle1">Peer Rating</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
	);
}

export default ProfileOverview;
