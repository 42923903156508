import React, { Component, Fragment } from 'react';
import {
 Grid,
 Typography,
 AppBar,
 Tabs,
 Tab
} from '@material-ui/core';
import SwipeableViews from "react-swipeable-views";

import BannerMessage from '../../../Components/BannerMessage';
import ContractCard from './ContractCard';

const CONTRACT_STATUS = {
  pending : 'pending',
  hired : 'hired',
  outdated : 'outdated',
  declined : 'declined',
}

class Contracts extends Component {
  static defaultProps = {
    data: {}
  }

  state = {
    tab: 0,
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tab: newValue
    });
  };

  handleChangeIndex = index => {
    this.setState({
      tab: index
    });
  };

  getFilteredContracts = (status) => {
    const { contracts, role } = this.props;

    let filterdContracts = contracts.filter((c)=> c.status === CONTRACT_STATUS[`${status}`]);

    if(filterdContracts.length > 0) {
      return filterdContracts.map((contract, index) => (
              <ContractCard role={role} contract={contract} key={index}/>
            ))
    }

    return (
      <BannerMessage message="There are no records to show in this section for now."/>
    )

  }

  render() {
    const { data, contracts, role} = this.props;
    const { tab } = this.state;

    return (
      <div>
        <Grid container justify="space-between">
          <Typography variant="h4">Contracts</Typography>
        </Grid>

        <AppBar
          position="static"
          style={{
            backgroundColor: "#FFFFFF",
            marginTop: "24px",
            marginBottom: "24px",
            boxShadow: "0px 2px 2px 0px rgba(224,224,224,0.6)"
          }}
        >
          <Tabs
            value={tab}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Pending" />
            <Tab label="Signed" />
            <Tab label="Outdated" />
            <Tab label="Declined" />
          </Tabs>
        </AppBar>

        <SwipeableViews
            index={tab}
            onChangeIndex={this.handleChangeIndex}
        >
          {
            Object.keys(CONTRACT_STATUS).map((status, index)=>{
              return (
                <div key={index}>
                  {
                    (contracts && contracts.length > 0) &&
                    this.getFilteredContracts(status)
                  }
                </div>
              )
            })
          }
        </SwipeableViews>
      </div>
     );
  }
}

export default Contracts;
