import React from 'react'
import { Route, Switch } from 'react-router-dom';
import ProjectsListing from './Components/ProjectsListing';
import Project from './Components/Project';
import AddProjectForm from './Components/AddProjectForm';

function Client(props) {
  return (
    <Switch>
      <Route exact path="/" component={(params) => { return <ProjectsListing {...props} {...params}/> }}/>
      <Route path="/project/:id" component={(params) => { return <Project {...props} {...params}/> }}/>
      <Route path="/create/project" component={(params) => { return <AddProjectForm {...props} {...params}/> }}/>
    </Switch>
  )
}

export default Client;
