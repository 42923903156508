import gql from 'graphql-tag'

export default gql`
query FetchUser($id: ID!) {
  user(id: $id) {
    email
    id
    contracts {
      id
      userId
      status
      ratePerHour
      hoursPerWeek
      startDate
      endDate
      user {
        id
        username
        profile {
          id
          role
          skype
          linkedinProfile
        }
      }
      project {
        id
        title
        technicalRequirements {
          id
          talentMatches {
            id
          }
        }
        user {
          id
          username
          profile {
            id
            role
            skype
            linkedinProfile
          }
        }
      }
    }
    talentMatches{
      id
      status
      technicalRequirement {
        id
        project {
          id
          contracts {
            id
            userId
            status
            ratePerHour
            hoursPerWeek
            startDate
            endDate
          }
        }
      }
    }
    profile {
      id
      profilePicture
      role
    }
    projects {
      id
      description
      title
    }
    incredibleProjects {
      id
      description
      title
    }
    roles {
      description
      id
      name
    }
    username
  }
}
`
