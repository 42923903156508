import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TypeForm from './Components/TypeForm';
import DetailForm from './Components/DetailForm';
import { connect } from 'react-redux';
import { signUp, loginRequest } from '../../redux/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import {
  emailRegex,
  passwordRegex
} from './../../utils/validationsRegex';
import queryString from 'query-string';
import { reduxForm } from 'redux-form';

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.unit * 3,
  },
  button: {
    marginLeft: theme.spacing.unit/2,
    marginRight: theme.spacing.unit/2,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});

const steps = ['Select Type', 'Details'];

function getStepContent(step, values, error, formErrors) {
  switch (step) {
    case 0:
      return <TypeForm {...values}/>;
    case 1:
      return <DetailForm {...values} error={error} formErrors={formErrors}/>;
    // case 2:
    //   return <TypeForm />;
    default:
      throw new Error('Unknown step');
  }
}

class SignUp extends Component {
  state = {
    activeStep: 0,
    values: [

    ],
    signFormErrors: {
      userType: false,
      fname: false,
      lname: false,
      email: false,
      password: false,
      confirmPassword: false,
      skype_id: false,
      linkedin_profile: false
    }
  };

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  componentDidMount() {
    const paramValues = queryString.parse(this.props.location.search)
    const { email, userType} = paramValues;

    if(userType) {

      this.props.change('userType',this.capitalize(userType));
      this.setState({ activeStep: 1 });
    }
    if(email){
      this.props.change('email',email);
    }
  }

  handleNext = () => {
    if(this.state.activeStep === steps.length - 1) {
      if(this.validateForm() && this.state.values.password === this.state.values.confirmPassword){
        this.props.signUp(this.state.values);
      }
    }
    else {
      this.setState(state => ({
        activeStep: state.activeStep + 1,
      }));
    }
  };

  validateForm = () => {
    const {
      userType,
      fname,
      lname,
      email,
      password,
      confirmPassword,
      skype_id,
      linkedin_profile,
      client_description
    } = this.state.values;

    let signFormErrors = {
      userType: false,
      fname: false,
      lname: false,
      email: {
        required: false,
        format: false,
        message: null
      },
      password: {
        required: false,
        format: false,
        message: null
      },
      confirmPassword: false,
      skype_id: false,
      linkedin_profile: false
    };

    this.setSignupErrorForm(signFormErrors, userType, 'userType');
    this.setSignupErrorForm(signFormErrors, fname, 'fname');
    this.setSignupErrorForm(signFormErrors, lname, 'lname');
    this.setSignupErrorForm(signFormErrors, email, 'email');
    this.setSignupErrorForm(signFormErrors, password, 'password');
    this.setSignupErrorForm(signFormErrors, confirmPassword, 'confirmPassword');
    this.setSignupErrorForm(signFormErrors, skype_id, 'skype_id');
    this.setSignupErrorForm(signFormErrors, linkedin_profile, 'linkedin_profile');

    if(userType === 'Client'){
      signFormErrors.client_description = false;
      this.setSignupErrorForm(signFormErrors, client_description, 'client_description');
    }

    this.setState({
      signFormErrors
    });

    if (
      signFormErrors.userType ||
      signFormErrors.fname ||
      signFormErrors.lname ||
      signFormErrors.email.required ||
      signFormErrors.password.required ||
      signFormErrors.email.format ||
      signFormErrors.password.format ||
      signFormErrors.confirmPassword.required ||
      signFormErrors.skype_id ||
      signFormErrors.linkedin_profile
    ) {
      return false
    } else {
      return true
    }
  }

  setSignupErrorForm = (object, value, key) => {
    if (!value) {
      if (key === 'email') {
        object[key].required = true;
      } else if (key === 'password') {
        object[key].required = true;
      } else {
        object[key] = true;
      }

    } else {
      if (key === 'email') {
        object[key].required = false;
        object[key].format = !emailRegex.test(value);
        object[key].message = !emailRegex.test(value) ? 'Kindly Enter a Valid Email' : null
      } else if (key === 'password') {
        object[key].required = false;
        object[key].format = !passwordRegex.test(value);
        object[key].message = !passwordRegex.test(value) ? 'Minimum eight characters, at least one letter, one number and one special character:' : null
      } else {
        object[key] = false;
      }
    }
  }

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  componentWillReceiveProps(nextProps) {
    if(nextProps.form && nextProps.form.SignUp && nextProps.form.SignUp.values) {
      this.setState({ values: nextProps.form.SignUp.values })
    }
    if(nextProps.auth.error !== this.props.auth.error) {
      this.setState({ error: nextProps.auth.error });
    }
    if(nextProps.auth.status !== this.props.auth.status && this.state.activeStep < steps.length ) {
      this.setState(state => ({
        activeStep: state.activeStep + 1,
      }));
    }
  }

  render() {
    const { classes, auth } = this.props;
    const { activeStep, error, values, status } = this.state;
    const { loading } = auth;
    const { email, password } = values;

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Sign Up
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  {
                    this.state.values.userType === 'Client'
                    ?
                    <div>
                      <Typography variant="h5" gutterBottom>
                        Thank you for showing you interest to become a part of GetIncredibles.
                      </Typography>
                      <Typography gutterBottom>
                        One of our account managers will get in touch with you soon to help get you on-boarded on the platform but there is a wait since we are still in Beta. So, look out for our email!
                      </Typography>
                      <Typography gutterBottom>
                        See you back here soon.
                      </Typography>
                      <Typography style={{marginTop: '32px'}}>
                        <i style={{fontWeight: 500, color:'#686868'}}>- The GetIncredibles team.</i>
                      </Typography>
                    </div>
                  :
                    <div>
                      <Typography variant="h5" gutterBottom>
                        Thank you for showing your interest to become a part of GetIncredibles.
                      </Typography>
                      <Typography gutterBottom>
                        We are looking forward to getting you on-boarded as quickly as possible but there is a wait since we are still in Beta. We are going to get in touch with you soon regarding next steps. So, look out for our email!
                      </Typography>
                      <Typography>
                        See you back here soon. Be Incredible!
                      </Typography>
                      <Typography style={{marginTop: '32px'}}>
                        <i style={{fontWeight: 500, color:'#686868'}}>- The GetIncredibles team.</i>
                      </Typography>
                    </div>
                  }

                  <Grid container justify="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          window.location.replace('https://www.getincredibles.com');
                          // this.props.loginRequest({ email, password })
                        }}
                        disabled={loading}
                        className={classes.button}
                      >
                        Go To HomePage
                      </Button>

                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {error && <Typography color="error" align="center">{error.toUpperCase()}</Typography>}
                  {getStepContent(activeStep,values, error, this.state.signFormErrors)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={this.handleBack} className={classes.button}>
                        Back
                        {/* Request Access */}
                      </Button>
                    )}
                    <div style={{position: 'relative'}}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleNext}
                        disabled={loading}
                        className={classes.button}
                      >

                        { activeStep === steps.length - 1 ? 'Request Access' : 'Next'}
                      </Button>
                      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}


const mapStateToProps = ({ form, auth }) => {
    return { form, auth };
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
};

SignUp = connect(mapStateToProps,{ signUp, loginRequest })(withStyles(styles)(SignUp));

export default reduxForm({
  form: 'SignUp',
})((SignUp))
