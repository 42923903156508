import React, { Component, useState } from 'react';
import { connect } from 'react-redux';

import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

const TechnicalRequirementRecord = ({title, values}) => {
  return (
      <Grid style={{margin: '8px 0px'}} container item xs={12}>
        <Grid item xs={4}>
          <Typography variant="subtitle1"><strong>{title}</strong></Typography>
        </Grid>
        <Grid item xs={6}>
          {
            typeof(values) == 'string' &&
            <Typography variant="body1">{values}</Typography>
          }

          {
            typeof(values) == 'object' &&
            values.map((value,index)=>(
              <Chip style={{margin: '4px', marginLeft: '0px'}} label={value} key={index}/>
            ))
          }
        </Grid>
      </Grid>
  )
}

const TechnicalRequirementModal = (props) => {
  const {
    technicalRequirements
  } = props;

  const {
    project,
    talentType,
    duration,
    startDate,
    languages,
    tools,
    technicalExpertise,
    domain
  } = technicalRequirements;

  return (
    <div style={{marginBottom: '32px', padding: '16px'}}>
      <Grid
          container
            direction="row"
            justify="space-between"
            alignItems="center"
            >

            <TechnicalRequirementRecord title="Project Title" values={project.title}/>
            <TechnicalRequirementRecord title="Talent Type" values={talentType}/>
            <TechnicalRequirementRecord title="Specialization" values={talentType}/>
            <TechnicalRequirementRecord title="Duration" values={duration}/>
            <TechnicalRequirementRecord title="Start Date" values={startDate}/>
            <TechnicalRequirementRecord title="Status" values={'N/A'}/>
            <TechnicalRequirementRecord title="Domain" values={domain}/>
            <TechnicalRequirementRecord title="Languages" values={languages}/>
            <TechnicalRequirementRecord title="Tools" values={tools}/>
            <TechnicalRequirementRecord title="Techincal Expertise" values={technicalExpertise}/>

          </Grid>
    </div>
  )
}

const styles = {}

export default TechnicalRequirementModal;
