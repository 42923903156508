// UI/UX
export const DESIGNER_TOOLS = [
  'Invision',
  'Sketch',
  'Zeplin',
  'FIGMA',
  'MockFlow',
  'Balsamiq',
  'Webflow',
  'Flinto',
  'Framer',
  'Hype Principle',
  'Adobe XD',
  'Origami',
  'Axure RP',
  'Proto.io',
  'Atomic',
  'Atomic',
  'Atomic',
  'UXPin',
  'ProtoPie',
];

export const ARTIST_TOOLS = [
  'Adobe Photoshop',
  'Adobe Illustrator',
  'Adobe Aftereffects',
  'Adobe Premiere',
  '3D Studio Max',
  'Maya',
  'Blender',
  'Spine',
  'Zbrush'
];

export const ENGINEER_TOOLS = [
  'Visual Studio',
  'Visual Studio 2013',
  'Visual Studio 2012',
  'Visual SourceSafe',
  'Google Analytics',
  'MailChimp',
  'Dapper',
  'Git',
  'Facebook Connect',
  'TFS',
  'Microsoft Team Foundation Server',
  'Fiddler',
  'Jupyter',
  'IPython',
  'MATLAB',
  'Mercurial',
  'Mathematica',
  'Weka',
];

export const PROJECT_MANAGER_TOOLS = [
  "Jira",
  "Rally",
  "Bitbucket",
  "Trello",
  "Slack",
  "Github",
  "Gitflow",
  "Visual Studio.NET",
  "Visual Studio 2013",
  "Visual Studio 2012",
  "Visual SourceSafe",
  "Google Analytics",
  "MailChimp",
  "Dapper",
  "Git",
  "Facebook Connect",
  "TFS",
  "Microsoft Team Foundation Server",
  "Fiddler",
  "Jupyter",
  "IPython",
  "MATLAB",
  "Mercurial",
  "Mathematica",
  "Weka"
]

export const PLATFORMS = [
  'IOS',
  'Android',
  'Windows',
  'Web browser',
  'Samsung TV',
  'Linux',
  'Mac',
  'HTC Vive',
  'PS4',
  'Steam OS',
  'Xbox One',
  'Xbox 360',
  'Windows Phone',
  'Oculus',
  'WebVR',
  'Blackberry',
  'Wii/U',
];

export const PROGRAMMING_LANGUAGE_OPTIONS = [
  "C++",
  "C#",
  "C",
  "Objective C",
  "Java",
  "JavaScript",
  "Visual Basic",
  "Python",
  "PHP",
  "Swift",
  "NodeJS",
  "ReactJS",
  "HTML5",
  "RubyOnRails",
  "SQL"
]

export const API_OPTIONS = [
  "SoundCloud API",
  "Mandrill API",
  "Google Places API",
  "Facebook API",
  "Google Analytics API",
  "MailChimp API",
  "Twitter API",
  "Amazon Product Advertising API",
  "YouTube API",
  "Google+ API",
  "Vimeo API"
];

export const LIBRARIES_OPTIONS = [
  "Google Maps",
  "TensorFlow",
  "Matplotlib",
  "SciPy",
  "Sklearn",
  "Pandas",
  "Scikit-learn",
  "NumPy",
  "Keras"
];

export const DOMAINS = [
  "AR/VR",
  "HealthTech",
  "FinTech",
  "IoT",
  "Games"
];
