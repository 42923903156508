import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Typography, TextField } from '@material-ui/core';
import { updateDeliverable } from '../../../../../../../redux/actions';

class Description extends Component {
    state = {
      isEdit: false,
      description: this.props.data.description
    }
	
    handleSave = () => {
      const { isEdit, description } = this.state;
			const { id, refetch } = this.props.data;

			if(isEdit)
      this.props.updateDeliverable({description}, id).then(resp => {
        this.setState({description})
				//refetch();
			})

			this.setState({isEdit: !isEdit})
    }
    
    render() {
  
      const styles = {
        description: {
          border: '1px solid #d6d6d6',
          padding: 10,
          borderRadius: 5,
        },
      }
      const { data, canEdit = false } = this.props;
      const { isEdit, description } = this.state;
  
      return (
        <Grid item>
          <Grid container alignItems="center" justify={'space-between'}>
            <Typography variant="subtitle2" gutterBottom>
              Description
            </Typography>
            {
              canEdit &&
              <Button onClick={this.handleSave} size="small" color="primary">
                { !isEdit ? 'Edit' : 'Save' }
              </Button>
            }
          </Grid>
          { 
            isEdit ? 
            <TextField
              variant="outlined"
              defaultValue={this.state.description}
              id="description"
              name="description"
              fullWidth
              autoComplete="description"
              onChange={(event)=> {this.setState({description: event.target.value})}} />
            : 
            <Typography style={styles.description} paragraph> {description} </Typography>
          }        
        </Grid>
      )
    }
  }
  const mapStateToProps = (state) => {
    return state;
  }
  
export default connect(mapStateToProps,{updateDeliverable})(Description)