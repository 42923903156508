import gql from 'graphql-tag'

export default gql`
query FetchProject($id: ID!) {
  project(id: $id){
    callScheduled
    contracts {
      id
      userId
      status
      ratePerHour
      hoursPerWeek
      startDate
      endDate
      user {
        id
        username
        profile {
          id
          role
          skype
          linkedinProfile
        }
      }
      project {
        id
        title
      }
    }
    getEstimateIncredibles {
      id
      username
    }
    hiredIncredibles {
      id
      username
    }
    deliverables {
      id
      description
      client {
        id
        username
      }
      incredibles {
        id
        username
      }
      documents {
        id
        name
        serviceUrl
        recordType
        filename
        extension
      }
      checklists {
        id
        status
        checklistText
        dueDate
      }
      comments {
        id
        description
        createdAt
        user {
          id
          username
        }
      }
      title
    }
    description
    domain
    id
    stageAt
    targetPlatform
    technicalRequirements {
      duration,
      domain,
      id,
      startDate,
      talentType,
      languages,
      technicalExpertise,
      tools,
      project {
        id,
        title
      }
      talentMatches {
        id
        status
        user {
          id
          username,
          profile {
            id
            timezone,
            availability,
            minBidRate,
            overview,
            skype,
            linkedinProfile
          }
        }
      }
      technicalExpertise
    }
    title
    userId
  }
}
`
