import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Moment from 'react-moment';
import { Grid, Typography, ExpansionPanel, ExpansionPanelSummary, Divider, ExpansionPanelDetails, Button } from '@material-ui/core';


const Row  = ({label,value}) => {
	return (
		<Grid xs={6} style={{marginBottom:'18px'}}>
				<Typography variant="body1"><strong>{label}</strong></Typography>
				<Typography variant="body2">{value ? value : 'N/A'}</Typography>
		</Grid>
	)
}

const MatchedResultRow = ({data, onActionClick}) => {
	const { technicalRequirement, status, id } = data;
	const { project, talentType, domain, startDate } = technicalRequirement;
	const { user, title: projectTitle , stageAt } = project;
	const { username } = user;

	return (
		<Grid container style={{padding: '16px 0px'}}>
			<Grid container item style={{marginBottom: '16px'}}>
				<div style={{height: '50px', width: '50px', backgroundColor: '#ccc', marginRight: '18px'}}/>

				<Grid>
					<Typography variant="h6">{projectTitle}</Typography>
					<Typography variant="body2">Studiopiece.com</Typography>
				</Grid>
			</Grid>

			<Grid container item xs={9}>
				<Row label='Client' value={username}/>
				<Row label='Inception Date' value={<Moment format="MMM D, YYYY" withTitle >{startDate}</Moment>}/>
				<Row label='Requirement' value={`Needs a ${talentType} in ${domain[0].split(' ').join(', ')}`}/>
				<Row label='Project Stage' value={stageAt}/>
			</Grid>

			<Grid container item justify='center' alignItems="center" direction="column" xs={3}>

					{
						status === 'pending' &&
						<Button style={{width: '150px', marginBottom: '16px'}} color="primary" variant='outlined' onClick={(event)=>{ onActionClick(event, 'matched', 'action')}}>Accept Match</Button>
					}

					{
						(status === 'pending' || status === 'estimation' ) &&
						<Button style={{width: '150px', marginBottom: '16px'}} color="secondary" variant='outlined' onClick={(event)=>{ onActionClick(event, 'declined', 'action')}}>Reject</Button>
					}

			</Grid>


		</Grid>
	)
}

const IncredibleMatchedPanel = ({ data, updateStatus, children }) => {
	const [panelExpand, setpanelExpand] = useState(false);

	const handleExpansionPanel = (event, value, type) => {
		console.log(type);
		if(type === 'action') {
			event.stopPropagation();
			handleUpdateStatus(value);
		}else	if(type === 'expand') {
			setpanelExpand(value);
		}
	}

	const handleUpdateStatus = (value) => {
		updateStatus(value,data.id);
	}

	return (
		<React.Fragment>
			<ExpansionPanel onChange={(event, expanded)=>{ handleExpansionPanel(event, expanded, 'expand')}} expanded={panelExpand}>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<MatchedResultRow onActionClick={ handleExpansionPanel } data={data}/>
					{/* <Grid container justify="space-between" spacing={2}>
							{ renderDataRows }
							<MatchedResultRow/>
					</Grid> */}
				</ExpansionPanelSummary>
				<Divider />
				<ExpansionPanelDetails>
					<Grid container direction="column" spacing={2}>
						{ children }
					</Grid>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</React.Fragment>
	)
}

export default IncredibleMatchedPanel
