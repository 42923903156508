import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import ChecklistItemForm from './ChecklistItemForm';
import ChecklistItem from './ChecklistItem';

import {
  addCheckList,
  deleteCheckList,
  updateCheckList
} from '../../../../../redux/actions';

class Checklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checklistData: this.props.deliverable.checklists ? this.props.deliverable.checklists : []
     }
  }

  handleCreateChecklistItem  = (item) => {
    const { addCheckList, deliverable } = this.props;
    const { checklistData } = this.state;

    addCheckList(deliverable.id, item)
      .then(response => {

        let responseChecklistItem = response.data.data;

        const {
          checklist_text,
          due_date,
          status
        } = responseChecklistItem.attributes;

        let checklistItem = {
          id: responseChecklistItem.id,
          checklistText: checklist_text,
          dueDate: due_date,
          status: status,
        };

        checklistData.push(checklistItem);

        this.setState({
          checklistData
        });
      })
  }

  handleDeleteChecklistItem = (index) => {
    const { deleteCheckList } = this.props;
    const { checklistData } = this.state;

    deleteCheckList(checklistData[index].id)
      .then(response => {
        checklistData.splice(index,1);

        this.setState({
          checklistData
        });
      })
  }

  handleUpdateChecklistItem = (item, index) => {
    const { updateCheckList } = this.props;
    const { checklistData } = this.state;

    updateCheckList(item)
      .then(response => {
        let responseChecklistItem = response.data.data;

        const {
          checklist_text,
          due_date,
          status
        } = responseChecklistItem.attributes;

        let checklistItem = {
          id: responseChecklistItem.id,
          checklistText: checklist_text,
          dueDate: due_date,
          status: status,
        };

        checklistData[index] = JSON.parse(JSON.stringify(checklistItem));

        this.setState({
          checklistData
        });
      });
  }

  render() {
    const { checklistData } = this.state;

    return (
      <div>
        <Typography variant="subtitle1"><strong>Checklist</strong></Typography>

        {
          checklistData.length > 0 &&
          checklistData.map((item, key)=>(
            <ChecklistItem onUpdate={ this.handleUpdateChecklistItem } onDelete={ this.handleDeleteChecklistItem } index={key} key={key} item={item}/>
          ))
        }

        <ChecklistItemForm onCreate={ this.handleCreateChecklistItem }/>
      </div>
     );
  }
}

const mapStateToProps = (state) => {
	return state
}

export default connect(mapStateToProps, {
  addCheckList,
  deleteCheckList,
  updateCheckList
})(Checklist);
