import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import AppsIcon from '@material-ui/icons/Apps';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MyAvatar from '../MyAvatar';
import MyDrawer from '../MyDrawer';
import IconButton from '@material-ui/core/IconButton';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
  appBarMenuItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '8px'
  },
  appBarMenuItemText: {
    fontWeight: '500',
    marginLeft: '8px',
    fontSize: '16px'
  },
  appBarLeftContainer: {
    display: 'flex',
    alignItems: 'center'
  }

});

class Dashboard extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      open: false,
      // selectedProject: (this.props.data.user.projects && this.props.data.user.roles[0].name === 'Client') ? this.props.data.user.projects[0].id : null
      selectedProject: (this.props.data.user.projects && this.props.data.user.roles[0].name === 'Client') ? this.props.location.pathname.replace('/project/','') : null
    };
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathname.replace('/project/') !== prevState.selectedProject) {
      return {
        // selectedProject: nextProps.location.pathname.replace('/project/','')
        selectedProject: localStorage.getItem('projectId')
      };
    }
  }

  handleSelectProject = (event) => {
    console.log('value', event.target.value);
    this.props.history.push(`/project/${event.target.value}`);
    localStorage.setItem('projectId',event.target.value);
    this.setState({
      selectedProject: event.target.value
    }, () => {
      console.log(this.state.selectedProject);
      // this.props.history.go(`/project/${this.state.selectedProject}`);
    });
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, data, myListItems, history } = this.props;
    const { user } = data;
    const  role  = user.roles[0].name;
    const {
      location
    } = history;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
            {
              role !== 'Client' &&
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden,
                )}
              >
                <MenuIcon />
              </IconButton>
            }

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              style={{marginLeft: '24px'}}
              className={classes.title}
            >
              Get Incredibles
            </Typography>
            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <div className={classes.appBarLeftContainer}>
              {
                role === 'Client' &&
                <Link to="/">
                  <div className={classes.appBarMenuItem}>
                    <AppsIcon/>
                    <div className={classes.appBarMenuItemText}>Projects</div>
                  </div>
                </Link>
              }
              <MyAvatar signOut={this.props.signOut} user={user}/>
            </div>
          </Toolbar>
        </AppBar>
        {
          (role === 'Client' && location.pathname !== '/' && location.pathname !== '/create/project') &&
          <MyDrawer open={true} handleDrawerClose={this.handleDrawerClose}>

            {
              user.projects &&
              <FormControl style={{marginLeft: '8px',paddingRight:'16px',marginTop:'16px', marginBottom: '32px'}} className={classes.formControl} fullWidth>
                <InputLabel htmlFor="age-simple">Selected Project</InputLabel>
                <Select
                  value={this.state.selectedProject}
                  onChange={this.handleSelectProject}
                >
                  {
                    user.projects.map((p,i)=>(
                      <MenuItem value={p.id} key={i}>{p.title}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            }
            {myListItems()}
          </MyDrawer>
        }

        {
          role === 'Incredible' &&
          <MyDrawer open={this.state.open} handleDrawerClose={this.handleDrawerClose}>
              {myListItems()}
          </MyDrawer>
        }

        <div className={classes.root} id='subDrawer'></div>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
            {this.props.children}
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Dashboard));
