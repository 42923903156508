import gql from 'graphql-tag'

export default gql`
query FetchUserMatches($id: ID!) {
  user(id: $id) {
    id
    talentMatches {
      id
      status
      technicalRequirement {
        id
        domain
        startDate
        duration
        languages
        technicalExpertise
        timezone
        tools
        talentType
        project {
          id
          description
          domain
          stageAt
          targetPlatform
          title
          user {
            id
            username
            profile {
              id
              profilePicture
            }
          }
        }
      }
    }
  }
}
`
