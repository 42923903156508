import {
	put,
	post,
	get
} from 'axios';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const createProject = ({
	projectName: title,
	projectDescription: description,
	domains: domain,
	projectStage: stage_at,
	platforms: target_platform,
	references
}, refetch) => {
	return (dispatch, getState) => {

		const state = getState()
		const {
			auth
		} = state;
		const {
			user
		} = auth;
		const {
			token,
			id
		} = user;

		const postParams = {
			project: {
				title,
				description,
				domain,
				stage_at,
				target_platform,
			},
			references
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return post(`${API_BASE_URL}/api/v1/users/${id}/projects`, postParams, config).then(resp => {
			refetch();

		}).catch(error => {
			console.log('error', error);
		})
	}
}


export const postPeerReview = (values) => {
	return (dispatch, getState) => {

		const state = getState()
		const {
			auth
		} = state;
		const {
			user
		} = auth;
		const {
			token,
			id
		} = user;

		// const postParams = {
		// 	peer_review: {
		// 		sender_id: `${id}`,
		// 		...values
		// 	},
		// }
		const postParams = {};

		// Need to change to correct spelling later
		if (values.receiver_email) {
			postParams.peer_invite = {
				sender_id: `${id}`,
				...values
			}
		} else {
			postParams.peer_review = {
				sender_id: `${id}`,
				...values
			}
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}

		if (postParams.peer_invite) {
			return post(`${API_BASE_URL}/api/v1/users/${id}/peer_invites`, postParams, config);
		} else {
			return post(`${API_BASE_URL}/api/v1/users/${id}/peer_reviews`, postParams, config);
		}
	}
}

export const checkInviteEmail = (values) => {
	return (dispatch, getState) => {

		const state = getState()
		const {
			auth
		} = state;
		const {
			user
		} = auth;
		const {
			token,
			id
		} = user;
		const {
			email
		} = values;

		// const postParams = {
		// 	peer_review: {
		// 		sender_id: `${id}`,
		// 		...values
		// 	},
		// }

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return get(`${API_BASE_URL}/api/v1/users/${id}/peer_invites/email_exists?email=${email}`, config);
	}
}

export const updatePeerReview = (values, ratingId) => {
	return (dispatch, getState) => {

		const state = getState()
		const {
			auth
		} = state;
		const {
			user
		} = auth;
		const {
			token,
			id
		} = user;

		const postParams = {
			peer_review: {
				// sender_id: `${id}`,
				...values
			},
		}

		console.log(postParams);

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return put(`${API_BASE_URL}/api/v1/peer_reviews/${ratingId}`, postParams, config);
	}
}

export const declinePeerRequest = (reviewId) => {
	return (dispatch, getState) => {

		const state = getState()
		const {
			auth
		} = state;
		const {
			user
		} = auth;
		const {
			token,
			id
		} = user;

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return axios.delete(`${API_BASE_URL}/api/v1/peer_reviews/${reviewId}`, config);
	}
}
