import React, { Component } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import MyAvatar from '../../../../../../../Components/MyAvatar';
import { connect } from 'react-redux';
import { updateDeliverable } from '../../../../../../../redux/actions';

class AssignedUsers extends Component {
	state = {
		isEdit: false,
		assignee: this.props.data.assignee
	}

	handleClick = (checked,data) => {
		const { isEdit } = this.state;
		const { id } = data;
		const { updateDeliverable } = this.props;
		const { id: deliverableId} = this.props.data;

		if(isEdit && checked) {
			updateDeliverable({assigned_to: id}, deliverableId);
			this.setState({ assignee: data })
		}
		else {
			updateDeliverable({assignee: null}, deliverableId);
			this.setState({ assignee: null })
		}
		this.setState({isEdit : !isEdit})
	}

	render() {
		const { data, canEdit = false } = this.props;
		const { possibleAssignees } = data;
		const { isEdit, assignee } = this.state;
		return (
			<Grid item>
			<Grid container alignItems="center" justify={'space-between'}>
				{
					!isEdit ?
					<Typography variant="subtitle2" gutterBottom>
						Assigned to
					</Typography>
					:
					<Typography variant="subtitle2" gutterBottom>
						Choose from hired incredibles
					</Typography>
				}
				{
					canEdit &&
					<Button onClick={() => this.setState({isEdit: !isEdit})} size="small" color="primary">
						{ !isEdit ? 'Edit' : 'Save' }
					</Button>
				}
			</Grid>
				<Grid container spacing={2}>
				{
					!isEdit && assignee &&
					<Grid item><MyAvatar user={assignee} hasMenu={false} size={'large'} /></Grid>
				}
				{
					isEdit && possibleAssignees.map(item => <Grid item><MyAvatar onClick={(checked) => this.handleClick(checked,item)} user={item} checked={assignee && assignee.id === item.id ? true : false} checkIcon={true} hasMenu={false} size={'large'} /></Grid>)
				}
				</Grid>
			</Grid>
		)
	}
}

const mapStateToProps = (state) => {
	return state;
}

export default connect(mapStateToProps,{updateDeliverable})(AssignedUsers);
