import React from 'react';
import { Grid, Typography, Divider, Button } from '@material-ui/core';
import Dialog from '../../../../Components/Dialog';
import EditDialog from './Component/EditDialog';
import Tags from '../Project/Components/Tags';

const Overview = ({data, editMode = false, refetch}) => {

	const {
		overview,
		specialization,
		domain,
		clients
	} = data;

	return (
		<Grid container xs={12} item spacing={2}>
			<Grid container xs = {12} item spacing={2} justify="space-between">
				<Typography variant={'h5'} style={{marginLeft: '6px'}} gutterBottom>
					Overview
				</Typography>
				{
					editMode &&
					<Dialog title={'Overview Details'} content={(props) => <EditDialog handleClose={props} refetch={refetch} data={data}/>}>
						<Button size="small" color="primary">
							Edit
						</Button>
					</Dialog>
				}
			</Grid>

			<Grid item xs={12}>
				<Typography gutterBottom paragraph>
					{overview ? overview : 'N/A'}
				</Typography>
			</Grid>

			{/* <Grid item xs={12}>
				<Tags data={domain}/>
			</Grid> */}

			{/* <Grid item xs={12}>
				<Typography variant={'subtitle2'} gutterBottom>
					Specializes in:
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Tags data={specialization}/>
			</Grid> */}

			{
				(domain && domain.length > 0) &&
				<>
					<Grid item xs={12}>
						<Typography variant={'h5'}>
							Domains:
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Tags data={domain}/>
					</Grid>
				</>
			}

			{
				(clients && clients.length > 0) &&
				<>
					<Grid item xs={12}>
						<Typography variant={'h5'}>
							Clients:
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Tags data={clients}/>
					</Grid>
				</>
			}
			<Divider/>
		</Grid>
	)
}

export default Overview;
