import React, { Component, Children } from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import {withRouter} from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { declinePeerRequest } from './../../../../../redux/actions';

import PeerReviewForm from './../PeerReviewForm';


import MyDialog from './../../../../../Components/Dialog';

const PeerCard = (props) => {
  const { incredible, refetch, type } = props;

  const { username, profile } = incredible;
  const { role, domain} = profile;

  const checkPeerStatus = () => {
    let userId = `${JSON.parse(localStorage.getItem('user')).id}`;
    let sentRatings = incredible.receivedRatings;

    if(sentRatings.length > 0) {
      if(sentRatings.map(r=>r.receiverId).indexOf(userId) != -1) {
        return true
      }
      // console.log(sentRatings.map(r=>r.receiverId))
      // console.log(sentRatings.map(r=>r.receiverId).indexOf(userId))
    }

    return false
  }

  const handleDeclinePeerRequest = () => {
    const { ratingId } = incredible;
    props.declinePeerRequest(ratingId).then(response=>{
      refetch();
    })
  }

  return (
      <Card style={peerCardStyles.cardContainer}>
         <MyDialog title="Peer Review" content={(renderProps)=>{return (<PeerReviewForm mode='view' onClose={renderProps} refetch={refetch} incredible={incredible}/>)}}>
            {
              ({onOpenDialog})=>{
                return(
                  <Avatar onClick={()=>{
                    window.open(`${window.location.origin}/user/${incredible.id}/profile`);
                    // props.history.push(`/user/${incredible.id}/profile`)
                  }} style={peerCardStyles.avatar}>{username ? username.charAt(0).toUpperCase(): 'N/A'}</Avatar>
                )
              }
            }
          </MyDialog>
          <Typography variant="subtitle1" gutterBottom>{ username }</Typography>

          {
            role &&
            <Typography variant="subtitle2" gutterBottom>{role ? role: 'N/A'}</Typography>
          }

          {/* <div style={peerCardStyles.chipsContainer}>
            {
              (domain && domain.length > 0) &&
              domain.slice(0,3).map((d,i)=> (<Chip style={peerCardStyles.chip} key={i} label={d}/>))
            }
          </div> */}

          {
            type === 'mypeers' &&
            <LinearProgress style={{width: '80%', height: '8px', borderRadius: '8px', marginTop: '10px' }} color="primary" variant="determinate" value={(incredible.aggregate*10)} />
          }

          {
            type === 'allMyPeers' &&
            <LinearProgress style={{width: '80%', height: '8px', borderRadius: '8px', marginTop: '10px' }} color="primary" variant="determinate" value={(incredible.aggregate*10)} />
          }

          <div>
              {
               (type === 'incredibles' && checkPeerStatus())
               ? <div style={peerCardStyles.peerRequestSentText}>Peer Request Sent!</div>
               : <CardActions>
                {
                  // (type !== 'mypeers' && type !== 'allMyPeers') &&
                  (type !== 'allMyPeers') &&
                  <MyDialog title="Peer Review" content={(renderProps)=>{return (<PeerReviewForm mode={type === 'mypeers' ? 'view': null} onClose={renderProps} refetch={refetch} incredible={incredible}/>)}}>
                    {
                      ({onOpenDialog})=>{
                        return(
                          <div style={{display:'flex',flexDirection: 'column'}}>
                            {
                              (type !== 'sent' && type !== 'mypeers') &&
                              <Button onClick={onOpenDialog} variant="contained" color="primary">Mark As Peer</Button>
                            }
                            {
                              type === 'received' &&
                              <Button onClick={handleDeclinePeerRequest} style={{marginTop: '8px'}} variant="outlined" color="secondary">Decline</Button>
                            }

                            {/* {
                              type === 'mypeers' &&
                              <Button onClick={onOpenDialog} style={{marginTop: '8px'}} variant="outlined" color="primary">View</Button>
                            }

                            {
                              type === 'sent' &&
                              <Button onClick={onOpenDialog} style={{marginTop: '8px'}} variant="outlined" color="primary">View</Button>
                            } */}
                          </div>
                        )
                      }
                    }
                  </MyDialog>
                }
              </CardActions>
              }
          </div>
      </Card>
  )
}

const peerCardStyles = {
  cardContainer: {
    width: '250px',
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center'
    paddingTop:'32px',
    alignItems: 'center',
    marginRight: '24px'
  },
  avatar: {
    height: '75px',
    width: '75px',
    marginBottom: '8px'
  },
  chipsContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: "wrap",
      margin: '8px 0px',
  },
  chip: {
    margin: '4px'
  },
  peerRequestSentText: {
    backgroundColor: '#673ab71f',
    padding: '5px 10px',
    borderRadius: '5px',
    color: '#525252'
  }
}

const mapStateToProps = (state) => {
  return state;
}


export default connect(mapStateToProps,{ declinePeerRequest })(withRouter(PeerCard));
