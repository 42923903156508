import React, { Component } from 'react';
//import { deleteProject } from '../../../../actions';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '@material-ui/icons/Visibility';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import MyDialog from './../../../../../Components/Dialog';
import TechnicalRequirementModal from './TechnicalRequirementModal';

const styles = theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
  table: {
    minWidth: 700,
  },
});

class View extends Component {
  render() {
    let { props } = this;
    let { data, classes } = props;
    let { project } = data;

    const {
      description,
      domain,
      hasCalled = true,
      hasIncredible,
      id,
      requirements,
      stageAt = [],
      targetPlatform,
      technicalRequirements = [],
      title,
    } = project

    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          Project Overview
        </Typography>
        <Grid container spacing={4}>
          <Grid item key={id} xs={12}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Grid container alignItems="center" justify="space-between">
                  <Typography gutterBottom variant="h5">
                    { title }
                  </Typography>
                  <CardActions>
                    <Button size="small" color="primary">
                      <Link to={`/project/${id}`}>
                        Edit
                      </Link>
                    </Button>
                  </CardActions>
                </Grid>
                <Typography variant="h6">
                  Description
                </Typography>
                <Typography paragraph>
                  { description }
                </Typography>
                <Typography variant="h6">
                  Domains
                </Typography>
                <Typography paragraph>
                  { domain && domain.join(', ') }
                </Typography>
                <Typography variant="h6">
                  Target Platforms
                </Typography>
                <Typography paragraph>
                  { targetPlatform && targetPlatform.join(', ') }
                </Typography>
                <Typography variant="h6">
                  Current Project Stage
                </Typography>
                <Typography paragraph>
                  { stageAt }
                </Typography>
                <Grid container alignItems="center" justify="space-between">
                  <Typography gutterBottom variant="h6">
                    Incredibles that i need
                  </Typography>
                  <Button onClick={()=> this.props.history.push(`/project/${id}/createRequirement`)} size="small" color="primary">Add requirements</Button>
                </Grid>
              </CardContent>
              <Divider/>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Talent Type</TableCell>
                    <TableCell align="right">Specialization</TableCell>
                    <TableCell align="right">Duration</TableCell>
                    <TableCell align="right">Start Date</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {technicalRequirements.map(row => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.talentType}
                      </TableCell>
                      <TableCell align="right">{row.domain.join(', ')}</TableCell>
                      <TableCell align="right">{row.duration}</TableCell>
                      <TableCell align="right">{row.startDate}</TableCell>
                      <TableCell align="right">{'N/A'}</TableCell>
                      <TableCell align="right">
                        <MyDialog title="Talent Requirements" content={(renderProps)=>{return (<TechnicalRequirementModal technicalRequirements={row}/>)}}>
                          {
                            ({onOpenDialog})=>(
                              <IconButton onClick={onOpenDialog}>
                                <ViewIcon/>
                              </IconButton>
                            )
                          }
                        </MyDialog>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(View)
