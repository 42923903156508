import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';

class NavItems extends Component {
  state = {
    list: this.props.list,
    path: this.props.path
  }

  static getDerivedStateFromProps(props, state) {
    const { pathname } = props.location;

    if(pathname !== state.path){
      return {
        path: pathname
      }
    }
  }

  render(){
    const { list, path } = this.state;
    return (
      <React.Fragment>
        {
          list.map((item,index) => {
            const isSelected = item.to === path ? true: false
            return (
              <Link key={index} to={item.projectLink ? item.to.replace('projectId',localStorage.getItem('projectId')) : item.to}>
                <ListItem button selected={isSelected}>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              </Link>
            )
          })
        }
      </React.Fragment>
    )
  }
};

export default withRouter(NavItems);
