import {
    IN_PROGRESS,
    SIGN_IN_ERROR,
    SIGN_IN_SUCCESS,
    SIGN_OUT_ERROR,
    SIGN_OUT_SUCCESS,
    SIGN_UP_ERROR,
    SIGN_UP_SUCCESS,
    SET_AUTH_TOKEN,
  } from '../actions';

const INITIAL_STATE = {
  loading: false,
  user: null,
  error: null,
  status: false,
};

export default (state = INITIAL_STATE, action) => {
    let user = null;
    const { payload } = action;
    const { data, headers } = payload ? payload: {};

    switch (action.type) {
        case IN_PROGRESS:
            return { ...state, status: false, loading: true }

        case SIGN_IN_ERROR:
            return { ...state, user: null, error: action.payload, loading: false }
        case SIGN_IN_SUCCESS:
            user = { token: headers.authorization, id: data.user.id };
            localStorage.setItem('user', JSON.stringify(user));
            return { ...state, error: null, user, loading: false }

        case SIGN_OUT_ERROR:
            return { ...state, error: action.payload, loading: false }
        case SIGN_OUT_SUCCESS:
            localStorage.removeItem('user');
            return { ...state, user: null, error: null, loading: false }

        case SIGN_UP_ERROR:
            return { ...state, status: false, error: action.payload, loading: false }
        case SIGN_UP_SUCCESS:
            return { ...state, status: true, error: null, loading: false }

        case SET_AUTH_TOKEN:
            user = JSON.parse(localStorage.getItem('user'));
            return { ...state, error: null, user, loading: false }
        default:
            return state;
    }
};
