import React from 'react';
import { Card, CardContent, Paper, Grid, Typography, Divider, Button } from '@material-ui/core';
import fetchUserProfile from '../../graphQL/Query/fetchUserProfile';
import { graphql } from 'react-apollo';
import Loader from '../Loader';
import Project from './Components/Project';
import ProfileOverview from './Components/ProfileOverview';
import Overview from './Components/Overview';
import SkillsAndTools from './Components/SkillsAndTools';
import EditDialog from './Components/Project/Components/EditDialog';
import Dialog from '../../Components/Dialog';
import MyAvatar from './../MyAvatar';
import EmailIcon from '@material-ui/icons/Email';
import LocationIcon from '@material-ui/icons/LocationOn';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import {useTheme } from '@material-ui/core/styles';

import skypeIcon from './../../assets/icons/skype.svg';
import linkedIcon from './../../assets/icons/linkedin.svg';

const Projects = ({ data = {}, refetch, editMode = false }) => {
	const { profileProjects, id } = data;
	return (
		<Grid container xs={12} item spacing={2}>
			<Grid container item justify="space-between">
				<Typography variant={'h5'} gutterBottom>
					Projects
				</Typography>
				{
					editMode &&
					<Dialog title={'Add Project'} content={(props) => <EditDialog id={id} data={data} handleClose={props} refetch={refetch}/>}>
						<Button size="small" color="primary">
							Add
						</Button>
					</Dialog>
				}
			</Grid>

			{
				profileProjects.length === 0 &&
				<Typography style={{marginLeft: '8px'}} variant={'subtitle1'} gutterBottom>
					No Projects Available!
				</Typography>
			}

			{
				(profileProjects && profileProjects.length > 0) &&
				profileProjects.sort((a,b)=>{
					if(parseInt(a.id) < parseInt(b.id)){
						return -1;
					}
					if(parseInt(a.id) > parseInt(b.id)){
						return 1;
					}
					return 0;
				}).map((proj,index) => {
					return (
						<React.Fragment key={index}>
							<Project data={proj} refetch={refetch}/>
							{
								index + 1 < profileProjects.length ?
								<Grid item xs={12}><Divider/></Grid> : null
							}
						</React.Fragment>
					)
				})
			}
		</Grid>
	)
}

const TabContainer = ({ children, dir }) => {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}

const Profile = (props) => {
	const { data, editMode = false } = props;
	const { loading, user = {}} = data;
	const { profile } = user;
	const theme = useTheme();
	const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  }

	if(loading)
		return <Loader/>

	return (
		<div>
		<Paper>
			<Card>
				<CardContent>
					{/* <ProfileOverview user={data.user}/> */}
					<ProfileOverview editMode={editMode} data={user} refetch={data.refetch} />


				{/* <Grid container spacing={2}>
						<Grid container item xs={3} spacing={2}>
							<SideBar editMode={editMode} data={user} refetch={data.refetch} />
						</Grid>
						<Grid container item xs spacing={2}>
							<Overview editMode={editMode} refetch={data.refetch} data={profile}/>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<SkillsAndTools editMode={editMode} refetch={data.refetch} data={profile} />
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Projects editMode={editMode} refetch={data.refetch}  data={profile}/>
						</Grid>
				</Grid> */}
				</CardContent>
			</Card>
		</Paper>

		<AppBar position="static" style={{backgroundColor: '#FFFFFF', marginTop: '24px'}} color="default">
			<Tabs
				value={value}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				// variant="fullWidth"
			>
				{/* <Tab label="Item One" /> */}
				<Tab label="About" />
				<Tab label="Projects" />
			</Tabs>
		</AppBar>

		<SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {/* <TabContainer dir={theme.direction}>
				<Paper elevation="1" style={{padding:'24px', borderRadiusTopleft: '0x', borderRadiusTopRight: '0x'}}>
							<ProfileOverview user={data.user}/>
					</Paper>
				</TabContainer> */}
        <TabContainer dir={theme.direction}>
					<Paper elevation="1" style={{padding:'24px', borderRadius: '0px'}}>
						<Overview editMode={editMode} refetch={data.refetch} data={profile}/>
						<SkillsAndTools editMode={editMode} refetch={data.refetch}  user={user} />
					</Paper>
				</TabContainer>
        <TabContainer dir={theme.direction}>
					<Paper elevation="2" style={{padding:'24px', borderRadius: '0px'}}>
						<Projects editMode={editMode} refetch={data.refetch}  data={profile}/>
					</Paper>
				</TabContainer>
      </SwipeableViews>
	</div>
	);
}

export default graphql(fetchUserProfile,{
  options: (props) => {
    return {variables: {id: props.match.params.userId}}
  }
})(Profile);
