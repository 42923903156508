import gql from 'graphql-tag'

export default gql`
query assignedProjects($id: ID!) {
	user(id: $id) {
		id
		incredibleProjects {
      id
      description
      title
		}
		talentMatches{
      id
      status
      technicalRequirement {
        id
        project {
					id
          title
          getEstimateIncredibles {
            id
            username
          }
          hiredIncredibles {
            id
            username
          }
          deliverables {
            id
            description
            client {
              id
              username
            }
            incredibles {
              id
              username
            }
            documents {
              id
              name
              serviceUrl
              recordType
              filename
              extension
            }
            checklists {
              id
              status
              checklistText
              dueDate
            }
            comments {
              id
              description
              createdAt
              user {
                id
                username
              }
            }
            title
          }
          contracts {
            id
            userId
            status
            ratePerHour
            hoursPerWeek
            startDate
            endDate
          }
        }
      }
    }
	}
}
`
