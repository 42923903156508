import React from 'react'

const Tags = ({ data }) => {
  const styles = {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    tag: {
      padding: '5px 20px',
      backgroundColor: '#e6e6e6',
      marginRight: 10,
      marginBottom: 10,
      fontSize: 15,
      borderRadius: 5,
    }
  }

  const tags = data ? data.map((text,index) => {
    return (
      <div key={index} style={styles.tag}>{text}</div>
    )
  }) : null

  return (
    <div style= {styles.container}>
      { tags }
    </div>
  )
}

export default Tags