import { authRef, userRef, provider } from "../../config/firebase";
import { post, get } from 'axios'
export const IN_PROGRESS = 'IN_PROGRESS';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const loginRequest = ({ email, password, googleId }) => {
  let data = {
    user: {
      email: email.trim()
    }
  };

  if (googleId) {
    data.user.google_id = googleId;
  } else {
    data.user.password = password;
  }

  return (dispatch) => {
    dispatch({
      type: IN_PROGRESS,
      payload: null
    });
    return post(`${API_BASE_URL}/login`,data)
      .then(data => {
        dispatch({
          type: SIGN_IN_SUCCESS,
          payload: data
        });
        return data
      }).catch(err => {
        dispatch({
          type: SIGN_IN_ERROR,
          payload: err
        });
        return { type:'error', message: 'Invalid email/password' }
      })
  }
}

export const retrieveUser = () => {
    return (dispatch) => {
        dispatch({
          type: IN_PROGRESS,
          payload: null
        });
        dispatch({
          type: SET_AUTH_TOKEN,
          payload: null
        })
        // authRef.onAuthStateChanged( user => {
        //     console.log('auth changed', user);
        //     if (user) {
        //         const { uid, displayName, email, emailVerified, photoURL } = user;
        //         dispatch({
        //             type: SIGN_IN_SUCCESS,
        //             payload: { user: { uid, displayName, email, emailVerified, photoURL } },
        //         })
        //     } else {
        //         dispatch({
        //             type: SIGN_OUT_SUCCESS,
        //             payload: 'user logged out'
        //         })
        //     }
        // })
    }
}

export const signInWithCredentials = ({ email, password }) => {
    return (dispatch) => {
        dispatch({
            type: IN_PROGRESS,
            payload: null
        });
        //authRef.signOut();
        authRef.signInWithEmailAndPassword(email,password)
        .catch( error => {
            dispatch({
                type: SIGN_IN_ERROR,
                payload: error
            })
        })
    }
}

export const signUp = (props) => {

    const {
      email = '',
      password = '',
      confirmPassword: password_confirmation = '',
      fname = '',
      lname = '',
      userType: role = 'Client',
      incredibleType: functional_area = '',
      linkedin_profile,
      skype_id,
      client_description
    } = props;

    const postParams = {
      user: {
        email:email.trim().toLowerCase(),
        functional_area,
        password,
        password_confirmation,
        username: fname.trim()+' '+lname.trim(),
        role,
        linkedin_profile,
        skype_id
      }
    }

    if(role === 'Client') {
      postParams.user.client_description = client_description
    }

    return (dispatch) => {
        dispatch({
            type: IN_PROGRESS,
            payload: null
        });

        post(API_BASE_URL+'/signup',postParams).then( res => {
          dispatch({
              type: SIGN_UP_SUCCESS,
              payload: res
          });
          //dispatch(loginRequest(postParams.user));
        })
        .catch( error => {
          dispatch({
              type: SIGN_UP_ERROR,
              payload: error.response ? error.response.data.errors : error.message
          })
        })

        // authRef.createUserWithEmailAndPassword(details.email,details.password).then( res => {
        //     dispatch({
        //         type: SIGN_UP_SUCCESS,
        //         payload: res
        //     });
        //     userRef.doc(res.user.uid).set(details).catch(res => {
        //         console.log('error',res);
        //     });
        // })
        // .catch( error => {
        //     console.log('error',error);
        //     dispatch({
        //         type: SIGN_UP_ERROR,
        //         payload: error
        //     })
        // })
    }
}

export const forgotPassword = (email) => {
  return (dispatch, getState) => {
    return post(`${API_BASE_URL}/forgot_password`, {
      email: email
    })
  }
}

export const signIn = () => {
    return (dispatch) => {
        dispatch({
            type: IN_PROGRESS,
            payload: null
        });

        authRef.signInWithPopup(provider)
        .catch(error => {
            dispatch({
                type: SIGN_IN_ERROR,
                payload: error
            })
        });
    }
};

export const signOut = () => {
    console.log('signOut')
    return (dispatch) => {
      dispatch({
          type: SIGN_OUT_SUCCESS,
          payload: 'user logged out'
      })
      // authRef.signOut()
      // .catch(error => {
      //     dispatch({
      //         type: SIGN_OUT_ERROR,
      //         payload: error
      //     })
      // });
    }
};
