import React, { Component, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import RejectedIcon from "@material-ui/icons/Close";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  ExpansionPanelSummary,
  ExpansionPanel,
  ExpansionPanelDetails,
  Button
} from "@material-ui/core";
import MyAvatar from "../../../../../Components/MyAvatar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Panel from "../../../../../Components/Panel";
import { connect } from "react-redux";
import { updateMatch } from "../../../../../redux/actions";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import { useTheme } from "@material-ui/core/styles";

import skypeIcon from "./../../../../../assets/icons/skype.svg";
import linkedIcon from "./../../../../../assets/icons/linkedin.svg";
import BannerMessage from './../../../../../Components/BannerMessage';

import IncredibleHireContract from './IncredibleHireContract';
import IncredibleAcceptContract from './IncredibleAcceptContract';

const Match = ({ user, status, updateStatus, projectId, id }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const styles = {
    container: {
      display: "flex"
    },
    matchContainer: {
      padding: "16px 0px"
    },
    actionButton: {
      width: "132px",
      fontSize: "12px",
      padding: "6px"
    },
    state: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      height: "100%",
      padding: 5
    },
    contactIcon: {
      margin: "0px 4px",
      cursor: "pointer"
    }
  };
  let isCTA = status === "cta" || status === "get_estimate" ? true : false;
  let isGetEstimate = status === "get_estimate" ? true : false;

  const {id:userId, username, profile } = user;

  const {
    availability,
    region,
    timezone,
    minBidRate,
    overview,
    skype,
    linkedinProfile
  } = profile;

  if (!status || status === "reject") return null;

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        autoHideDuration={3000}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{message} Copied</span>}
      />
      <Grid
        container
        item
        alignItems="center"
        justify="space-between"
        spacing={2}
        style={styles.matchContainer}
      >
        <Grid container justify="center" item xs={2}>
          <Link
            to={{
              pathname: "incredible/" + user.id,
              state: {
                user
              }
            }}
            className="col-md-2"
          >
            <MyAvatar size="extraLarge" hasMenu={false} user={user} />
          </Link>
        </Grid>
        <Grid container item xs>
          <Grid container item direction="row" alignItems="center">
            <Typography variant="h6">{username}</Typography>

            <CopyToClipboard text={skype}>
              <img
                onClick={() => {
                  setOpen(true);
                  setMessage("SkypeId");
                }}
                style={styles.contactIcon}
                src={skypeIcon}
                height="24px"
                alt="skype-icon"
              />
            </CopyToClipboard>

            <CopyToClipboard text={linkedinProfile}>
              <img
                onClick={() => {
                  setOpen(true);
                  setMessage("Linkedin Profile Link");
                }}
                style={styles.contactIcon}
                src={linkedIcon}
                height="24px"
                alt="linkedin-icon"
              />
            </CopyToClipboard>
          </Grid>

          <Typography variant={"body2"}>
            {overview ? `${overview.substring(0, 100)}...` : ""}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant={"body2"}>
            {timezone ? timezone : "timezone"}
          </Typography>
        </Grid>

        {/* <Grid container justify="center" item xs>
          <Typography>{"$"+minBidRate ? minBidRate : 'N/A'}</Typography>
          <Typography variant={'body2'}>min rate/hr</Typography>
        </Grid> */}

        <Grid
          container
          direction="column"
          item
          xs
          spacing={2}
          alignItems="center"
          justify="center"
        >
          {status === "matched" && (
            <Grid item justify="center" container>
              <Button
                style={styles.actionButton}
                variant="outlined"
                onClick={() => updateStatus("get_estimate", id)}
                color="primary"
              >
                Get Estimatess
              </Button>
            </Grid>
          )}

          {status === "get_estimate" && (
            <Fragment>
              <IncredibleHireContract projectId={projectId} userId={userId}/>
              <IncredibleAcceptContract/>
            </Fragment>
            // <Grid item justify="center" container>
            //   <Button
            //     style={styles.actionButton}
            //     variant="outlined"
            //     onClick={() => updateStatus("hired", id)}
            //     color="primary"
            //   >
            //     Hire
            //   </Button>
            // </Grid>
          )}

          {(status === "get_estimate" || status === "matched") && (
            <Grid item justify="center" container>
              <Button
                style={styles.actionButton}
                variant="outlined"
                onClick={() => updateStatus("rejected", id)}
                color="secondary"
              >
                Reject
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const TabContainer = ({ children, dir }) => {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
};

const FilteredTalentMatchesTabContent = ({
  talentMatches,
  updateStatus,
  projectId,
  theme,
  status
}) => {
  let filteredTalentMathces = talentMatches.filter(tM => tM.status === status);
  return (
    <TabContainer dir={theme.direction} style={{padding: '8px'}}>
      {filteredTalentMathces.length > 0 ? (
        filteredTalentMathces.map((match, index) => {
          return (
            <React.Fragment key={match.id}>
              <Match
                updateStatus={updateStatus}
                projectId={projectId}
                {...match}
              />
              {index + 1 < talentMatches.length ? (
                <Grid item>
                  <Divider />
                </Grid>
              ) : null}
            </React.Fragment>
          );
        })
      ) : (
        <div style={{padding: '8px'}}>
          <BannerMessage message="There are no Incredibles to show in this section for now."/>
        </div>
      )}
    </TabContainer>
  );
};

const TechnicalRequirement = ({ data, updateStatus, projectId }) => {
  let count = 0;
  const theme = useTheme();
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangeIndex = index => {
    setTab(index);
  };

  const { talentType, talentMatches = [], technicalExpertise, id } = data;

  talentMatches.map(match => {
    return match.status !== null && match.status !== "reject" ? count++ : null;
  });

  const dataRows = [
    { label: "Incredibles Type", value: talentType },
    { label: "Specialization", value: technicalExpertise },
    { label: "Incredibles needed", value: 1 },
    { label: "Matches", value: count }
  ];

  return (
    <Panel data={dataRows} style={{ padding: "8px", backgroundColor: "red" }}>
      {talentMatches.length === 0 ? (
        <div style={{padding: '8px 24px 24px 24px'}}>
          <BannerMessage
            message="There are no Incredible matches against your talent requirements yet."
          />
        </div>
      ) : (
        <div style={{ padding: "8px" }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            style={{ boxShadow: "0px 2px 2px 0px rgba(224,224,224,0.6)" }}
          >
            <Tab label="Matched" />
            <Tab label="Getting Estimates" />
            <Tab label="Hired" />
            <Tab label="Declined Matches" />
          </Tabs>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={tab}
            onChangeIndex={handleChangeIndex}
          >
            {["matched", "get_estimate", "hired", "declined"].map(
              (status, index) => (
                <FilteredTalentMatchesTabContent
                  key={index}
                  talentMatches={talentMatches}
                  theme={theme}
                  projectId={projectId}
                  updateStatus={updateStatus}
                  status={status}
                />
              )
            )}
          </SwipeableViews>
        </div>
      )}
    </Panel>
  );
};

class Incredible extends Component {
  updateStatus = (status, id) => {
    this.props.updateMatch(status, id).then(resp => {
      this.props.data.refetch();
    });
  };

  render() {
    const { data } = this.props;
    const { project } = data;

    let { technicalRequirements, id } = project;

    return (
      <React.Fragment>
        <Typography variant="h4">Incredibles</Typography>

        <Grid container spacing={4}>
          <Grid item>
            <Typography variant="h6" style={{margin: '12px 0px 14px', fontWeight: '400'}}>Incredible Matches against requirements</Typography>

            {technicalRequirements.length === 0 && (
              <p>
                There are no Incredible matches against your talent
                requirements as yet.
              </p>
            )}

            {
              technicalRequirements.length > 0 &&
              technicalRequirements.map(req => (
                <TechnicalRequirement
                  key={req.id}
                  projectId={id}
                  data={req}
                  updateStatus={this.updateStatus}
                />
              ))
            }
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(
  mapStateToProps,
  { updateMatch }
)(Incredible);
