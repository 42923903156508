import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { Typography, TextField, Button, Grid, Avatar,  } from '@material-ui/core';

import { createComment } from './../../../../../redux/actions';

const CommentForm = ({onCreate, onComplete}) => {
  const [comment, setComment] = useState('');

  return (
    <div style={{marginTop: '16px', marginBottom: '24px'}}>
      <TextField onChange={(e)=>{setComment(e.target.value)}} value={comment} multiline fullWidth placeholder="Type your comment" variant="outlined"/>

      <div style={{display: 'flex', justifyContent: "flex-end", marginTop: '8px'}}>
        <Button onClick={()=>{
          onCreate(comment)
            .then(response => {
              const { created_at, description, username } = response.data.data.attributes;

              onComplete({
                description: description,
                createdAt: created_at,
                user: {
                  username
                }
              });
              setComment('');
            });
        }} variant="outlined" disabled={comment.length === 0} color="primary">Send</Button>
      </div>
    </div>
  )
}

const CommentCard = ({ comment }) => {
  const { description, user, createdAt } = comment;
  const { username } = user;

  return (
    <div style={{
      borderTop:' 1px solid #e4e4e4',
      padding: '16px 0px'
    }}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Avatar size>{username.charAt(0).toUpperCase()}</Avatar>
        </Grid>
        <Grid item xs={11} style={{paddingLeft: '16px'}}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant="subtitle1">
              <strong>{username}</strong>
            </Typography>

            <Typography variant="caption">
              <Moment fromNow>{ createdAt }</Moment>
            </Typography>
          </div>

          <Typography variant="body2">{description}</Typography>
        </Grid>
      </Grid>
    </div>
  )
}

class Comments extends Component {
  state = {
    comments: this.props.deliverable ? this.props.deliverable.comments.reverse() : []
  }

  handleCreateComment = (comment) => {
    const { deliverable, createComment } = this.props;

    return createComment({
      description: comment,
      user_id: JSON.parse(localStorage.getItem('user')).id
    }, deliverable.id);
  }

  handleCommentState = (comment) => {
    const { comments } = this.state;
    comments.unshift(comment);

    this.setState({
      comments
    })
  }

  render() {
    const { comments } = this.state;

    return (
      <div>
        <Typography variant="subtitle1"><strong>Comments</strong></Typography>
        <CommentForm onComplete={ this.handleCommentState } onCreate={this.handleCreateComment}/>

        {
          comments.length > 0 &&
          comments.map((comment,key)=>(
            <CommentCard comment={comment} key={key}/>
          ))
        }
      </div>
     );
  }
}


const mapStateToProps = (state) => {
	return state
}

export default connect(mapStateToProps, {
  createComment
})(Comments);
