import React from 'react'
import {
  withRouter
} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Project from '../../../../Components/Project';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AddIcon from '@material-ui/icons/Add';

function Projects(props) {
  const { data } = props;
  const { user } = data;
  const { projects } = user;

  const handleAddProject = () => {
    props.history.push('/create/project');
  }

  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom component="h2">
          Projects
      </Typography>

      <div style={{
            background: '#ece9e9',
            padding: '16px',
            borderRadius: '8px',
            marginBottom: '16px'
        }}>
          <Typography style={{fontSize: '14px'}} gutterBottom>
          Welcome to your projects dashboard. Here you will see all of the projects you create on GetIncredibles.
          </Typography>
          <Typography style={{fontSize: '14px'}} gutterBottom>
          Start off by clicking on the big add icon below to create your first project.
          </Typography>
        </div>

      <Grid container spacing={4}>
        <Grid item sm={2} md={2} lg={2}>
          <Card onClick={handleAddProject} style={styles.addProjectButtonCard}>
            <CardContent>
              <AddIcon style={{fontSize: '72px'}}/>
            </CardContent>
          </Card>
        </Grid>
        { projects && projects.map(project => <Project key={project.id} data={project}/>) }
      </Grid>

    </React.Fragment>
  )
}

const styles = {
  addProjectButtonCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height:'141px',
    cursor: 'pointer'
  }
}

export default withRouter(Projects);
