import React from 'react';
import RadioOptions from '../../../Components/SelectionControl/RadioOptions';
import Grid from '@material-ui/core/Grid';
import { reduxForm } from 'redux-form';
function AddressForm(props) {

  const { userType = 'Client', change } = props
  const options = [
    { value: 'Client', label: 'Hire Incredibles' },
    { value: 'Incredible', label: 'Become an incredible' }
  ]

  return (
    <React.Fragment>
      {/* <Typography variant="h6" gutterBottom>
        User Type
      </Typography> */}
      <Grid container spacing={2}>
        <RadioOptions change={change} id={'userType'} label={'What would you like to do ?'} options={options} defaultValue={userType}/>
      </Grid>
    </React.Fragment>
  );
}

AddressForm = reduxForm({
  form: 'SignUp',
  destroyOnUnmount: false,
})((AddressForm))

export default AddressForm;