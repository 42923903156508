import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
    width: 600,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class MyDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    const { refetch } = this.props;
    this.setState({ open: false });
    if(refetch)
      refetch()
  };

  render() {
    const { children, title, content } = this.props

    return (
      <React.Fragment>
        {
          typeof(children) === 'function' ?
          <span>
              { children({onOpenDialog:this.handleClickOpen}) }
          </span>
          :
           <span onClick={this.handleClickOpen}>
              { children }
            </span>
        }
        <Dialog
          onClose={this.handleClose}
          open={this.state.open}
        >
          <DialogTitle onClose={this.handleClose}>
            { title }
          </DialogTitle>
          { content(this.handleClose) }
        </Dialog>
      </React.Fragment>
    );
  }
}

export default MyDialog;
