import React from 'react';
import Moment from 'react-moment';
import Detail from './Components/Detail';
import Tags from './Components/Tags';
import { Grid, Typography } from '@material-ui/core';
import EditDialog from './Components/EditDialog';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from './../../../Dialog';

import { connect } from "react-redux";
import { deleteProfileProject } from './../../../../redux/actions'

const Project = ({ data, editMode = false, refetch, deleteProfileProject  }) => {
  const {
    id,
    name,
    url,
    domain = [],
    description,
    platforms = [],
    programmingLanguages = [],
    startDate,
    tools = [],
    endDate,
    teamSize,
    role,
  } = data

  const styles = {
    url: {
      color: '#3d8af7',
      marginBottom: 0,
    },
    description: {
      //color: '#7A7A7A',
      whiteSpace: 'pre-wrap',
    },
    actionIcons: {
      opacity: 0.7,
      cursor: 'pointer',
      marginLeft: '8px'
    }
  }

  const handleDelete = () => {
    deleteProfileProject(id, refetch);
  }
  return (
    <Grid container item xs = {12} spacing={1}>
      <Grid container justify="space-between" item xs={12}>
				<Typography variant={'h6'}>{name}</Typography>
        <div>
          {
            <Dialog title={'Edit Project'} content={(props) => <EditDialog editMode={true} data={data} handleClose={props} refetch={refetch}/>}>
              <EditIcon style={styles.actionIcons}/>
            </Dialog>
          }

          <DeleteIcon onClick={handleDelete} style={styles.actionIcons}/>
        </div>
			</Grid>
      <Grid item xs={12}>
        <Typography paragraph style={styles.url}>{url}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Tags data={domain}/>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph style={styles.description}>{description}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Detail heading={'My Role on ' + name + ':'} text={role}/>
      </Grid>
			<Grid item xs={6}>
					<Detail heading={'Platforms'} text={platforms.join(', ')}/>
			</Grid>
      {
        (role === 'engineer' || role === 'TPM') &&
        <Grid item xs={6}>
            <Detail heading={'Programing Language:'} text={programmingLanguages.join(', ')}/>
        </Grid>
      }
      <Grid item xs={12} style={styles.row}>
        <Detail heading={'Tools/Softwares used:'} text={tools.join(', ')}/>
      </Grid>
      <Grid item xs={12} style={styles.row}>
        <Typography style={{color: '#000000'}}>
          {
            (startDate && endDate) &&
            <>
              <Moment format="MMM D, YYYY" withTitle>{startDate}</Moment> to <Moment format="MMM D, YYYY" withTitle>{endDate}</Moment><span style={{margin:'0px 8px', fontSize: '18px'}}>|</span><Moment diff={startDate} unit="months">{endDate}</Moment> months
            </>
          }
          {
            (startDate && endDate && teamSize) && <span style={{margin:'0px 8px', fontSize: '18px'}}>|</span>
          }
          {
            teamSize &&
            <>
              Team size: {teamSize}
            </>
          }
        </Typography>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = ({ form }) => {
  return {};
};

export default connect(mapStateToProps,{deleteProfileProject})(Project);
