import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import Dialog from '../../../../Components/Dialog';
import EditDialog from './Components/EditDialog';

const SkillsAndTools = ({user, editMode = false, refetch }) => {
	const {
		apis,
		languages,
		libraries,
		platform,
		tools,
		role,
	} = user.profile;

	const fields = [
		[
			{ label: 'Programming Languages', name: 'languages', key: languages.join(', ') },
			{ label: 'APIS', name: 'apis', key: apis.join(', ') },
		],
		[
			{ label: 'Platforms',name: 'platform', key: platform.join(', ') },
			{ label: 'Libraries',name: 'libraries', key: libraries.join(', ') },
		],
		[
			{ label: 'Tools',name: 'tools', key: tools.join(', ') },
		]
	]

	const render = fields.map((arr,index) => {
		const items = arr.map((item,index) => {

			if(role === 'engineer' || role === 'TPM') {
				return (
					<Grid key={index} item xs={6}>
						<Grid item xs={12}>
							<Typography variant={'subtitle2'} gutterBottom >
								{ item.label }
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography gutterBottom paragraph>
								{ item.key ? item.key : 'N/A' }
							</Typography>
						</Grid>
					</Grid>
				)
			}else {

				if (item.name === 'languages' || item.name === 'apis' || item.name === 'libraries') {
					return <></>
				}

				if (item.name === 'tools' || item.name === 'platform') {
					return (
						<Grid key={index} item xs={6}>
							<Grid item xs={12}>
								<Typography variant={'subtitle2'} gutterBottom >
									{ item.label }
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography gutterBottom paragraph>
									{ item.key ? item.key : 'N/A' }
								</Typography>
							</Grid>
						</Grid>
					)
				}
			}
		})

		return (
			<Grid key={index} container xs = {12} item spacing={2}>
				{ items }
			</Grid>
		)
	})

	return (
		<Grid style={{marginTop: '16px'}} container xs={12} item spacing={2}>
			<Grid container item justify="space-between">
				<Typography variant={'h5'} gutterBottom>
					Skills and Tools
				</Typography>
				{
					editMode &&
					<Dialog title={'Profile Details'} content={(props) => <EditDialog handleClose={props} refetch={refetch} user={user}/>}>
						<Button size="small" color="primary">
							Edit
						</Button>
					</Dialog>
				}
			</Grid>
			{ render }
		</Grid>
	)
}

export default SkillsAndTools;
