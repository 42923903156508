import React, { Component, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import StarRatingComponent from 'react-star-rating-component';
import Grid from '@material-ui/core/Grid';


const getFormatDate = (date) => {
  let newDate = new Date(date);

  return `${newDate.getDate()}/${newDate.getMonth()}/${newDate.getFullYear()}`;
}

const Peer = ({peer, onViewPeerDetails}) => {
  const {receiverEmail, createdAt} = peer;
  const [showDetails, toggleShowDetails] = useState(false);

  const peerStyles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '16px'
    },
    contentLeft: {
      display: 'flex',
      alignItems: 'center',
      flex: '1'
    },
    contentRight: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: '24px',
      // flex: '1'
    },
    email: {
      // marginLeft: '24px'
    },
    avatar: {
      height: '64px',
      width: '64px',
      fontSize: '24px',
      marginRight: '24px'
    },
    sentDate: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '32px'
    },
    actionButtons: {
      display: 'flex',
      flexDirection: 'column'

    }
  }

  return (
    <div>
      <div style={peerStyles.container}>
        <div style={peerStyles.contentLeft}>
          <Avatar style={peerStyles.avatar}>{receiverEmail ? receiverEmail.charAt(0).toUpperCase(): 'N/A'}</Avatar>
          <div>
            {
              peer.name &&
              <Typography variant="subtitle1" style={peerStyles.email}>{peer.name}</Typography>
            }
            <Typography variant="subtitle1" style={peerStyles.email}>{peer.receiverEmail}</Typography>
          </div>
        </div>

        <div style={peerStyles.contentRight}>
          <div style={peerStyles.sentDate}>
            <Typography variant="body2">Sent on:</Typography>
            <Typography variant="body2">{getFormatDate(createdAt)}</Typography>
          </div>

          <div style={peerStyles.actionButtons}>
            {
              !peer.name &&
              <Button style={{width: '120px', fontSize: '12px', marginBottom:'8px'}} variant="contained" color="primary">Remind</Button>
            }
            <Button style={{width: '120px', fontSize: '12px'}} onClick={()=>{toggleShowDetails(!showDetails)}} variant="outlined" color="primary">View Ratings</Button>
          </div>
        </div>
      </div>
      {
        showDetails &&
        <PeerDetails peer={peer}/>
      }
    </div>
  )
}

const PeerDetails = ({peer}) => {
  const {projectName, startDate, endDate, score} = peer;

  const peerDetailsStyles = {
    container: {
      borderTop: '1px solid #ccc',
      borderBottom: '1px solid #ccc',
      padding: '24px'
    },
    row: {
      margin: '8px 0px'
    },
    title: {
      marginRight: '16px'
    },
    dates: {
      display: 'flex',
      margin: '8px 0px'
    }
  }

  return(
    <div style={peerDetailsStyles.container}>
      <Grid style={peerDetailsStyles.row} container direction="row" alignItems="center">
        <Typography style={peerDetailsStyles.title} variant="body2"><strong>Workplace:</strong></Typography>
        <Typography variant="body2">{projectName}</Typography>
      </Grid>

      <div style={peerDetailsStyles.dates}>
        <Grid container direction="row" alignItems="center">
          <Typography style={peerDetailsStyles.title} variant="body2"><strong>From:</strong></Typography>
          <Typography variant="body2">{getFormatDate(startDate)}</Typography>
        </Grid>

        <Grid container direction="row" alignItems="center">
          <Typography style={peerDetailsStyles.title} variant="body2"><strong>To:</strong></Typography>
          <Typography variant="body2">{getFormatDate(endDate)}</Typography>
        </Grid>
      </div>


      <Grid style={peerDetailsStyles.row} container direction="row" alignItems="center">
        <Typography style={peerDetailsStyles.title} variant="body2"><strong>Relationship:</strong></Typography>
        <StarRatingComponent
          name='rating' /* name of the radio input, it is required */
          value={score}
          starCount={10}
          starColor='#FFD700'
          emptyStarColor='#ABABAB'
        />
      </Grid>
    </div>
  )
}

class PeerInviteSent extends Component {
  state = {
    showPeerDetails: false
  }

  togglePeerDetails = () => {
    const {showPeerDetails} = this.state;
    this.setState({showPeerDetails:!showPeerDetails});
  }

  render() {
    const {invites, showPeerDetails} = this.props;
    return (
      <div>
        {
          invites.map((invite,index)=>{
            return(
              <Peer peer={invite} onViewPeerDetails={this.togglePeerDetails}/>
            )
          })
        }
      </div>
     )
  }
}

const peerInviteSentStyles = {

}

export default PeerInviteSent;
