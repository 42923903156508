
import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter} from 'react-router-dom';

const styles = theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
});

const Project = (props) => {
  const { data, classes } = props;

  const { id, description, title } = data;

  const handleViewProject = (id) => {
    const {
      history
    } = props;

    localStorage.setItem('projectId', id);
    history.push(`/project/${id}`);
  }

  return (
    <Grid item key={id} sm={6} md={4} lg={3}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5" component="h2">
            { title }
          </Typography>
          <Typography>
            { description }
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={()=>{handleViewProject(id)}}>
            View
            {/* <Link to={`/project/${id}`}>
              View
            </Link> */}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default withRouter(withStyles(styles)(Project));
