import React from 'react';
import CommentIcon from '@material-ui/icons/Comment';
import ListIcon from '@material-ui/icons/List';
import AgendaIcon from '@material-ui/icons/ViewAgenda';
import MyAvatar from '../MyAvatar';
import { Typography, Grid, Card, CardContent, LinearProgress } from '@material-ui/core';

const Footer = (props) => {
	const { data, assignee } = props;
  const { comments = [], checklists = []} = data;

	const icons = [
		{ icon: <AgendaIcon/>, value: 0 },
		{ icon: <CommentIcon/>, value: comments.length },
		{ icon: <ListIcon/>, value: checklists.length },
 	]
	const renderIcons = icons.map(({icon, value},index) => {
		return (
			<React.Fragment key={index}>
				<Grid item>
					{ icon }
				</Grid>
				<Grid item>
					<Typography>{value}</Typography>
				</Grid>
			</React.Fragment>
		)
	})

	return (
		<Grid container direction="row" item spacing={2} alignItems="center" justify="space-between">
			<Grid item>
				<Grid container alignItems="center" spacing={2}>
				{
					renderIcons
				}
				</Grid>
			</Grid>
			{
				assignee && 
				<Grid item>
					<MyAvatar user={assignee} size="small" hasMenu={false}/>
				</Grid>
			}
		</Grid>
 	)
}

const Deliverable = (props) => {
	const { data } = props;
	const { description, assignee, checklists } = data;

	let progress = 0;

	checklists.map(({status}) => {
		if(status)
			progress++;
	})
	progress = (progress/checklists.length) * 100

	return (
		<Card>
			<CardContent>
				<Grid container direction="column" spacing={2}>
					<Grid item >
						<Typography paragraph>
							{ description }
						</Typography>
					</Grid>
					<Footer assignee={assignee} data={data}/>
					<Grid item>
						<LinearProgress variant="determinate" value={progress} />
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}

export default Deliverable;