import React, { Component } from "react";
import { connect } from 'react-redux'
import {
  Typography,
  Grid,
  Button,
  Tabs,
  Tab,
  SwipeableViews,
  AppBar
} from "@material-ui/core";

import { createDeliverable } from '../../../redux/actions';

import DeliverableCard from "./DeliverableCard";
import DeliverableFormCard from "./DeliverableFormCard";

const deliverablesData = [
  {title: 'Task 1', description: 'Ullamco laborum laborum veniam non ut magna ea et. Voluptate cupidatat nisi aliquip dolore amet nisi ex id laborum ipsum excepteur ipsum. Tempor esse quis aliqua do amet dolor consectetur incididunt labore enim in consequat sunt sunt. Sit quis minim officia esse sunt id aliquip anim ipsum. Magna voluptate elit nostrud incididunt ad anim voluptate in.'},

  {title: 'Task 2', description: 'Ullamco laborum laborum veniam non ut magna ea et. Voluptate cupidatat nisi aliquip dolore amet nisi ex id laborum ipsum excepteur ipsum. Tempor esse quis aliqua do amet dolor consectetur incididunt labore enim in consequat sunt sunt. Sit quis minim officia esse sunt id aliquip anim ipsum. Magna voluptate elit nostrud incididunt ad anim voluptate in.'},

  {title: 'Task 3', description: 'Ullamco laborum laborum veniam non ut magna ea et. Voluptate cupidatat nisi aliquip dolore amet nisi ex id laborum ipsum excepteur ipsum. Tempor esse quis aliqua do amet dolor consectetur incididunt labore enim in consequat sunt sunt. Sit quis minim officia esse sunt id aliquip anim ipsum. Magna voluptate elit nostrud incididunt ad anim voluptate in.'},
];

class Deliverables extends Component {
  state = {
    tab: 0,
    deliverables: this.props.data.project.deliverables ? this.props.data.project.deliverables : [],
    deliverablesForm: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      deliverables: nextProps.data.project.deliverables ? nextProps.data.project.deliverables : [],
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tab: newValue
    });
  };

  handleAddDeliverableForm = () => {
    this.setState({
      deliverableForm: true
    })
  }

  handleCreateDeliverable = (deliverable) => {
    const { data, createDeliverable } = this.props;
    const { project, refetch } = data;

    createDeliverable(project.id, deliverable)
    .then(response=>{
      refetch();
      this.setState({
        deliverableForm: false,
      })
    });
  }

  handleCancelDeliverableForm = () => {
    this.setState({
      deliverableForm: false
    })
  }

  filterAvailableUsers = () => {
    const { project } = this.props.data;

    // let users = project.technicalRequirements.map(tR=>tR.talentMatches).filter(talentMatch =>{
    //   debugger;
    //   return talentMatch.status == 'estimation'
    // });

    // console.log(users);

    // return users;
  }

  render() {
    const { tab, deliverables, deliverableForm} = this.state;
    const { data } = this.props;
    const { refetch, project } = data;

    const assignableIncredibles = [...project.getEstimateIncredibles, ...project.hiredIncredibles];

    return (
      <div>
        <Grid container justify="space-between">
          <Typography variant="h4">Deliverables</Typography>

          <Button variant="outlined" color="primary" onClick={this.handleAddDeliverableForm}>
            Add Deliverable
          </Button>
        </Grid>

        <AppBar
          position="static"
          style={{
            backgroundColor: "#FFFFFF",
            marginTop: "24px",
            marginBottom: "24px",
            boxShadow: "0px 2px 2px 0px rgba(224,224,224,0.6)"
          }}
        >
          <Tabs
            value={tab}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Active" />
            <Tab label="Awaiting Approval" />
            <Tab label="Approved" />
          </Tabs>
        </AppBar>

        {
          deliverableForm &&
          <DeliverableFormCard onCreateDeliverable={this.handleCreateDeliverable} onCancel={this.handleCancelDeliverableForm}/>
        }

        {
          deliverables && deliverables.map((d,k)=>(
            <DeliverableCard refetch={refetch} users={this.filterAvailableUsers()} assignableIncredibles={assignableIncredibles} deliverable={d} key={k}/>
          ))
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps, {
  createDeliverable
})(Deliverables);
