import React, { Component } from 'react';
import { TextField, Button, Grid, Typography } from '@material-ui/core';
import CommentBox from './CommentBox';
import { createComment } from '../../../../../../../redux/actions';
import { connect } from 'react-redux';

class AddComment extends Component {
	state = {
		isEdit: false,
		value: '',
		comments: this.props.data.comments ? this.props.data.comments : []
	}

	handleChange = (e) => {
		this.setState({ value: e.target.value })
	}

	addComment = () => {
		let state = {...this.state};
		let { comments ,value } = state;
		let { id } = this.props.data;
		let user_id = this.props.auth.user.id
		let description = value
		this.props.createComment({description, user_id},id).then(resp => {
			const { id, attributes } = resp.data.data;
			const { username } = attributes;
			comments.push({ description, id, user: { username, id: user_id} });
			state.value = '';
			this.setState(state);
		});

	}

	render() {
		const { comments, value } = this.state;
		return (
			<React.Fragment>
			<Grid item>
				<Grid container direction="column" spacing={2}>
					<Grid item>
						<Typography variant="subtitle2" gutterBottom>
							Comments
						</Typography>
					</Grid>
					<Grid item>
						<TextField
							label="Enter comment"
							multiline
							rowsMax="5"
							value={this.state.value}
							onChange={this.handleChange}
							variant="outlined"
							style={{width: '100%'}}
						/>
					</Grid>
					<Grid item>
						<Button disabled={value.length === 0 ? true : false} onClick={this.addComment} variant="contained" color="primary">Post</Button>
					</Grid>
				</Grid>
			</Grid>
			{
				comments.map(item => {
					return <CommentBox key={item.id} data={item}/>
				})
			}
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return state
}

export default connect(mapStateToProps,{createComment})(AddComment);
