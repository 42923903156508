import React, { Component, Fragment } from 'react';

import {
  Grid,
  Button,
  Typography,
  Divider,
  TextField,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import 'date-fns';

import ConfirmationIcon from '../../../../../assets/icons/confirmation.svg';

const ContractInformationRecord = ({label, value}) => {
  return (
    <Grid container item direction="column" justify="center" alignItems="center" xs={4} style={{marginTop: '16px'}}>
      <Typography variant="subtitle1" style={{ color: '#646564', fontWeight: '400' }}>{label}</Typography>
      <Typography variant="subtitle1">{value}</Typography>
    </Grid>
  )
}

const ContractInformation = (props) => {
  return (
    <div style={{padding: '8px 16px'}}>
      <Typography style={{marginBottom: '16px'}} variant="h5" align="center">Amy Has Sent You A Contract</Typography>
      <Grid container justify="space-between" alignItems="center">
        <ContractInformationRecord label="Rate ($) / Hour" value="$70"/>
        <ContractInformationRecord label="Hours / week" value="35 Hours"/>
        <ContractInformationRecord label="Duration" value="3 Months"/>
      </Grid>

      <Grid container>
        <ContractInformationRecord label="Start Date" value="Sep 12, 2019"/>
        <ContractInformationRecord label="End Date" value="Nov 12, 2019"/>
      </Grid>
    </div>
  )
}

class IncredibleAcceptContract extends Component {
  state = {
    openIncredibleHireContractModal: false,
    confirmation: false
  }

  handleOpenModal  = () => {
    this.setState({
      openIncredibleHireContractModal: true
    })
  }

  handleCloseModal = () => {
    this.setState({
      openIncredibleHireContractModal: false
    })
  }

  handleCreateContract = () => {
    this.setState({
      confirmation: true
    })
  }

  render() {
    const { openIncredibleHireContractModal, confirmation } = this.state;

    return (
      <Fragment>
        <Grid item justify="center" container>
          <Button
            style={styles.actionButton}
            variant="outlined"
            onClick={this.handleOpenModal}
            color="primary"
          >
            Accept
          </Button>
        </Grid>

        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={openIncredibleHireContractModal}
          onClose={this.handleCloseModal}
        >
          <DialogTitle style={{
            textAlign: 'center',
            'fontSize': '24px',
            'fontWeight': '400'}}>
              New Contract
          </DialogTitle>

          <DialogContent>
            <ContractInformation/>
          </DialogContent>

          <DialogActions style={{ marginBottom: '16px' }}>
            {
              !confirmation ?
              <Fragment>
                <Button color="secondary" onClick={this.handleCloseModal} variant="outlined">Cancel</Button>
                <Button color="primary" onClick={this.handleCreateContract} variant="outlined">Accept</Button>
              </Fragment>
              :
              <Button color="primary" onClick={this.handleCloseModal} variant="outlined">Done</Button>
            }
          </DialogActions>
        </Dialog>
      </Fragment>
     );
  }
}

const styles = {
  actionButton: {
    width: "132px",
    fontSize: "12px",
    padding: "6px"
  },
}

export default IncredibleAcceptContract;
