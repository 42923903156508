import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Button from '@material-ui/core/Button';

import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { createTechnicalRequirement } from '../../../../../redux/actions';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import 'date-fns';
import timezoneOptions from '../../../../../Components/Timezone';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  chip: {
    marginRight: '5px'
  }
});
 
const talentTypes = [
	'TPM',
	'Artist',
	'Engineer',
]

const domainOptions = [
  'Games',
  'AR/VR',
  'Fintech',
  'HealthTech',
  'IOT',
];

const technicalExpertiseOptions = [
	'Project Scoping',
	'Technical Risk Assessment',
	'Technical Problem Solving',
	'Team Management',
	'Managing Design Process',
	'Project Management',
	'Budget Management',
	'Time Management'
]

const toolsOptions = [
	'BitBucket',
	'Jira',
	'Github',
]

const languagesOptions = [
	'Node.js',
	'React.js',
	'Redux',
	'Redux Saga',
	'Ruby on Rails',
	'Python',
]

const durationOptions= [
	'0-3 weeks',
	'3-6 weeks',
	'1-3 months',
	'3-6 months',
]

const platformOptions = [
  'Web',
  'IOS',
  'Android'
]

class AddTechnicalRequirement extends Component {
  state = {
    domain: [],
		duration: '',
		languages: [],
		start_date: new Date(),
		talent_type: "TPM",
		technical_expertise: [],
		timezone: "",
		tools: [],
  }

  handleSave = () => {
		const {data} = this.props;
		const { project } = data;
		const { id } = project;
    this.props.createTechnicalRequirement(this.state, id).then(resp => {
			this.props.data.refetch().then(resp => this.props.history.push(`/project/${id}`));
    })
  }

  handleAddReference = () => {
    const { references } = this.state;

    references.push({
      name:'',
      link:''
    });

    this.setState({references});
  }

  handleRemoveReference = (index) => {
    const { references } = this.state;

    references.splice(index, 1);

    this.setState({references});
  }

  handleReferenceValueChange = (event, index) => {
    const { references } = this.state;

    references[index][event.target.name] = event.target.value;

    this.setState({references});
  }

  handleTextChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSelectChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const {
			domain,
			duration,
			languages,
			start_date,
			technical_expertise,
			timezone,
			tools,
			talent_type
		} = this.state;

    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
					Add new requirement
        </Typography>
          <Paper className={classes.paper}>
					<FormControl margin="normal" required fullWidth>
								<InputLabel htmlFor="select-multiple-chip">What kind of incredible are you looking for?</InputLabel>
								<Select
									value={talent_type}
									onChange={this.handleSelectChange}
									name="talent_type"
								>
									{talentTypes.map(name => (
										<MenuItem key={name} value={name}>
											{name}
										</MenuItem>
									))}
								</Select>
						</FormControl>

						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="select-multiple-chip">Required Industry/vertical/domain knowledge or experience (e.g. Games, AR/VR etc.)</InputLabel>
							<Select
								multiple
								value={domain}
								name="domain"
								onChange={this.handleSelectChange}
								input={<Input id="select-multiple-chip"/>}
								renderValue={selected => (
									<div className={classes.chips}>
										{selected.map(value => (
											<Chip key={value} label={value} className={classes.chip} />
										))}
									</div>
								)}
							>
								{domainOptions.map(name => (
									<MenuItem key={name} value={name}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="select-multiple-chip">Required functional expertise or specialties. e.g Technical Problem Solving</InputLabel>
							<Select
								multiple
								value={technical_expertise}
								name="technical_expertise"
								onChange={this.handleSelectChange}
								input={<Input id="select-multiple-chip"/>}
								renderValue={selected => (
									<div className={classes.chips}>
										{selected.map(value => (
											<Chip key={value} label={value} className={classes.chip} />
										))}
									</div>
								)}
							>
								{technicalExpertiseOptions.map(name => (
									<MenuItem key={name} value={name}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="select-multiple-chip">Tools and Softwares he should preferably be proficient in e.g. Jira</InputLabel>
							<Select
								multiple
								value={tools}
								name="tools"
								onChange={this.handleSelectChange}
								input={<Input id="select-multiple-chip"/>}
								renderValue={selected => (
									<div className={classes.chips}>
										{selected.map(value => (
											<Chip key={value} label={value} className={classes.chip} />
										))}
									</div>
								)}
							>
								{toolsOptions.map(name => (
									<MenuItem key={name} value={name}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="select-multiple-chip">Preferred programming language(s) or expertise. e.g. HTML, Python</InputLabel>
							<Select
								multiple
								value={languages}
								name="languages"
								onChange={this.handleSelectChange}
								input={<Input id="select-multiple-chip"/>}
								renderValue={selected => (
									<div className={classes.chips}>
										{selected.map(value => (
											<Chip key={value} label={value} className={classes.chip} />
										))}
									</div>
								)}
							>
								{languagesOptions.map(name => (
									<MenuItem key={name} value={name}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									label="Likely start date"
									value={start_date}
									fullWidth
									name='start_date'
									onChange={(start_date) => this.setState({start_date})} 
								/>
							</MuiPickersUtilsProvider>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
								<InputLabel htmlFor="select-multiple-chip">Likely duration of engagement</InputLabel>
								<Select
									value={duration}
									onChange={this.handleSelectChange}
									name="duration"
								>
									{durationOptions.map(name => (
										<MenuItem key={name} value={name}>
											{name}
										</MenuItem>
									))}
								</Select>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
								<InputLabel htmlFor="select-multiple-chip">Preferred time zone</InputLabel>
								<Select
									value={timezone}
									onChange={this.handleSelectChange}
									name="timezone"
								>
									{timezoneOptions.map(name => (
										<MenuItem key={name} value={name}>
											{name}
										</MenuItem>
									))}
								</Select>
						</FormControl>
          </Paper>
          <Button onClick={this.handleSave} style={{width: '120px', height:'42px'}} variant="contained" color = "primary" >Save</Button>
			</React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps,{ createTechnicalRequirement })(withStyles(styles)(AddTechnicalRequirement));
