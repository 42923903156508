import React, { Component, Fragment } from "react";
import {
  Paper,
  Typography,
  Grid,
  Chip,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent
} from "@material-ui/core";

import TimerIcon from "@material-ui/icons/Timer";
import CommentIcon from "@material-ui/icons/Comment";
import AttachmentIcon from "@material-ui/icons/Attachment";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";

import DeliverableDetails from '../DeliverableDetails';

const IconLabel = ({ icon, label }) => {
  const iconLabelStyles = {
    text: {
      fontSize: "16px",
      fontWeight: "500",
      color: "#0000008a",
      marginLeft: "6px"
    }
  };
  return (
    <Grid container item alignItems="center">
      {icon}
      <div style={iconLabelStyles.text}>{label}</div>
    </Grid>
  );
};

const DeliverableCardHeader = ({ title, date }) => {
  return (
    <Grid container justify="space-between" alignItems="center">
      <Typography variant="h5">{title}</Typography>

      <Chip
        icon={<TimerIcon />}
        label={date}
        color="secondary"
        variant="outlined"
        style={{ fontSize: "16px", width: "150px", height: "36px" }}
      />
    </Grid>
  );
};

const DeliverableCardFooter = ({deliverable}) => {
  const { incredibles, checklists, comments, documents } = deliverable;
  const deliverableCardFooterStyles = {
    container: {
      marginTop: "24px"
    },
    avatar: {
      marginRight: "8px"
    },
    icon: {
      height: "32px",
      width: "32px",
      color: "#0000008a"
    }
  };

  return (
    <Grid
      container
      style={deliverableCardFooterStyles.container}
      justify="space-between"
      xs={12}
    >
      <Grid container justify={"flex-start"} xs={6}>
        {
          incredibles.length > 0 &&
          incredibles.map((i,k)=>(
            <Avatar style={deliverableCardFooterStyles.avatar} key={k}>{i.username.charAt(0).toUpperCase()}</Avatar>
          ))
        }
      </Grid>

      <Grid container justify={"flex-end"} xs={6}>
        <Grid item style={{ marginRight: "16px" }}>
          <IconLabel
            icon={<AttachmentIcon style={deliverableCardFooterStyles.icon} />}
            label={documents.length}
          />
        </Grid>

        <Grid item style={{ marginRight: "16px" }}>
          <IconLabel
            icon={<CommentIcon style={deliverableCardFooterStyles.icon} />}
            label={comments.length}
          />
        </Grid>

        <Grid item>
          <IconLabel
            icon={
              <PlaylistAddCheckIcon style={deliverableCardFooterStyles.icon} />
            }
            label={checklists.length}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

class DeliverableCard extends Component {
  state = {
    openDeliverableDetailsModal: false
  }

  handleOpenModal = () => {
    this.setState({
      openDeliverableDetailsModal: true
    })
  }

  handleCloseModal = () => {
    this.setState({
      openDeliverableDetailsModal: false
    },()=>{
      this.props.refetch();
    })
  }

  render() {
    const styles = {
      container : {
        padding: "32px",
        margin: "16px 0px",
        cursor: 'pointer'
      }
    }

    const { openDeliverableDetailsModal } = this.state;
    const { assignableIncredibles, deliverable, refetch } = this.props;
    const { title, description } = deliverable;

    return (
      <Fragment>
        <Paper style={styles.container} onClick={this.handleOpenModal}>
          <DeliverableCardHeader title={title} date="Sep 19, 2018" />

          <Typography style={{ margin: "8px 0px 8px 0px" }} variant="subtitle1">
            Sep 12, 2018
          </Typography>

          <Typography variant="body1">{description}</Typography>

          <DeliverableCardFooter deliverable={deliverable} />
        </Paper>

        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={openDeliverableDetailsModal}
          onClose={this.handleCloseModal}
        >
          <DialogTitle style={{
            textAlign: 'center',
            'fontSize': '24px',
            'fontWeight': '400'

          }}>Deliverable</DialogTitle>

          <DialogContent>
            <DeliverableDetails refetch={refetch} assignableIncredibles={assignableIncredibles} deliverable={deliverable}/>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default DeliverableCard;
