import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CheckIcon from '@material-ui/icons/Check';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

const styles = theme => ({
  smallAvatar: {
    width: 36,
    height: 36,
  },
  largeAvatar: {
    width: 70,
    height: 70,
  },
  extraLargeAvatar: {
    width: 120,
    height: 120,
  },
  menu: {
    marginTop: 36,
  },
  checkIcon: {
    position: 'absolute',
    opacity: 0.8,
    zIndex: 1,
    padding: 10,
  }
})

class MyAvatar extends Component {

  state = {
    open: false,
    checked: this.props.checked ? this.props.checked: false,
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCheck = event => {
    const { checked } = this.state;
    this.props.onClick(!checked);
    this.setState({ checked: !checked })
  }

  handleClose = (value) => {
    this.setState({ anchorEl: null });
    if(value === 'logout') {
      window.location.replace('/');
      localStorage.clear();
      this.props.signOut();
    }
  };

  render() {
    const { props, state } = this;
    const { anchorEl } = state;
    const { user, classes, size = "small", hasMenu = true, checkIcon = false } = props;
    const { profilePic, username } = user ? user : {};

    let initials = username ? username.match(/\b\w/g) || [] : [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

    const simpleAvatar = () => {
      return (
        <React.Fragment>
        {
          profilePic ?
          <Avatar alt={username} src={profilePic} className={classes[`${size}Avatar`]}/> :
          <Avatar alt={username} className={classes[`${size}Avatar`]}>{initials}</Avatar>
        }
        </React.Fragment>
      )
    }

    const checkedAvatar = () => {
      const { checked } = this.state;
      return (
        <IconButton color="inherit" style={{padding: 0}} onClick={this.handleCheck}>
        <Grid container style={{position: 'relative'}}>
        {
            checked ?
            <CheckIcon className={cn(classes[`${size}Avatar`],classes.checkIcon)} /> : null
        }
        {
            profilePic ?
            <Avatar alt={username} src={profilePic} className={classes[`${size}Avatar`]}/> :
            <Avatar alt={username} className={classes[`${size}Avatar`]}>{initials}</Avatar>
        }
        </Grid>
        </IconButton>

      )
    }

    return (
      <React.Fragment>
        {
          hasMenu ?
          <React.Fragment>
            <IconButton color="inherit" onClick={this.handleClick}
              aria-owns={anchorEl ? 'avatar-menu' : undefined}
              aria-haspopup="true"
            >
              { simpleAvatar() }
            </IconButton>
            <Menu id='avatar-menu' className={classes.menu} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
              <MenuItem onClick={()=>this.handleClose('logout')}>Logout</MenuItem>
            </Menu>
          </React.Fragment> : checkIcon ? checkedAvatar() :simpleAvatar()
        }
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(MyAvatar);
