import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '../../../../../../Components/Dialog';
import Typography from '@material-ui/core/Typography';
import { Grid, DialogContent } from '@material-ui/core';
import AddComment from './Components/AddComment';
import Description from './Components/Description';
import Checklist from './Components/CheckList';
import AssignedUsers from './Components/AssignedUsers';

const Content = (props) => {

  const { data, canEdit = false } = props;
  const { refetch, id, possibleAssignees, assignee, checklists, comments, description } = data;

  return (
    <DialogContent>
      <Grid container direction="column" spacing={2}>
        <AssignedUsers canEdit={canEdit} data={{possibleAssignees,id,assignee}}/>
        <Description canEdit={canEdit} data={{refetch,id,description}}/>
        <Checklist canEdit={canEdit} data={{refetch,id,checklists}}/>
        <AddComment canEdit={canEdit} data={{comments,id}}/>
      </Grid>
    </DialogContent>
  )
}

class Deliverable extends React.Component {

  render() {
    const { children,data } = this.props;
    const { refetch } = data;
    return (
      <Dialog refetch={refetch} content={(props) => <Content handleClose={props} {...this.props}/>} title={'Deliverable Overview'}>{children}</Dialog>
    );
  }
}
export default Deliverable;
