import React, { Component } from 'react';
import { TextField, MenuItem } from '@material-ui/core';

class Select extends Component {
	state = {
			value: this.props.value
	}

	handleChange = (event) => {
		const { id, change } = this.props;
		const value = event.target.value;

		change(id,value)
		this.setState({ value })
	}

	render() {
		const { items, id, label, helperText } = this.props
		return (
			<TextField
					id={id}
					select
					label={label}
					value={this.state.value}
					onChange={this.handleChange} 
					helperText={helperText}
					fullWidth
				>
					{
						items.map((option,id) => {
							return (
								<MenuItem key={id} value={option}>
									{option}
								</MenuItem>
							)
						})
					}
			</TextField>
		)
	}
}

export default Select;