import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import Button from '@material-ui/core/Button';

import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { createProject } from '../../../../redux/actions';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from 'material-ui-pickers';
import 'date-fns';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  chip: {
    marginRight: '5px'
  }
});

const domainOptions = [
  'Games',
  'AR/VR',
  'Fintech',
  'HealthTech',
  'IOT',
];

const projectStageOptions = [
  `It's in the idea phase and hasn't been scoped out yet`,
  `It's clearly defined but we haven't started production yet`,
  `We're already working on it but need to accelerate the pace with the help of additional team members`
]

const platformOptions = [
  'Web',
  'IOS',
  'Android'
]


const ReferencesList = (props) => {
  const {onAdd, onRemove, onChange, references} = props;

  const referenceStyles = {
    referenceInputContainer: {
      display: 'flex',
      alignItems: 'flex-end'
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '24px'
    },
    input: {
      marginBottom: '0px',
      marginRight: '32px'
    },
    deleteButton: {
      cursor: 'pointer'
    },
    addButton: {
      width: '120px',
      height: '42px'
    },
    description : {
      width: '80%'
    }
  }

  return(
    <div>
      <div style={referenceStyles.header}>
        <Typography style={referenceStyles.description} variant="subtitle1" gutterBottom>
          Please share examples of similar apps that inspire you. Having this information would help us in matching you to an Incredible team that has relevant expertise
        </Typography>
        <Button
        style={referenceStyles.addButton}
              variant="contained"
              color="primary"
              onClick={onAdd}
            >
          Add
            </Button>
      </div>
      {
        references &&
        <div>
          {
            references.map((reference,index)=>{
              return (
                <div style={referenceStyles.referenceInputContainer} key={index}>
                   <FormControl margin="normal" style={referenceStyles.input} fullWidth>
                      <InputLabel htmlFor={`reference-name-${index}`}>Reference Name</InputLabel>
                      <Input id={`reference-name-${index}`} onChange={(event)=>{onChange(event,index)}} name="name" value={reference.name}/>
                    </FormControl>

                   <FormControl margin="normal" style={referenceStyles.input} fullWidth>
                      <InputLabel htmlFor={`reference-link-${index}`}>Reference Link</InputLabel>
                      <Input id={`reference-link-${index}`} onChange={(event)=>{onChange(event,index)}} name="link" value={reference.link}/>
                    </FormControl>

                    <DeleteForeverOutlinedIcon onClick={()=>{onRemove(index)}} style={referenceStyles.deleteButton}/>
                </div>
              )
            })
          }
        </div>
      }
    </div>
  )
}

class AddProjectForm extends Component {
  state = {
    projectName: '',
    projectDescription: '',
    projectStage: '',
    domains: [],
    platforms: [],
    references: []
  }

  handleSave = () => {
    this.props.createProject(this.state).then(resp => {
      this.props.data.refetch();
      this.props.history.push('/');
    })
  }

  handleAddReference = () => {
    const { references } = this.state;

    references.push({
      name:'',
      link:''
    });

    this.setState({references});
  }

  handleRemoveReference = (index) => {
    const { references } = this.state;

    references.splice(index, 1);

    this.setState({references});
  }

  handleReferenceValueChange = (event, index) => {
    const { references } = this.state;

    references[index][event.target.name] = event.target.value;

    this.setState({references});
  }

  handleTextChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSelectChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const {projectName, projectDescription, projectStage, domains, platforms, references } = this.state;

    return (
      <div>
        <Typography variant="h4" gutterBottom component="h2">
          Please tell us about your Project
        </Typography>
          <Paper className={classes.paper}>
            <form>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="projectName">What's the name of your project</InputLabel>
                <Input id="projectName" value={projectName} name="projectName" onChange={this.handleTextChange}/>
              </FormControl>

              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="projectDescription">Tell us briefly about your project</InputLabel>
                <Input multiline={true} value={projectDescription} id="projectDescription" name="projectDescription" onChange={this.handleTextChange}/>
              </FormControl>

              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="select-multiple-chip">Which industry/vertical/domain does your project belong to? (e.g. Games, AR/VR)</InputLabel>
                <Select
                  multiple
                  value={domains}
                  name="domains"
                  onChange={this.handleSelectChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  {domainOptions.map(name => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
             </FormControl>

              <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="select-multiple-chip">What's the stage your project is currently at?</InputLabel>
                  <Select
                    value={projectStage}
                    onChange={this.handleSelectChange}
                    name="projectStage"
                  >
                    {projectStageOptions.map(name => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
              </FormControl>

              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="select-multiple-chip">What's your target platform?(e.g. Web, iOS, Android etc)</InputLabel>
                <Select
                  multiple
                  value={platforms}
                  name="platforms"
                  onChange={this.handleSelectChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  {platformOptions.map(name => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
             </FormControl>

             <ReferencesList references={references}
              onAdd={this.handleAddReference}
              onRemove={this.handleRemoveReference}
              onChange={this.handleReferenceValueChange}
             />

            </form>
          </Paper>

          <Button onClick={this.handleSave} style={{width: '120px', height:'42px'}} variant="contained" color = "primary" >Save</Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps,{ createProject })(withStyles(styles)(AddProjectForm));
