import gql from 'graphql-tag'

export default gql`
query FetchUserProfile($id: ID!) {
  user(id: $id) {
    email
    id
    profile {
      apis
      availability
      clientRating
      clients
      domain
      experience
      gender
      id
      interests
      languages
      libraries
      minBidRate
      overview
      peerRating
      platform
      portfolioLink
      profilePicture
      tagline
      skype
      linkedinProfile
      profileProjects {
        id
        name
        url
        domain
        description
        platforms
        programmingLanguages
        startDate
        tools
        endDate
        teamSize
        role
        createdAt
      }
      role
      specialities
      specialization
      talentType
      timezone
      tools
    }
    username
  }
}
`
