import React from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppsIcon from '@material-ui/icons/Apps';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import CallIcon from '@material-ui/icons/Call';
import { MenuList, MenuItem, Paper, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';


const styles = theme => ({
  menuItem: {
    // '&:focus': {
    //   backgroundColor: theme.palette.primary.main,
    //   '& $primary, & $icon': {
    //     color: theme.palette.common.white,
    //   },
    // },
  },
  primary: {},
  icon: {},
});

export const MenuItems = (props) => {

  const { classes } = props;
  const { id } = props.data.variables

  const list = [
    { label: 'Project Overview', icon: <AppsIcon />, to: `/project/${id}` },
    { label: 'Incredibles', icon: <PeopleIcon />, to: `/project/${id}/incredibles` },
    { label: 'Project Management', icon: <SettingsIcon />, to: `/project/${id}/management` },
    //{ label: 'Contact Us', icon: <CallIcon />, to: '/contactus' },
  ]

  return (
    <Paper>
      <MenuList>
      {
        list.map((item,index) => {
        const isSelected = item.to === props.history.location.pathname ? true: false
          return (
            <Link key={index} to={item.to}>
            <MenuItem button selected={isSelected} className={classes.menuItem}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </MenuItem>
            </Link>
          )
        })
      }
      </MenuList>
    </Paper>
  )
};

export default withStyles(styles)(MenuItems);
