import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Paper, Button, Divider} from '@material-ui/core';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Moment from 'react-moment';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { updateContract } from '../../../../redux/actions';

import skypeIcon from "../../../../assets/icons/skype.svg";
import linkedIcon from "../../../../assets/icons/linkedin.svg";

const ContractCardLabelValue = ({label, value}) => {
  return(
    <Grid xs={4} style={{ marginTop: '8px'}}>
      <Typography variant="body2" style={{color: '#494949'}}>{label}</Typography>
      <Typography variant="subtitle1">{value}</Typography>
    </Grid>
  )
};

const ContractSummary = (props) => {
  const { contract, role, detailsSection, onClick } = props;
  const { ratePerHour } = contract;
  const { user, project } = contract;
  const { skype, linkedinProfile, role: position } = user.profile;

  return (
    <Grid container>
      <Grid item container xs={10}>
        <Grid container alignItems="center" xs={12}>
          <Typography variant="h5" style={styles.title}>
              { role === 'incredible' ? project.user.username : user.username }
          </Typography>

          <CopyToClipboard text={ role === 'incredible' ? project.user.profile.skype : skype }>
            <img
              onClick={() => {}}
              style={styles.contactIcon}
              src={skypeIcon}
              height="24px"
              alt="skype-icon"
            />
          </CopyToClipboard>

          <CopyToClipboard text={ role === 'incredible' ? project.user.profile.linkedinProfile : linkedinProfile }>
            <img
              onClick={() => {}}
              style={styles.contactIcon}
              src={linkedIcon}
              height="24px"
              alt="linkedin-icon"
            />
          </CopyToClipboard>
        </Grid>

        <ContractCardLabelValue label="Hired As" value={position ? position : 'N/A'}/>

        <ContractCardLabelValue label="Ptroject" value={project.title ? project.title : 'N/A'}/>

        <ContractCardLabelValue label="Rate($) / Hour" value={`\$${ratePerHour}`}/>

      </Grid>

      <Grid item container justify="center" alignItems="center" xs={2}>
        <Button variant="outlined" color="primary" onClick={()=>{onClick()}}>View Details { detailsSection ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/> }</Button>
      </Grid>
    </Grid>
  )
};

const ContractDetails = (props) => {
  const { contract, role, onAccept, onReject } = props;
  const { hoursPerWeek, ratePerHour, startDate, endDate } = contract;
  const { user, project } = contract;

  return (
    <Grid container>
    <Grid item container xs={10}>
      <Grid container alignItems="center" xs={12}>
        <Typography variant="h6" style={{color: '#494949'}}>Contract Details</Typography>
      </Grid>

      <ContractCardLabelValue label="Start Date" value={<Moment format="D MMMM, YYYY" withTitle>{startDate}</Moment>}/>

      <ContractCardLabelValue label="End Date" value={<Moment format="D MMMM, YYYY" withTitle>{endDate}</Moment>}/>

      <ContractCardLabelValue label="Estimated Budget" value={`\$ ${hoursPerWeek * ratePerHour}`}/>

      <ContractCardLabelValue label="Hours / Week" value={hoursPerWeek}/>

      <Grid xs={4} style={{ marginTop: '8px'}}>
        <Typography variant="body2" style={{color: '#494949'}}>Duration</Typography>
        <Typography variant="subtitle1"><Moment diff={startDate} unit="weeks">{endDate}</Moment> weeks</Typography>
      </Grid>
    </Grid>

    {
      role === 'incredible' &&
      <Grid item container direction="column" justify="center" alignItems="center" xs={2}>
        <Button variant="outlined" onClick={()=>{ onAccept() }} style={{width: '153.7px', marginBottom: '16px'}} color="primary">Accept</Button>
        <Button variant="outlined" onClick={()=>{ onReject() }} style={{width: '153.7px'}} color="secondary">Reject</Button>
      </Grid>
    }
  </Grid>
  )
};


class ContractCard extends Component {
  state = {
    detailsSection: false
  }

  handleToggleDetails = () => {
    this.setState({
      detailsSection: !this.state.detailsSection
    });
  }

  handleAcceptContract = () => {
    const { contract, updateContract } = this.props;
    const { project } = contract;

    updateContract({status: 'hired'}, contract.id)
      .then(response=>{
        console.log(response);
      });
  }

  handleRejectContract = () => {
    const { contract, updateContract } = this.props;
    const { project } = contract;

    updateContract({status: 'declined'}, contract.id)
      .then(response=>{
        console.log(response);
      });
  }

  render() {
    const { detailsSection } = this.state;

    return (
      <div>
        <Paper style={styles.container}>
          <ContractSummary onClick={this.handleToggleDetails} detailsSection={detailsSection} {...this.props}/>

          {
            detailsSection &&
            <Fragment>
              <Divider style={styles.divider}/>
              <ContractDetails onReject={this.handleRejectContract} onAccept={this.handleAcceptContract} {...this.props}/>
            </Fragment>
          }
        </Paper>
      </div>
    );
  }
}

const styles = {
  container: {
    padding: "32px",
    margin: "16px 0px",
  },
  title: {
    marginRight: '8px'
  },
  contactIcon: {
    margin: "0px 4px",
    cursor: "pointer"
  },
  divider: {
    margin: '16px 0px'
  }
};

const mapStateToProps = (state) => {
	return state
}

export default connect(mapStateToProps, {
  updateContract
})(ContractCard);
