import React, { Component, Fragment } from 'react';
import Moment from 'react-moment';

import {
  Typography,
  Grid,
  Chip,
  TextField,
  IconButton
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import TimerIcon from "@material-ui/icons/Timer";

import ChecklistItemForm from './../ChecklistItemForm';

class ChecklistItem extends Component {
  state = {
    edit: false
  }

  toggleEdit = () => {
    this.setState({
      edit: true
    })
  }

  handleUpdate = (item) => {
    const { onUpdate, index } = this.props;

    if(typeof(onUpdate) === 'function') {
      onUpdate(item, index);

      this.setState({
        edit: false
      });
    }
  }

  render() {
    const { edit } = this.state;
    const { item, index, onDelete, onUpdate } = this.props;
    const { checklistText, dueDate } = item;

    return (
      <Fragment>
        {
          !edit ?
          <div>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={6}>
                <Typography variant="subtitle1" onClick={()=>{this.toggleEdit()}}>{checklistText}</Typography>
              </Grid>

              <Grid item xs={2}>
                <Chip
                  icon={<TimerIcon />}
                  label={<Moment format="MMM D, YYYY" withTitle >{dueDate}</Moment>}
                  color="secondary"
                  variant="outlined"
                  style={{ fontSize: "14px", width: "132px", height: "30px" }}
                />
              </Grid>

              <Grid item xs={2}>
                <div style={{display: 'flex', marginRight: '-7px',justifyContent: 'flex-end'}}>
                  <IconButton onClick={ ()=>{onDelete(index)} } color="secondary">
                    <DeleteIcon/>
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </div>
          : <ChecklistItemForm onUpdate={ this.handleUpdate } item={item} edit={true}/>
        }
      </Fragment>
     );
  }
}


export default ChecklistItem;
