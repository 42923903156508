import axios, {
	put,
	post,
} from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const updateProfileRequest = (values, refetch) => {
	return (dispatch, getState) => {

		const state = getState()
		const {
			auth
		} = state;
		const {
			user
		} = auth;
		const {
			token,
			id
		} = user;

		const postParams = {
			profile: {
				...values
			}
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return put(`${API_BASE_URL}/api/v1/users/${id}/profile`, postParams, config).then(resp => {
			refetch();
		}).catch(error => {
			console.log('error', error);
		})
	}
}

export const createProfileProject = ({
	name,
	url,
	role,
	description,
	startDate: start_date,
	endDate: end_date,
	teamSize: team_size,
	platforms,
	tools,
	domain,
	languages: programming_languages,
	specialisation,
	libraries
}, refetch) => {
	return (dispatch, getState) => {

		const state = getState()
		const {
			auth
		} = state;
		const {
			user
		} = auth;
		const {
			token,
			id
		} = user;

		const postParams = {
			profile_project: {
				name,
				url,
				role,
				description,
				start_date,
				end_date,
				team_size,
				platforms,
				tools,
				domain,
				programming_languages,
				specialisation,
				libraries
			}
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return post(`${API_BASE_URL}/api/v1/users/${id}/profile/profile_projects/`, postParams, config).then(resp => {
			refetch();
		}).catch(error => {
			console.log('error', error);
		})
	}
}

export const updateProfileProject = (projectId, {
	name,
	url,
	role,
	description,
	startDate: start_date,
	endDate: end_date,
	teamSize: team_size,
	platforms,
	tools,
	domain,
	languages: programming_languages,
	specialisation,
	libraries
}, refetch) => {
	return (dispatch, getState) => {

		const state = getState()
		const {
			auth
		} = state;
		const {
			user
		} = auth;
		const {
			token,
			id
		} = user;

		const postParams = {
			profile_project: {
				name,
				url,
				role,
				description,
				start_date,
				end_date,
				team_size,
				platforms,
				tools,
				domain,
				programming_languages,
				specialisation,
				libraries
			}
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return put(`${API_BASE_URL}/api/v1/profile_projects/${projectId}`, postParams, config).then(resp => {
			refetch();
		}).catch(error => {
			console.log('error', error);
		})
	}
}

export const deleteProfileProject = (projectId, refetch) => {
	return (dispatch, getState) => {

		const state = getState()
		const {
			auth
		} = state;
		const {
			user
		} = auth;
		const {
			token,
		} = user;

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return axios.delete(`${API_BASE_URL}/api/v1/profile_projects/${projectId}`, config).then(resp => {
			refetch();
		}).catch(error => {
			console.log('error', error);
		})
	}
}
