import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  TextField,
  Divider
} from "@material-ui/core";

import { updateDeliverable } from '../../../../redux/actions';


import Assignees from './Assignees';
import Checklist from './Checklist';
import Attachments from './Attachments';
import Comments from './Comments';

class DeliverableDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      titleTextField: false,
      descriptionTextField: false,
     }
  }

  componentDidMount() {
    const { deliverable } = this.props;
    const { title, description } = deliverable;

    this.setState({
      title,
      description
    });
  }

  toggleTextField = (value) => {
    this.setState({
      titleTextField: value
    })
  }

  toggleDescriptionField = (value) => {
    this.setState({
      descriptionTextField: value
    })
  }

  onDeliverableInformationUpdate = (event) => {
    const { deliverable, refetch, updateDeliverable } = this.props;
    const { id } = deliverable;
    const { name } = event.target;

    updateDeliverable({[name]: this.state[name]}, id)
      .then( response => {
        // refetch();
      }
    );
  }

  onTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  onResetTextField = (event) => {
    const { deliverable } = this.props;
    const { name } = event.target;

    this.setState({
      [name]: deliverable[name],
      [`${name}TextField`]: false
    })
  }

  render() {
    const { deliverable, assignableIncredibles } = this.props;
    const {
      title,
      description,
      titleTextField,
      descriptionTextField,
    } = this.state;

    return (
      <div>
        <div>
          <Typography variant="subtitle1"><strong>Title</strong></Typography>

          {
            titleTextField ?
            <TextField
              id="title"
              name="title"
              value={title}
              variant="outlined"
              fullWidth
              autoFocus
              onKeyPress = {(e)=>{
                if(e.which === 13) {
                  this.toggleTextField(false);
                  this.onDeliverableInformationUpdate(e)
                }
              }}
              onBlur = { this.onResetTextField }
              onChange={ this.onTextChange }
              inputProps={{ 'aria-label': 'bare' }}
            />:
            <Typography variant="subtitle1" onClick={()=>this.toggleTextField(true)}>{title}</Typography>
          }
        </div>

        <div>
          <Typography variant="subtitle1"><strong>Description</strong></Typography>
          {
            descriptionTextField ?
            <TextField
              id="description"
              value={description}
              name ="description"
              variant="outlined"
              autoFocus
              multiline
              fullWidth
              onKeyPress = {(e)=>{
                if(e.which === 13) {
                  this.toggleDescriptionField(false);
                  this.onDeliverableInformationUpdate(e)
                }
              }}
              onBlur = { this.onResetTextField }
              onChange={ this.onTextChange }
              inputProps={{ 'aria-label': 'bare' }}
            />:
            <Typography variant="subtitle1" onClick={()=>{this.toggleDescriptionField(true)}}>{description}</Typography>
          }
        </div>

        <Divider style={{margin: '24px 0px'}}/>

        <Assignees assignableIncredibles={assignableIncredibles} deliverable={deliverable}/>

        <Divider style={{margin: '24px 0px'}}/>

        <Checklist deliverable={deliverable}/>

        <Divider style={{margin: '24px 0px'}}/>

        <Attachments deliverable={deliverable}/>

        <Divider style={{margin: '24px 0px'}}/>

        <Comments deliverable={deliverable}/>
      </div>
     );
  }
}

const mapStateToProps = (state) => {
	return state
}

export default connect(mapStateToProps, {
  updateDeliverable
})(DeliverableDetails);
