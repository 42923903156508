import React, { Component } from 'react';

import Deliverables from './../../../../shared/Deliverables';

import { Typography, Grid, Card, CardContent, CardActions, Button } from '@material-ui/core';
import DeliverableDialog from './Deliverable'
import { connect } from 'react-redux';
import { createDeliverable } from '../../../../../redux/actions';
import DeliverablePanel from '../../../../../Components/DeliverablePanel';

class Management extends Component {
	handleAdd = () => {
		const { refetch, project } = this.props.data;
		this.props.createDeliverable(project.id).then(resp => {
			refetch()
		})
	}

	render() {
		const { props } = this;
		const { data } = props;
		const { project, refetch } = data;
		const { deliverables, title, technicalRequirements } = project;

		return (
			<React.Fragment>
				<Deliverables data={data}/>
			</React.Fragment>
		)
	}
}
// class Management extends Component {
// 	handleAdd = () => {
// 		const { refetch, project } = this.props.data;
// 		this.props.createDeliverable(project.id).then(resp => {
// 			refetch()
// 		})
// 	}

// 	render() {
// 		const { props } = this;
// 		const { data } = props;
// 		const { project, refetch } = data;
// 		const { deliverables, title, technicalRequirements } = project;

// 		let possibleAssignees = []
// 		technicalRequirements.map(req => {
// 			return req.talentMatches.map(match => {
// 				if(match.status === 'get_estimate') {
// 					return possibleAssignees.push(match.user)
// 				}
// 				return null
// 			})
// 		})

// 		return (
// 			<React.Fragment>
// 				<Typography variant="h4" gutterBottom component="h2">
// 					Project Management
// 				</Typography>
// 				<Grid container spacing={4}>
// 					<Grid item xs>
// 						<Card>
// 							<CardContent>
// 								<Grid container alignItems="center" justify="space-between">
// 									<Typography variant="h6">Deliverables for {title}</Typography>
// 									<CardActions>
// 										<Button onClick={this.handleAdd} size="small" color="primary">
// 											Add
// 										</Button>
// 									</CardActions>
// 								</Grid>
// 								{
// 									deliverables.map((item,index) => {
// 										return (
// 											<React.Fragment key={index}>
// 												<DeliverableDialog canEdit={true} data={{refetch,possibleAssignees,...item}}>
// 													<DeliverablePanel data={item}/>
// 												</DeliverableDialog>
// 												{
// 													index + 1 < deliverables.length ?
// 													<div style={{paddingTop:20}}/> : null
// 												}
// 											</React.Fragment>
// 										)
// 									})
// 								}
// 							</CardContent>
// 						</Card>
// 					</Grid>
// 				</Grid>
// 			</React.Fragment>
// 		)
// 	}
// }


const mapStateToProps = (state) => {
	return state
}

export default connect(mapStateToProps,{createDeliverable})(Management);
