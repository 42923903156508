import React, { Component } from 'react';
import fetchAssignedProjects from '../../../../graphQL/Query/fetchAssignedProjects';
import { graphql } from 'react-apollo';
import { FormControl, InputLabel, Select, MenuItem, Typography, Grid, Card, CardContent, CardActions, Button } from '@material-ui/core';
import Loader from '../../../../Components/Loader';
import DeliverablePannel from '../../../../Components/DeliverablePanel';
import DeliverableDialog from '../../../Client/Components/Project/Components/Deliverable';
import Deliverables from '../../../shared/Deliverables';

class Management extends Component {
    state = {
        project: null
    }

    handleSelectChange = event => {
        let project = event.target.value;

        if(project.deliverables && project.deliverables.length > 0) {
            project.deliverables = project.deliverables.filter((deliverable) => {
                let userId = `${this.props.auth.user.id}`;

                let incredibleIds = deliverable.incredibles.map(incredible=>incredible.id);

                return incredibleIds.indexOf(userId) !== -1
            })
        }

        this.setState({ [event.target.name]: project });
    };

    render() {
        const { data, auth } = this.props;
        const { user, loading, error, refetch } = data;

        if(loading) {
            return <Loader />
        }

        if(error) {
            return <div>Error....</div>
        }

        const { incredibleProjects = null, talentMatches } = user;
        const { project } = this.state;
        const { deliverables = [], title } = project || {};

        const filteredProjects = talentMatches.filter(tM=> tM.status === 'hired' || tM.status === 'get_estimate')
                                    .map(ftM => ftM.technicalRequirement.project);

        let filteredDeliverables = deliverables.filter((item) => {
            const ret = item.assignee ? (parseInt(item.assignee.id) === auth.user.id ? true : false) : false
            return ret;
        })

        if(!filteredProjects) {
            return <div>No Project Assigned Currently</div>
        }

        return (
            <React.Fragment>

                <FormControl margin="normal" style={{marginBottom: '24px'}} required fullWidth>
                    <InputLabel htmlFor="select-multiple-chip">Select Project</InputLabel>
                    <Select
                    value={project}
                    onChange={this.handleSelectChange}
                    name="project"
                    >
                    {filteredProjects.map((project,index) => (
                        <MenuItem key={index} value={project}>
                            {project.title}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                {
                    project &&
                    <Deliverables data={{refetch, project}}/>
                }
                {
                filteredDeliverables.length > 0 &&
                <Grid container>
                    <Typography variant="h4" gutterBottom component="h2">
                        Project Management
                    </Typography>
                    <Grid container direction="column" spacing={4}>
                        <Grid item>
                            <Card>
                                <CardContent>
                                <Grid container direction="column" spacing={2}>
                                    <Grid container alignItems="center" justify="space-between">
                                        <Typography variant="h5">Deliverables for {title}</Typography>
                                    </Grid>
                                    {
                                        filteredDeliverables.map((item,index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <DeliverableDialog data={{refetch,...item}}>
                                                        <DeliverablePannel data={item}/>
                                                    </DeliverableDialog>
                                                    {
                                                        index + 1 < filteredDeliverables.length ?
                                                        <div style={{paddingTop:20}}/> : null
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                }
            </React.Fragment>
        )
    }
}

export default graphql(fetchAssignedProjects,{
    options: (props) => {
        return {variables: {id: props.auth.user.id}}
    }
})(Management);
