import gql from 'graphql-tag'

export default gql`
query FetchUserRatings($id: ID!) {
  user(id: $id) {
    email
    id
    sentInvites {
      id
      receiverEmail
      projectName
      score
      startDate
      endDate
      createdAt
      signUp
    }
    receivedRatings{
      id
      score
      projectName
      startDate
      endDate
      sender {
        id
        username
        profile{
          id
          profilePicture
          role
          domain
        }
      }
      receiver {
        id
        username
        profile{
          id
          profilePicture
          role
          domain
        }
      }
    }
    myPeers{
      id
      aggregate
      projectName
      score
      startDate
      endDate
      receiver{
        id
        username
        profile{
          id
          role
          domain
        }
      }
    }
    allMyPeers{
      id
      aggregate
      receiver{
        id
        username
        profile{
          id
          role
          domain
        }
      }
    }
    sentRatings{
      id
      score
      projectName
      startDate
      endDate
      createdAt
      receiver {
        id
        username
        email
        profile{
          id
          profilePicture
          role
          domain
        }
      }
    }
    username
  }
}
`
