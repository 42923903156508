import React, { Component, useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { connect } from 'react-redux';
import { postPeerReview, updatePeerReview } from './../../../../../redux/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import StarRatingComponent from 'react-star-rating-component';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import 'date-fns';
import { Button } from '@material-ui/core';

const rating = [1,2,3,4,5,6,7,8,9,10];

const ReviewRating = (props) => {
  const { label, name, onChange, value } = props;

  return(
    <FormControl component="fieldset" style={{margin: '0px', marginBottom: '16px'}}>
      <Typography variant="subtitle2" gutterBottom>{label}</Typography>

      <StarRatingComponent
          value={value}
          name={name}
          starCount={10}
          starColor='#FFD700'
          onStarClick={onChange}
          emptyStarColor='#ABABAB'
      />
      {/* <RadioGroup style={{margin: '0px'}} onChange={onChange} value={value} name={name} row>
        {
          rating.map((r,i)=>(
            <FormControlLabel
              style={{margin:'0px'}}
              index={i}
              value={`${r}`}
              control={<Radio color="primary" />}
              label={`${r}`}
              labelPlacement="bottom"
          />
          ))
        }
      </RadioGroup> */}
  </FormControl>
  )
}

const NotificationMessage = ({open,handleClose,message}) => {
  return(
    <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    open={open}
    autoHideDuration={6000}
    onClose={handleClose}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={<span id="message-id">{message}</span>}
  />
  )
}

class PeerReviewForm extends Component {
  static defaultProps = {
    incredible: null
  }

  state = {
    rating: {
      skills: null,
      communication: null,
      workQuality: null,
      relationshipStrength: null
    },

    score: null,
    project_name: '',
    start_date: new Date(),
    end_date: new Date(),
    update: false,
    loading: false,
    notification: false,
    showRating: false,
    dateRangeError: null,
    errors:{
      project_name: false,
      start_date: false,
      end_date: false,
      relationshipStrength: false
    }
  }

  componentDidMount(){
    const { mode } = this.props;
    const {project_name, start_date, end_date, score} = this.props.incredible || {};


    if(project_name && start_date && end_date) {
      let rating = this.state.rating;
      rating.relationshipStrength = mode === 'view' ? score : null;

      this.setState({
        rating,
        project_name,
        start_date,
        end_date,
        update: mode === 'view' ? false : true,
      })
    }
  }

  handleRatingChange = (nextValue, prevValue, name) => {
    const { rating } = this.state;

    if(this.props.mode != 'view') {
      rating[name] = nextValue;
      this.setState({rating});
    }
  }

  hanldeTextChange = (event) => {
    const {name, value} = event.target;

    this.setState({
      [name]: value
    })
  }

  handleSend = () => {
    const {project_name, start_date, end_date, rating, update, dateRangeError} = this.state;
    const { email, incredible, postPeerReview,updatePeerReview, refetch, onClose } = this.props;
    let obj = {
      project_name,
      start_date,
      end_date,
      skill_set_score:`${rating.skills}`,
      communication_score: `${rating.communication}`,
      work_quality_score: `${rating.workQuality}`,
      score: `${rating.relationshipStrength}`
    }

    if(update){
      delete obj.project_name
      delete obj.receiver_id
      delete obj.start_date
      delete obj.end_date
      this.setState({loading: true});
      updatePeerReview(obj, incredible.ratingId).then(response=>{
        refetch();
        setTimeout(() => {
          onClose()
        }, 1000);
      })
    } else if(email) {
      obj.receiver_email = email;
      if(this.validateForm() && !dateRangeError){

        this.setState({loading: true},()=>{
          postPeerReview(obj).then(response=>{
            refetch();

            setTimeout(() => {
              this.setState({notification:true});
              this.props.onInviteSuccess();
              onClose();
            }, 1000);
          });
        });

      }
    }
    else {
      if(this.validateForm() && !dateRangeError){
        obj.receiver_id = incredible.id;

        this.setState({loading: true},()=>{
          postPeerReview(obj).then(response=>{
            refetch();

            setTimeout(() => {
              onClose()
            }, 1000);
          });
        });
      }
    }
  }

  validateForm(){
    const { project_name, rating, start_date, end_date, errors } = this.state;
    let validated = false;
    let errorCount = 0;

    errors.project_name = !project_name;
    errors.start_date = !start_date;
    errors.end_date = !end_date;
    errors.relationshipStrength = !rating.relationshipStrength;

    Object.keys(errors).forEach(e=>{
      if(errors[e]){
        errorCount++;
      }
    });

    validated = errorCount > 0 ? false : true;

    this.setState({errors});
    return validated;
  }

  handleDateChange = (name, value) => {
    const {
      start_date,
      end_date
    } = this.state;

    let dateRangeError = null;

    if (name === 'start_date') {
      if (value.getTime() > end_date.getTime()) {
        dateRangeError = 'Start Date Cannnot be greater than End Date';
      }
    }

    if (name === 'end_date') {
      if (value.getTime() < start_date.getTime()) {
        dateRangeError = 'End Date Cannnot be lesser than Start Date';
      }
    }

    this.setState({
      dateRangeError,
      [name]: value
    })
  }

  render() {
    const { incredible, email, mode, onClose } = this.props;
    const { image, username , profile } = incredible || {};
    const { role, domain} = profile || {};
    const { project_name, rating, start_date, end_date, update, loading, notification, errors, dateRangeError } = this.state;

    return (
      <div style={peerReviewStyles.container}>
        <NotificationMessage open={notification} message="Email invite sent!"/>
        <Grid
         container
         direction="row"
         justify="center"
         alignItems="center"
         >
          {
            incredible &&
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item>
                <Avatar style={peerReviewStyles.avatar}>{username ? username.charAt(0).toUpperCase(): 'N/A'}</Avatar>
              </Grid>
              <Grid style={peerReviewStyles.incredibleDetailsContainer} item direction="column" alignItems="center" justify="center">
                <Typography variant="h6" align="center">{username}</Typography>

                {
                  role &&
                  <Typography style={peerReviewStyles.position} variant="subtitle1" align="center">{role ? role : 'N/A'}</Typography>
                }

                <div style={peerReviewStyles.chipContainer}>
                  {
                    (domain && domain.length > 0) &&
                    domain.slice(0,3).map((d,i)=> (<Chip style={peerReviewStyles.chip} key={i} label={d}/>))
                  }
                </div>
              </Grid>
             </Grid>
          }
          {
            email &&
            <Grid container direction="row" justify="center" alignItems="center">
              <Typography variant="body2" align="center"><strong>Email: </strong></Typography>
              <Typography variant="body1" align="center">{email}</Typography>
            </Grid>
          }
          <form onSubmit={(event)=>{
            event.preventDefault();
            this.handleSend();
          }}>
            <Grid item style={{width: '100%'}}>
                <Divider style={{margin: '32px 0px'}}/>

                <FormControl margin="normal" error={errors.project_name} required fullWidth>
                    <InputLabel htmlFor={`project_name`}>Where did you two work together?</InputLabel>
                    <Input disabled={update || mode === 'view'} id={`project_name`} value={project_name} onChange={this.hanldeTextChange} name="project_name"/>
                    {
                     errors.project_name &&
                     <FormHelperText>This field is required</FormHelperText>
                    }
                </FormControl>

                <Grid container justify="space-between">
                  <FormControl margin="normal" error={errors.start_date} required>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        label="From"
                        value={start_date}
                        style={{width: '250px'}}
                        name='start_date'
                        format="dd/MM/yyyy"
                        disabled={update || mode === 'view'}
                        onChange={(start_date) => {
                          this.handleDateChange('start_date', start_date);
                        }}
                        // onChange={(start_date) => this.setState({start_date})}
                      />
                    </MuiPickersUtilsProvider>
                    {
                      errors.start_date &&
                      <FormHelperText>This field is required</FormHelperText>
                    }
                </FormControl>

                <FormControl margin="normal" required error={errors.end_date}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      label="To"
                      value={end_date}
                      style={{width: '250px'}}
                      name='end_date'
                      format="dd/MM/yyyy"
                      disabled={update || mode === 'view'}
                      onChange={(end_date) => {
                        this.handleDateChange('end_date', end_date);
                      }}
                      // onChange={(end_date) => this.setState({end_date})}
                    />
                  </MuiPickersUtilsProvider>
                  {
                      errors.end_date &&
                      <FormHelperText>This field is required</FormHelperText>
                  }
                </FormControl>
              </Grid>
              {
                dateRangeError &&
                <div style={{color: '#f44336', fontSize:'12px', marginTop: '8px'}}>{ dateRangeError }</div>
              }
            </Grid>

            <Grid item style={{width:'100%'}}>
              <Divider style={{margin: '32px 0px'}}/>

              <Typography variant="body1" gutterBottom>Please submit your ratings to be classified as their peer. Belonging to a peer cluster increases your likelihood of being considered for team projects.</Typography>
              <Typography style={{marginTop:'16px',padding: '16px', backgroundColor: '#ebebeb'}} variant="body1">Your rating will not be shared explicitly. The rating is required to compute a ‘relationship strength’ score which is used by the GetIncredibles team to determine team fit for potential projects.</Typography>

              <Divider style={{margin: '32px 0px'}}/>
            </Grid>

            <Grid className="review-rating-form" container justify="flex-start">
                <ReviewRating label="Skill-set and knowledge of concerned area/domain"
                  value={rating.skills}
                  name='skills'
                  onChange={this.handleRatingChange}
                />

                <ReviewRating label="Communication and comprehension skills"
                  value={rating.communication}
                  name='communication'
                  onChange={this.handleRatingChange}
                />

                <ReviewRating label="Quality of work"
                  value={rating.workQuality}
                  name='workQuality'
                  onChange={this.handleRatingChange}
                />

                <FormControl required error={errors.relationshipStrength}>
                  <ReviewRating label="How much I would like to work with them"
                      value={rating.relationshipStrength}
                      name='relationshipStrength'
                      onChange={this.handleRatingChange}
                  />
                   {
                    errors.relationshipStrength &&
                    <FormHelperText style={{marginTop:'-24px'}}>This field is required</FormHelperText>
                  }
                </FormControl>
            </Grid>

            <Button disabled={loading} style={{width: '120px', height:'42px', marginTop: '24px'}} onClick={mode === 'view' ? ()=>{onClose()} :this.handleSend} variant="contained" color = "primary" >
                {
                  loading ?
                  <CircularProgress style={{top:'14px', left: '46%'}} size={24}/>
                  : mode === 'view' ? 'Close': 'Send'
                }

            </Button>
          </form>
        </Grid>
      </div>
   )
  }

}

const peerReviewStyles = {
  container: {
    padding: '32px',
  },
  avatar: {
    height: '80px',
    width: '80px',
  },
  incredibleDetailsContainer: {
    marginLeft: '32px'
  },
  position: {
    margin: '8px 0px'
  },
  chipContainer: {
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  chip: {
    margin: '2px 4px'
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps,{ postPeerReview, updatePeerReview })(PeerReviewForm);
