import { put, post } from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const createContract = (values,id) => {
	const { hours_per_week, rate_per_hour, start_date, end_date, documents, user_id} = values

	return (dispatch, getState) => {
    const state = getState()
		const { auth } = state;
		const { user } = auth;
    const { token } = user;

		var formData = new FormData();
		// formData.append('hours_per_week', hours_per_week);
		// formData.append('rate_per_hour', rate_per_hour);
		// formData.append('start_date', start_date);
		// formData.append('end_date', end_date);
		// formData.append('documents', documents);

		formData.append('contract[hours_per_week]', hours_per_week);
		formData.append('contract[rate_per_hour]', rate_per_hour);
		formData.append('contract[documents]', documents);
		formData.append('contract[user_id]', user_id);
		formData.append('contract[status]', 'pending');
		formData.append('contract[start_date]', start_date);
		formData.append('contract[end_date]', end_date);

    // const postParams = {
		// 	documents: {
		// 		file
		// 	}
		// }

		let config = {
			headers: {
				'Authorization': token,
				'Content-Type': 'multipart/form-data'
			}
		}

		return post(`${API_BASE_URL}/api/v1/projects/${id}/contracts`,formData, config)
    }
}


export const updateContract = (values, id) => {
	const { status } = values

	return (dispatch, getState) => {
    const state = getState()
		const { auth } = state;
		const { user } = auth;
    const { token } = user;

		var formData = new FormData();

		formData.append('contract[status]', status);

		let config = {
			headers: {
				'Authorization': token,
				'Content-Type': 'multipart/form-data'
			}
		}

		return put(`${API_BASE_URL}/api/v1/contracts/${id}`,formData, config)
    }
}


export const createProject = ({
		projectName: title,
		projectDescription: description,
		domains: domain,
		projectStage: stage_at,
		platforms: target_platform,
		references
	}, refetch ) => {
	return (dispatch, getState) => {

    const state = getState()
		const { auth } = state;
		const { user } = auth;
    const { token, id } = user;

    const postParams = {
			project: {
				title,
				description,
				domain,
				stage_at,
				target_platform,
			},
			references
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return post(`${API_BASE_URL}/api/v1/users/${id}/projects`,postParams, config).then(resp => {
			refetch();
		}).catch(error => {
			console.log('error',error);
		})
  }
}


export const createTechnicalRequirement = (values, projectId) => {
	return (dispatch, getState) => {

		debugger;
		const state = getState()
		const { auth } = state;
		const { user } = auth;
		const { token } = user;

		const postParams = {
			technical_requirement: {
				project_id: projectId,
				...values
			},
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return post(`${API_BASE_URL}/api/v1/projects/${projectId}/technical_requirements`,postParams, config);
	}
}

export const updateMatch = (status, id) => {
	return (dispatch, getState) => {

		const state = getState()
		const { auth } = state;
		const { user } = auth;
		const { token } = user;

		const postParams = {
			talent_match : {
				status
			}
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return put(`${API_BASE_URL}/api/v1/talent_matches/${id}`,postParams, config);
	}
}

export const updateClientMatch = (status, id) => {
	return (dispatch, getState) => {

		const state = getState()
		const { auth } = state;
		const { user } = auth;
		const { token } = user;

		const postParams = {
			status
		}

		let config = {
			headers: {
				'Authorization': token
			}
		}

		return put(`${API_BASE_URL}/api/v1/projects/${id}/client_matches`,postParams, config);
	}
}
