import React, { Component } from 'react';
import { FormControlLabel, FormControl, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  formControl: {
    margin: 12, //theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
});

class RadioOptions extends Component {

  state = {
    value: this.props.defaultValue
  }

  componentDidMount() {
    this.props.change(this.props.id,this.state.value)
  }

  handleChange = event => {
    this.setState({ value: event.target.value },() =>
      this.props.change(this.props.id,this.state.value)
    );
  }

  render() {
    const { classes, label, options } = this.props;
    return (
      <React.Fragment>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup
            className={classes.group}
            value={this.state.value}
            onChange={this.handleChange}
          >
          { 
            options.map(({ value, label },index) => {
              return <FormControlLabel key={index} value={value} control={<Radio color="primary" />} label={label} />
            })
          }
          </RadioGroup>
        </FormControl>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(RadioOptions);