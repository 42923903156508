import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import Profile from './Components/Profile';
import Matches from './Components/Matches';
import Community from './Components/Community';
import Management from './Components/Management';
import Contracts from '../shared/Contracts';

function Incredible(props) {
  return (
    <Switch>
      {/* <Route path="/incredible/:id/profile" component={(params) => { return <Dashboard {...props}><Project {...props} {...params}/></Dashboard> }}/> */}
      <Redirect
          exact
           from="/"
            to={{
              pathname: `/community`,
              state: { from: props.location }
            }}
          />
      <Route path="/user/:userId/profile" component={(params) => {
        return (
          <Profile {...props} {...params}/>
        )
      }}/>
      <Route path="/management" component={() => { return <Management {...props}/> }}/>
      <Route path="/community" component={(params) => { return <Community {...params} {...props} /> }}/>
      <Route path="/contracts" component={(params) => { return <Contracts {...params} {...props} role="incredible" contracts={props.data.user.contracts}/> }}/>
      <Route path="/user/:userId/matches" component={(params) => { return <Matches {...params}/> }}/>
    </Switch>
  )
}

export default Incredible;
