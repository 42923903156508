import React from 'react';
import Moment from 'react-moment';
import MyAvatar from '../../../../../../../Components/MyAvatar';
import { Grid, Typography, Box } from '@material-ui/core';

const CommentBox = ({ data }) => {
	const { user, description} = data;
	return (
		<Grid item>
			<Grid container spacing={2}>
				<Grid item xs = {'auto'}>
					<MyAvatar size="medium" user={user} hasMenu={false}/>
				</Grid>
				<Grid item xs>
					<Grid container direction="column" spacing={2}>
						<Grid item>
							<Grid container direction="column" item spacing={2}>
								<Grid item>
									<Typography variant="subtitle2">{user.username}</Typography>
								</Grid>
								<Grid item style={{padding: '0 8px'}}>
									<Box style={{padding: 8, display:'flex'}} border={1} borderRadius={16}>
										<Typography>{description}</Typography>
									</Box>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Typography variant="caption"><Moment format="MMM D, hh:mm A" withTitle>{data.created_at}</Moment></Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default CommentBox;