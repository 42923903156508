import gql from 'graphql-tag'

export default gql`
{
  users {
    email
    id
    profile {
      id
      domain
      role
    }
    roles {
      description
      id
      name
    }
    receivedRatings {
      id
      senderId
      receiverId
    }
    sentRatings {
      id
      senderId
      receiverId
    }
    username
  }
}
`
