import React, { Component } from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { graphql, compose } from 'react-apollo';
import {connect} from 'react-redux';



import SearchByNameSection from './SearchByNameSection';
import EmailInviteSection from './EmailInviteSection';
import PeersSection from './PeersSection';
import Loader from './../../../../Components/Loader';


import fetchUsers from './../../../../graphQL/Query/fetchUsers';
import fetchUserRatings from './../../../../graphQL/Query/fetchUserRatings';
import { postPeerReview } from './../../../../redux/actions';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  chip: {
    marginRight: '5px'
  }
});

const roles = {
  incredible: 'Incredible',
  client: 'Client'
}

class Community extends Component {
  state = {
    users: null,
    searchedUsers: null,
    user: null,
    searchResultEmail: null,
    searchResultName: null
  }

  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  componentDidMount() {
    if(this.props.data.users && !this.state.users){
      let incredibles = this.props.data.users.filter(user => {
        let isIncredible = false

        if (user.roles.length > 0) {
          user.roles.forEach(role => {
            if (role.name === roles.incredible) {
              isIncredible = true
            }
          });
        }

        if(user.id == this.props.auth.user.id){
          isIncredible = false;
        }


        return isIncredible;
      });

      this.setState({
        users: JSON.parse(JSON.stringify(incredibles)),
      })
    }
  }

  static getDerivedStateFromProps(props, prevState){
    const {
      loading,
      users,
    } = props.data;

    if(props.ratings.user) {
      if(!props.ratings.loading && JSON.stringify(prevState.user) != JSON.stringify(props.ratings.user)){
        return {
          user: JSON.parse(JSON.stringify(props.ratings.user))
        }
      }
    }

    if (!loading && !prevState.searchedUsers && !props.ratings.loading) {
      let incredibles = users.filter(user => {
        let isIncredible = false

        if (user.roles.length > 0) {
          user.roles.forEach(role => {
            if (role.name === roles.incredible) {
              isIncredible = true
            }
          });
        }

        if(user.id == props.auth.user.id){
          isIncredible = false;
        }

        return isIncredible;
      });

      if(props.ratings.user){
        return {
          users: JSON.parse(JSON.stringify(incredibles)),
          // searchedUsers: JSON.parse(JSON.stringify(incredibles)),
          user: JSON.parse(JSON.stringify(props.ratings.user))
        }
      }else {
        return {
          users: JSON.parse(JSON.stringify(incredibles)),
          // searchedUsers: JSON.parse(JSON.stringify(incredibles)),
        }
      }
    }else {
      return null
    }
  }

  handleSearchButton = (searchValue) => {
    const { users } = this.state;

    let name = searchValue;
    let emailSearchedFlag = false;

    if(name) {
      let searchedUsers = users.filter(u => {
        if(u.username) {
            if(u.username.toLowerCase().match(name.toLowerCase())){
              return true
            }
        }
      });

      if(searchedUsers.length === 0 && emailRegex.test(name)){
        searchedUsers = users.filter(u => {
          if(u.email) {
              if(u.email.toLowerCase().match(name.toLowerCase())){
                return true
              }
          }
        });
        emailSearchedFlag = true;
      }

      this.setState({
        searchedUsers: JSON.parse(JSON.stringify(searchedUsers)),
        searchResultEmail: (emailSearchedFlag && searchedUsers.length === 0) ? "There is no record of this user, you can invite him via email": null,
        searchResultName: (!emailSearchedFlag && searchedUsers.length === 0) ? "There is no record of this user, Try to search it via email": null
      })
    }else{
      this.setState({
        searchedUsers: null,
        searchResultEmail: null,
        searchResultName: null
      })
    }
    // else {
    //   this.setState({
    //     searchedUsers: JSON.parse(JSON.stringify(users))
    //   });
    // }
  }

  filterUsersByRating = (user, type) => {
    let ratingUsers = [];

    if(type === 'sent') {
      ratingUsers = user.sentRatings.filter(userRating => {
        if (userRating.score) {
          return true;
        }
      });

      ratingUsers = ratingUsers.map(rating => {
        return {
          ratingId: rating.id,
          score: rating.score,
          project_name: rating.projectName,
          start_date: rating.startDate,
          end_date: rating.endDate,
          ...rating.receiver
        }
      })
    }

    if(type === 'receiver'){
      ratingUsers = user.receivedRatings.filter(userRating => {
        if (!userRating.score) {
          return true;
        }
      });

      ratingUsers = ratingUsers.map(rating => {
        return {
          ratingId: rating.id,
          score: rating.score,
          project_name: rating.projectName,
          start_date: rating.startDate,
          end_date: rating.endDate,
          ...rating.receiver
        }
      })
    }

    if(type === 'mypeers'){
      ratingUsers = user.myPeers.filter(userRating => {
        return true
      });

      ratingUsers = ratingUsers.map(rating => {
        return {
          aggregate: rating.aggregate,
          ...rating.receiver
        }
      })
    }

    if(type === 'allMyPeers'){
      ratingUsers = user.allMyPeers.filter(userRating => {
        return true
      });

      ratingUsers = ratingUsers.map(rating => {
        return {
          aggregate: rating.aggregate,
          ...rating.receiver
        }
      })
    }

    return ratingUsers;
  }

  render() {
    const { classes, data, ratings } = this.props;
    const { name, email, searchedUsers, user, searchResultEmail, searchResultName } = this.state;
    const { loading } = data;

    if(loading && ratings.loading)
      return <Loader/>;

    return (
      <div>
        <Typography variant="h4" gutterBottom component="h2">Community</Typography>
        <div style={{
            background: '#ece9e9',
            padding: '16px',
            borderRadius: '8px'
        }}>
          <Typography style={{fontSize: '14px'}} gutterBottom>
            Welcome to your Incredible community. Here you can look for peers on the platform with whom you have worked with in the past, or invite them to become part of GetIncredibles by sending them an email invite
          </Typography>
          <Typography style={{fontSize: '14px'}} gutterBottom>
          At GetIncredibles, we believe that peer relationships are the most crucial factor in determining project team success. So we have developed a Cluster Engine to recommend project teams which can work well together from the get go! This is enabled by the peers you mark in this section
          </Typography>
        </div>

        <Paper className={classes.paper}>
            <EmailInviteSection onSearchClick={this.handleSearchButton}
               searchResultEmail={searchResultEmail}
               searchResultName={searchResultName}
               refetch={ratings.refetch}
               user={user}/>

            {/* <SearchByNameSection onSearchClick={this.handleSearchButton}/> */}
            {/* {
              searchResultEmail &&
              <div>{searchResultEmail}</div>
            } */}

            {/* {
              searchResultName &&
              <div>{searchResultName}</div>
            } */}

            {
              (!searchResultEmail && !searchResultName && searchedUsers) &&
              <PeersSection type="incredibles" title="Incredibles" users={searchedUsers} refetch={ratings.refetch}/>
            }

            {/* {
              (user && user.sentRatings.length > 0 && this.filterUsersByRating(user,'sent').length > 0) &&
              <PeersSection type="sent" title="Peer requests sent" users={this.filterUsersByRating(user,'sent').reverse()} />
            } */}

            {
              (user && user.receivedRatings.length > 0 && this.filterUsersByRating(user, 'receiver').length > 0) &&
              <PeersSection type="received" title="Peer requests received" users={this.filterUsersByRating(user, 'receiver').reverse()} refetch={ratings.refetch}/>
            }

            {
              (user && user.myPeers.length > 0 && this.filterUsersByRating(user, 'mypeers').length > 0) &&
              <PeersSection type="mypeers" title="Peers I would love to work with" users={this.filterUsersByRating(user, 'mypeers')} refetch={ratings.refetch}/>
            }

            {
              (user && user.allMyPeers.length > 0 && this.filterUsersByRating(user, 'allMyPeers').length > 0) &&
              <PeersSection type="allMyPeers" title="All Peers" users={this.filterUsersByRating(user, 'allMyPeers')} refetch={ratings.refetch}/>
            }

        </Paper>
      </div>
     );
  }
}

export default
graphql(fetchUserRatings,{
  name: 'ratings',
  options: (props) => {
      return {variables: {id: props.auth.user.id}}
  }
})(graphql(fetchUsers)(withStyles(styles)(Community)));
