import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import timezones from '../../../../Timezone'
import { Grid, TextField, DialogActions, Button, DialogContent } from '@material-ui/core';
import Select from '../../../../SelectionControl/Select';
import { updateProfileRequest } from '../../../../../redux/actions';

class EditSideBarDialog extends Component {

  handleSave = () => {
    let { values } = this.props.form.profile
    this.props.updateProfileRequest(values,this.props.refetch)
    this.props.handleClose();
  }

  componentDidMount() {
    this.props.change('','');
  }

  render () {
    const { data, change } = this.props;
    const {
      experience,
      timezone,
      clients,
      interests,
      tagline,
      skype,
      linkedinProfile
    } = data;

    return (
      <React.Fragment>
      <DialogContent>
        <Grid container direction="column" style={{ paddingTop: 24 }} spacing={2}>
          <Grid item>
            <TextField
              required
              defaultValue={tagline}
              id="tagline"
              name="tagline"
              label="Tag Line"
              fullWidth
              onChange={(event)=> {change('tagline',event.target.value)}}
            />
          </Grid>

          <Grid item>
            <TextField
              required
              defaultValue={skype}
              id="skype"
              name="skype"
              label="Skype"
              fullWidth
              onChange={(event)=> {change('skype',event.target.value)}}
            />
          </Grid>

          <Grid item>
            <TextField
              required
              defaultValue={linkedinProfile}
              id="linkedin_profile"
              name="linkedin_profile"
              label="Linkedin"
              fullWidth
              onChange={(event)=> {change('linkedin_profile',event.target.value)}}
            />
          </Grid>
          <Grid item>
            <Select
              id="timezone"
              label="Timezone"
              value={timezone}
              change={change}
              items={timezones}
              helperText="Please select your timezone"
            />
          </Grid>
          <Grid item>
            <TextField
              required
              defaultValue={experience}
              id="experience"
              name="experience"
              label="Experience"
              fullWidth
              autoComplete="experience"
              onChange = {
                (event) => {
                  change('experience', event.target.value === '' ? 0 : event.target.value)
                }
              }
            />
          </Grid>
          {/* <Grid item>
            <TextField
              required
              defaultValue={clients}
              id="clients"
              name="clients"
              label="Clients"
              fullWidth
              autoComplete="clients"
              onChange={(event)=> {change('clients',event.target.value.split(','))}}
              helperText="please use comma ', ' to seperate identities"
            />
          </Grid> */}
          {/* <Grid item>
            <TextField
              required
              defaultValue={interests}
              id="interests"
              name="interests"
              label="Future Interests"
              fullWidth
              autoComplete="interests"
              onChange={(event)=> {change('interests',event.target.value.split(','))}}
              helperText="please use comma ', ' to seperate identities"
            />
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleSave} color="primary">
          Save changes
        </Button>
      </DialogActions>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ form }) => {
  return { form }
}

EditSideBarDialog = connect(mapStateToProps, { updateProfileRequest })(EditSideBarDialog)

export default EditSideBarDialog = reduxForm({
  form: 'profile',
})(EditSideBarDialog)
