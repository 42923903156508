import React, { Component } from 'react';

import {
  Grid,
  TextField,
  IconButton
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';


class ChecklistItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checklist_text: '',
      due_date: new Date()
    }
  }

  componentDidMount() {
    const { item, edit } = this.props;

    if(item && edit){
      this.setState({
        id: item.id,
        checklist_text: item.checklistText,
        due_date: item.dueDate,
        status: item.status
      })
    }
  }

  handleChecklistItemCreate = () => {
    const { edit } = this.props;
    let checklistItemData = JSON.parse(JSON.stringify(this.state));


    if(edit) {
      this.props.onUpdate(checklistItemData);
    }else{
      checklistItemData['status'] = false;
      this.props.onCreate(checklistItemData);
    }

    this.setState({
      checklist_text: '',
      due_date: new Date()
    })
  }

  handleDateChange = (date) => {
    this.setState({
      due_date: date
    });
  }

  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    const { checklist_text, due_date } = this.state;

    const {
      item,
      edit
    } = this.props;



    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={6} style={{marginTop: '8px'}}>
              <TextField
                autoFocus
                style={{width: '95%'}}
                value={checklist_text}
                name="checklist_text"
                onChange={this.handleTextChange}
                placeholder="Add an action item/task"
              />
            </Grid>

            <Grid item xs={4}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="due_date"
                name="due_date"
                value={due_date}
                onChange={this.handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <div style={{display: 'flex', marginLeft: '4px'}}>
                <IconButton onClick={this.handleChecklistItemCreate}>
                  <DoneIcon/>
                </IconButton>
                {
                  edit &&
                  <IconButton color="secondary">
                    <DeleteIcon/>
                  </IconButton>
                }
              </div>
            </Grid>
          </Grid>
        </div>
      </MuiPickersUtilsProvider>
     );
  }
}

export default ChecklistItemForm
